import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import ReactPaginate from 'react-paginate';
import { NavLink } from "react-router-dom";
import { HeaderBotones } from '../../header/header_botones'


class Blog extends React.Component {

    state = {
        loadingBlog: false,
        pageCount: 0,
        isMobileSend: true,
        itemsPerPage: 5,
        dataPrincipal: [],
        dataSidebar: [],
        dataBlogActual: []
    };

    constructor(props) {
        super(props);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.irDetalle = this.irDetalle.bind(this);
        console.log("es blog tipo",this.props.isMobileSend)
    }

    handlePageClick(event) {
        const newOffset = (event.selected * this.state.itemsPerPage) % this.state.dataBlog.length;
        const endOffset = newOffset + this.state.itemsPerPage;
        this.setState({dataBlogActual:this.state.dataBlog.slice(newOffset, endOffset)})

    }

    irDetalle(event,item) {
       

    }

    async componentDidMount() {
        this.setState({isMobileSend:this.props.isMobileSend})

        const res =  await Http.instance.get("rest/pages/blog");
        if(res.status != 200){
            this.setState({dataTienda:[],loadingTiendas:true})
        }else{
            this.setState({dataPrincipal:res.principal,dataSidebar:res.sidebar,loadingTiendas:true})
        }

        console.log("ddd",res.sidebar)
        
        var itemOffset = 0
        var itemsPerPage = 5
        
        const endOffset = itemOffset + itemsPerPage;


        //this.setState({pageCount:Math.ceil(this.state.dataBlog.length / itemsPerPage),dataBlogActual:this.state.dataBlog.slice(itemOffset, endOffset)})
        this.setState({pageCount:Math.ceil(this.state.dataPrincipal.length / itemsPerPage),dataBlogActual:this.state.dataPrincipal.slice(itemOffset, endOffset)})
        
    }

    render() {

        const { pageCount} = this.state;

        return (
            <Row className={this.state.isMobileSend ? "movil noPadding":""}>
                <Col xs={12} className={this.state.isMobileSend ? "noPadding":"" }>
                    {
                        this.state.isMobileSend == false ?
                        <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                        : null
                    }
                    

                    
                    <div className="clear"></div>
                    <h1 className={this.state.isMobileSend == false ? 'tituloNuestrasTiendas' : 'tituloNuestrasTiendasMovil'}>Blog</h1>
                    <div className="clear"></div>

                    {
                        this.state.loadingTiendas == false ?
                            <div className="contSpinner"><Spinner /></div>
                        :
                        <Row className="itemCant1 blog" style={{padding:"20px 10px",margin:"0px"}}>
                            <Col sm={this.state.isMobileSend == false ? 8 : 12}>
                                {this.state.dataPrincipal.map((item, index) => (
                                    index < 2 ?
                                    <NavLink to={"/blog/"+item.id}>
                                        <Row className="itemBlog nopadding" onClick={(e) => this.irDetalle(e,item)}>
                                            <img src={item.image} className="imgCant1" style={{borderRadius:"10px",padding:"0px"}} />
                                            <div className={this.state.isMobileSend == false ? 'categoria' : 'categoriaMovil'}>{item.category.name}</div>
                                            <div className={this.state.isMobileSend == false ? 'titulo' : 'tituloMovil'}>{item.title}</div>
                                            <div className={this.state.isMobileSend == false ? 'detalle' : 'detalleMovil'}>{item.description}</div>
                                        </Row>
                                    </NavLink>
                                    : null
                                ))}
                                
                            </Col>

                            


                            <Col sm={this.state.isMobileSend == false ? 4 : 12} className={this.state.isMobileSend == false ? 'blogLateral' : ''}>

                                {this.state.dataSidebar.map((item, index) => (
                                    <NavLink to={"/blog/"+item.id}>
                                        <Row className="itemBlog nopadding" onClick={(e) => this.irDetalle(e,item)}>
                                            <img src={item.image} className="imgCant1" style={{borderRadius:"10px",padding:"0px"}} />
                                            <div className={this.state.isMobileSend == false ? 'categoria' : 'categoriaMovil'}>{item.category.name}</div>
                                            <div className={this.state.isMobileSend == false ? 'titulo' : 'tituloMovil'}>{item.title}</div>
                                            <div className={this.state.isMobileSend == false ? 'detalle' : 'detalleMovil'}>{item.description}</div>
                                        </Row>
                                    </NavLink>
                                ))}

                            </Col>

                            <div className="paginacion">

                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="siguiente >"
                                    onPageChange={this.handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel="< anterior"
                                    renderOnZeroPageCount={null}
                                />
                            </div>

                            
                        </Row>
                    }

                    

                    
                </Col>

            </Row>
        );
    }
}

export default Blog;