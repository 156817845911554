import React, { useState, useEffect } from 'react';

const country = "SV"
const urlBase =  "https://apillao.llaollaosv.com/"
//const urlBase =  "https://devapi.llaollaosv.com/"

class Http {
    static instance = new Http()


    get = async(url) => {
        try {
            let req = await fetch(urlBase + country + "/" + url);

            let json = await req.json();

            return json;
        } catch (err) {
            console.log("http get method error", err);
            return { "errorHttp": err };
            //throw Error(err);
        }
    }

    post = async(url, body, dominio) => {
        try {
            let req = await fetch((typeof dominio !== 'undefined' ? dominio : urlBase + country + "/") + url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: body
            });

            //console.log(req)
            console.log("req", JSON.stringify(req))


            let json = await req.json();

            return json;
        } catch (err) {
            console.log("http post method error", err, url);
            //showError()
            return { "errorHttp": err };
            //throw Error(err);
        }
    }

    delete = async(url) => {
        try {
            let req = await fetch(url, {
                method: "DELETE"
            });

            let json = await req.json();

            return json;
        } catch (err) {
            console.log("http get method error", err);
            //throw Error(err);
        }
    }

    put = async(url, body) => {
        try {
            let req = await fetch(url, {
                method: "PUT",
                body
            });

            let json = await req.json();

            return json;
        } catch (err) {
            console.log("http get method error", err);
            //throw Error(err);
        }
    }

    postImage = async(url, photo) => {

        var dataSend = {}
        dataSend.photo = photo

        console.log(JSON.stringify(dataSend))

        fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataSend),
            })
            .then((response) => response.json())
            .then((response) => {
                console.log('response!!!', response);
            })
            .catch((error) => {
                console.log('error!!!!', error);
            });
    }

}

export default Http;