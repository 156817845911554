import React,{ useState,useEffect }  from 'react';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { Route, Redirect } from 'react-router'
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InputMask from 'react-input-mask';
import validator from 'validator'
import { withRouter } from 'react-router-dom';
import $ from 'jquery'; 
import { NavLink } from "react-router-dom";
import { Spinner } from "react-activity";
const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 0,
      width: "85%",
      bottom: 'auto',
      transform: 'translate(-50%,-50%)',
      //transform: 'translate(0%, -50%)',
    },
  };


class IniciarSesion extends React.Component {

    state = {
        loading: false,
        setIsOpen: false,
        selectedIndex: 0,
        errorNombre:false,
        nombre:"",
        telefono:"",
        dui:"",
        pass:"",
        apellido:"",
        email:"",
        dia:"",
        mes:"",
        year:"",
        departamento:"",
        departamentos:[],
        municipios:[],
        municipio:"",
        colonia:"",
        colonias:[],
        tipoDireccion:"",
        direccion:"",
        terminos:false,
        setIsOpenPass: false,
        emailPass: "",
        loadPass: false,
        errorPassSend:false,
        successPassSend:false,
        
    };

    handleClickTab(e,valor) {
        this.setState({selectedIndex:valor})
        //alert(this.state.defaultIndexTabs)
    }

    handleSelectTabs = index => {
        this.setState({ selectedIndex: index });
    };

    loginUser = async (e) => {
        if(this.state.email == ""){
            this.setState({errorEmail:true,errorEmailText:"campo obligatorio."})
            return
        }else if(validator.isEmail(this.state.email) == false){
            this.setState({errorEmail:true,errorEmailText:"Email no valido"})
            return
        }

        if(this.state.pass == ""){
            this.setState({errorPass:true})
            return
        }

        this.setState({loadLogin:true})

        var dataUserSend = {}
        dataUserSend.token = ""
        dataUserSend.user = this.state.email
        dataUserSend.password = this.state.pass

        const res = await Http.instance.post("rest/account/login",JSON.stringify(dataUserSend));

        if(res.errorCode == 0){
            localStorage.removeItem("direccionSelect")
            localStorage.setItem("customer", JSON.stringify(res.customer_info));
            window.location = "/";
        }else{
            this.setState({mensajeLogin:res.errorMessage,loadLogin:false})
        }

    }

    passUser = async (e) => {

        if(this.state.loadPass == false){
            if(this.state.emailPass == ""){
                this.setState({errorEmailPass:true,errorEmailPassText:"campo obligatorio."})
                return
            }else if(validator.isEmail(this.state.emailPass) == false){
                this.setState({errorEmailPass:true,errorEmailPassText:"Email no valido"})
                return
            }
    
            this.setState({loadPass:true,mensajePass:"",errorPassSend:false,successPassSend:false})
    
            var dataUserSend = {}
            dataUserSend.email = this.state.emailPass
    
            const res = await Http.instance.post("rest/account/recovery-password",JSON.stringify(dataUserSend));
            console.log("resss",res)


            if(typeof res.errorHttp == 'undefined'){
                console.log("resss!!",res.errorHttp)

                if(res.status != "success"){
                    this.setState({loadPass:false,mensajePass:res.message,errorPassSend:true,successPassSend:false,emailPass:""})
                }else{
                    this.setState({loadPass:false,mensajePass:res.message,errorPassSend:false,successPassSend:true,emailPass:""})
                }
                
            }
        }
        
    }

    constructor(props) {
        super(props);
        //this.handleClickTab = this.handleClickTab.bind(this);

    } 

    async componentDidMount() {
        const res =  await Http.instance.get("rest/main/departments");
        this.setState({departamentos:res})
    }

    render() {
      return (
          <div  className="noPadding" style={{marginTop:"10px"}}>
            <Row className="noPadding">
                <div className="contNuestrasTiendas noPadding">

                    <div className="nuestrasTiendas">Ingresar</div>
                    <Row className="contListTiendas noPadding" style={{height:"100vh"}}>
                        <Col xs={12} className="contRegistroLeft h-100">
                            <Row className="noPadding">
                                <Col xs={1}></Col>
                                <Col xs={10}>
                                    <div className="movilFooter">
                                        <Row className="rowRegistro" style={{paddingTop:"50px"}}>

                                            

                                        

                                            <Col sm={6}>
                                                <div>
                                                    <div className="tituloCampo">
                                                    <i className="bi bi-envelope"></i>

                                                        <i className="glyphicon glyphicon-envelope"></i> &nbsp;&nbsp;EMAIL
                                                    </div>
                                                    <input 
                                                    onChange={(text) => {
                                                        this.setState({email:text.target.value,errorEmail:false,errorEmailText:""})
                                                    }}
                                                    value={this.state.email}
                                                    className={this.state.errorEmail ? "error" : ""} 
                                                    type="email" id="emailAddress" name="emailAddress" required="" aria-required="true"  aria-invalid="true" />
                                                    {
                                                        this.state.errorEmail == true ?
                                                            <label className="error">{this.state.errorEmailText}.</label>
                                                        :null
                                                    }
                                                </div>

                                            </Col>

                                            <Col sm={6}> 

                                                <div>
                                                    <div className="tituloCampo">
                                                        <i className="glyphicon glyphicon-lock"></i> &nbsp;&nbsp;CONTRASEÑA
                                                        <a onClick={(e) => this.setState({setIsOpenPass:true}) } className="button-secondary forgot-password-link">Restablecer contraseña</a>

                                                    </div>
                                                    <input 
                                                    onChange={(text) => {
                                                        this.setState({pass:text.target.value,errorPass:false})
                                                    }}
                                                    value={this.state.pass}
                                                    className={this.state.errorPass ? "error" : ""} 
                                                    type="password" autocomplete="off" id="createPassword" name="createPassword" required="" aria-required="true" aria-invalid="true"></input>
                                                    {
                                                        this.state.errorPass == true ?
                                                            <label className="error">Campo obligatorio.</label>
                                                        :null
                                                    }
                                                    
                                                </div>
                                                
                                        
                                            </Col> 
                                        </Row>


                                        <div className="popup-footer">
                                            <p>¿No tienes cuenta?,  <a href="/crearcuenta" title="Registrate">Regístrate</a></p>
                                        </div>

                                        {
                                            this.state.mensajeLogin != "" ?
                                                <label className="errorLogin" style={{textAlign:"center",display:"block",paddingBottom:"0px"}}>{this.state.mensajeLogin}</label>
                                            :null
                                        }
                                        
                                       
                                        <Button onClick={(e) => this.loginUser(e)} className="btn-agregar btnContinuarCrearCuenta">
                                            {
                                                this.state.loadLogin == true ?
                                                    <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                                : 
                                                "INGRESAR A CUENTA"
                                            }
                                        </Button>

                                          
                                       
                                    </div>

                                </Col>
                                <Col xs={1}></Col>
                            </Row>
                        </Col>
                    </Row>
                    
                </div>
            </Row>

            <Modal
                    isOpen={this.state.setIsOpenPass}
                    style={customStyles}
                    contentLabel=""
                >
                    <div className="popupHeader">
                        <img onClick={() => this.setState({setIsOpenPass:false})} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left",fontSize:"19px"}}>restablecer contraseña</div>
                        

                        
                        <div className='nombreProductoAgregado' style={{fontSize:"14px",marginTop:"5px",marginBottom:"5px"}}>Ingresa tu dirección de email y te enviaremos instrucciones para restablecerla.</div>

                        <div>
                            <input type="hidden" name="target" id="target" value="" />
                            <div className="input" style={{marginTop:"15px"}}>
                                <label for="header-account-sign-in-email">EMAIL</label>
                                <input 
                                type="email"
                                className={this.state.errorEmailPass ? "error" : ""} 
                                onChange={(text) => {
                                    this.setState({emailPass:text.target.value,errorEmailPass:false,errorEmailPassText:""})
                                }}
                                value={this.state.emailPass}
                                name="user"/>

                                {
                                    this.state.errorEmailPass == true ?
                                        <label className="errorLogin">{this.state.errorEmailPassText}.</label>
                                    :null
                                }

                            </div>

                            {
                                this.state.errorPassSend == true ?
                                    <label className="errorLogin" style={{textAlign:"center",display:"block",paddingBottom:"0px"}}>{this.state.mensajePass}</label>
                                :null
                            }

                            {
                                this.state.successPassSend == true ?
                                    <label className="error" style={{marginBottom:"20px",color:"#8FD400",display:"block",textAlign:"center",fontSize:"12px"}}>{this.state.mensajePass}</label>
                                :null
                            }

                            
                            
                            <div className="input">

                                <Button onClick={(e) => this.passUser(e)} className="btn-agregar btn btn-primary" style={{marginRight:"20px",height:"34px",marginLeft:"20px",width: 'calc(100% - 40px)',marginTop:"10px",marginBottom:"20px"}} className="btn-agregar btnConfirmar">
                                {
                                    this.state.loadPass == true ?
                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                    : 
                                    "Enviar"
                                }
                                </Button>

                                
                            </div>

                        </div>
                      
                    </div>
                    
            </Modal>
        </div>
      );
    }
}

export default IniciarSesion;