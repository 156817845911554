import React,{ useState,useEffect }  from 'react';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { Route, Redirect } from 'react-router'
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InputMask from 'react-input-mask';
import validator from 'validator'
import { withRouter } from 'react-router-dom';
import $ from 'jquery'; 
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { NavLink } from "react-router-dom";
import { HeaderBotones } from '../../header/header_botones'
class TrackPedido extends React.Component {
    state = {
        loadData:true,
        sinResultados:false,
        loadReload:false,
        res:{
            "cod_order": "",
            "details": [],
            "direction": {"address": "", "alias_addr": "", "alias_name": "", "customer": 0, "id": 0, "latitude": "", "local_number": "0", "locality": 0, "locality_name": "", "longitude": "", "primary_addr": 1, "reference": "", "zone": 0, "zone_name": ""},
            "idorder": 0,
            "location": {"backup": 0, "begin_service": "", "department": 1, "department_name": null, "direction": "", "end_service": "", "hours": {"Domingo a Jueves": "11:00AM A 09:00PM", "Viernes a Sábado": "11:00AM A 10:00PM"}, "id": 0, "latitude": "0", "longitude": "0", "name": "", "time_deliver": ""},
            "steps": [
                 {"name": "ORDEN INGRESADA", "status": "0", "time": "18:00"},
                 {"name": "ORDEN PREPARACION", "status": "0", "time": ""},
                 {"name": "ORDEN DESPACHADA", "status": "0", "time": ""},
                 {"name": "ORDEN FINALIZADA", "status": "0", "time": ""}
            ],
            "total": "",
            "type": "",
            "status": ""
        },
    };

    obtenerTrack = async (e) => {
        this.setState({loadReload:true})

        var dataUser = {}
        dataUser.customer = this.state.customer.id

        const res = await Http.instance.post("rest/cart/order-track",JSON.stringify(dataUser));
 

        if(typeof res.errorHttp === 'undefined'){
            if(res.status != "error"){
                var precioExtra = 0
                for (var j=0; j < res.details.length; j++) {
                    var item =  res.details[j]
                    if(item.instructions.isPromo == true){
                        //producto uno
                        //reordenar los topings para que los gratis aparezcan de ultimo
                        var toppingsProductoUnoGratis = item.instructions.prod_1.custom.filter((item) => item.price == 0)
                        var toppingsProductoUnoNoGratis = item.instructions.prod_1.custom.filter((item) => item.price !== 0)
                
                        var customProductoUnoFinal = []
                
                        customProductoUnoFinal.push.apply(customProductoUnoFinal, toppingsProductoUnoNoGratis);
                        customProductoUnoFinal.push.apply(customProductoUnoFinal, toppingsProductoUnoGratis);
                        item.instructions.prod_1.custom = customProductoUnoFinal

                        var precioExtraProductoUno = 0
                        var toppingsValidos = item.instructions.prod_1.custom.filter((item) => item.price !== 0)
                        if(item.instructions.prod_1.topping_free < toppingsValidos.length){
                            for (var i=item.instructions.prod_1.topping_free; i < toppingsValidos.length; i++) {
                                precioExtraProductoUno += toppingsValidos[i].price
                            } 
                        }
                        item.instructions.prod_1.precioExtra = precioExtraProductoUno
                        item.instructions.prod_1.price = item.instructions.prod_1.precio_unitario - precioExtraProductoUno
                        item.instructions.prod_1.subtotal = item.instructions.prod_1.precio_unitario
                        
                        
                        //producto dos
                         //reordenar los topings para que los gratis aparezcan de ultimo
                         var toppingsProductoDosGratis = item.instructions.prod_2.custom.filter((item) => item.price == 0)
                         var toppingsProductoDosNoGratis = item.instructions.prod_2.custom.filter((item) => item.price !== 0)
                 
                         var customProductoDosFinal = []
                 
                         customProductoDosFinal.push.apply(customProductoDosFinal, toppingsProductoDosNoGratis);
                         customProductoDosFinal.push.apply(customProductoDosFinal, toppingsProductoDosGratis);
                         item.instructions.prod_2.custom = customProductoDosFinal

                        var precioExtraProductoDos = 0
                        var toppingsValidos = item.instructions.prod_2.custom.filter((item) => item.price !== 0)
                        if(item.instructions.prod_2.topping_free < toppingsValidos.length){
                            for (var i=item.instructions.prod_2.topping_free; i < toppingsValidos.length; i++) {
                                precioExtraProductoDos += toppingsValidos[i].price
                            } 
                        }
                        item.instructions.prod_2.precioExtra = precioExtraProductoDos
                        item.instructions.prod_2.price = item.instructions.prod_2.precio_unitario - precioExtraProductoDos
                        item.instructions.prod_2.subtotal = item.instructions.prod_2.precio_unitario



                    }else{
                        var precioExtra = 0
                        var toppingsValidos = item.instructions.filter((item) => item.price !== 0)
        
                        if(item.product.custom_data.toppings_free < toppingsValidos.length){
                            for (var i=item.product.custom_data.toppings_free; i < toppingsValidos.length; i++) {
                                precioExtra += toppingsValidos[i].price
                            } 
                        }
                        item.precioExtra = precioExtra
                    }
                    
                } 
    
                var resultado = res

                for (var j = 0; j < resultado.details.length; j+=1) {
                    if(resultado.details[j].isPromo){
                        resultado.details[j].promo =  true
                        resultado.details[j].isTopping =  false
                    }else if(resultado.details[j].isTopping){
                        resultado.details[j].promo =  false
                    }else if(resultado.details[j].isTopping == false){
                        resultado.details[j].promo =  false
                    }
                }
                this.setState({res:resultado,loadData:false,loadReload:false})
            }else{
                this.setState({loadData:false,loadReload:false})
            }

            
            
            
        }
        
    }

    constructor(props) {
        super(props);
    }


    dataProductoCarrito(item,showCantidad,res){

        return(
            <div className="contInfo">
                <div  className="nameProducto">({item.quantity}) {item.title} {res.currency}{item.price.toFixed(2)} (c/u)</div>
                {item.instructions.length != 0 ?
                    <div>
                        <div class="toppings"><span>toppings</span></div>
                        <ul>
                            {item.instructions.map((itemToppping, index) => (

                                    index < item.product.custom_data.toppings_free ? <li>{itemToppping.name}</li>
                                    : null

                                
                            ))}

                            
                        </ul>

                        {
                            item.product.custom_data.toppings_free < item.instructions.length ?
                                <div>

                                    <div className="nameProducto">topping extra(s) {res.currency}{item.instructions[0].price.toFixed(2)} (c/u)</div>
                                    <ul>
                                        {item.instructions.map((itemToppping, index) => (

                                                index >= item.product.custom_data.toppings_free ? <li className={itemToppping.price == 0 ? "gratis" : ""}>{itemToppping.name} {itemToppping.price == 0 ? "(Gratis)": null}</li>
                                                : null

                                            
                                        ))}
                                    </ul>

                                </div>
                            : null
                        }

                    </div>
                : null }

                <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"5px"}}>
                    <div className="txTotal">Subtotal</div>
                    <div className="subTotalCarrito">{res.currency}{parseFloat(item.subtotal).toFixed(2)}</div>
                </div>
            </div>
        )
    }

    dataProductoCarritoPromo(item,showCantidad,res){
       
        return(
            <div className="contProducto">
                <img src={item.small_image} className="imgProducto" />
                <div>

                
                    <div  className="nameProducto" style={{color:"#585858",marginTop:"20px"}}>{item.name} {res.currency}{item.price.toFixed(2)} (c/u)</div>
                    {item.custom.length != 0 ?
                        <div>
                            <div class="toppings"><span>toppings</span></div>
                            <ul>
                                {item.custom.map((itemToppping, index) => (

                                        index < item.topping_free ? <li>{itemToppping.name}</li>
                                        : null

                                    
                                ))}

                                
                            </ul>

                            {
                                item.topping_free < item.custom.length ?
                                    <div>

                                        <div className="toppings"><span>topping extra(s) {res.currency}{item.custom[0].price.toFixed(2)} (c/u)</span></div>
                                        <ul>
                                            {item.custom.map((itemToppping, index) => (

                                                    index >= item.topping_free ? <li className={itemToppping.price == 0 ? "gratis" : ""}>{itemToppping.name} {itemToppping.price == 0 ? "(Gratis)": null}</li>
                                                    : null

                                                
                                            ))}
                                        </ul>

                                    </div>
                                : null
                            }

                        </div>
                    : null }

                </div>
            </div>
        )
    }

    async componentDidMount() {
        this.setState({isMobileSend:this.props.isMobileSend})

         //sesion iniciada
        const customer = localStorage.getItem("customer");
        const initialCustomer = JSON.parse(customer);
        if(initialCustomer != null){
            this.setState({customer:initialCustomer}, () => {
                this.obtenerTrack()
            });
        }
    }

    render() {


        const { res } = this.state;

      return (

        <Row className={this.state.isMobileSend ? "movil noPadding":""}>
            <Col xs={12} className={this.state.isMobileSend ? "noPadding":"" }>
                {
                    this.state.isMobileSend == false ?
                    <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                    : null
                }

                


                <div className="clear"></div>
                <h1 className={this.state.isMobileSend == false ? 'tituloNuestrasTiendas' : 'tituloNuestrasTiendasMovil'}>rastreo de orden</h1>

                <div className="clear"></div>

                
                <Row className={this.state.isMobileSend ? "contMainMovil altoMinimoMovil contContactenos noPadding":"itemCant1 contContactenos"}>

                    <Row className={this.state.isMobileSend ? "rowRegistro noPadding":"rowRegistro"}>
                        <Col xs={12}>

                            <div className="contFormularioContactenos contCarritoProducto" style={this.state.isMobileSend ? {marginLeft:"16px",marginRight:"16px",padding:"16px",textAlign:"center",alignContent:"center",alignItems:"center"} :{marginLeft:"50px",marginRight:"50px",padding:"25px",textAlign:"center",alignContent:"center",alignItems:"center"}}>
                                

                                {
                                    this.state.loadData == true ?
                                        <div className="contSpinner"><Spinner /></div>
                                    :
                                    
                                        this.state.res.status == "error" ?
                                            <div className='mensajeTrack'>{res.message}</div>
                                        :
                                        <Row style={{position:"relative"}}>
                                            {
                                                this.state.isMobileSend ?
                                                <div>
                                                     <Col sm={6}>
                                                        <div className='estadoOrden'>Estado de la orden: <span>{res.cod_order}</span></div>
                                                        <div style={{justifyContent: 'center',alignItems: 'center',marginTop: 20}}>
                                                        <div className="contPasos">
                                                            <div style={{display: "flex",alignItems:"center"}}>
                                                                <div className="circuloPasoCompletado">
                                                                    <img
                                                                        className="icon_ingresada"
                                                                        src="/images/icon_ingresada.png"
                                                                    />
                                                                </div>
                                                                <div className="contTextoPaso">
                                                                    <div className="txHora">{res.steps[0].time}</div>
                                                                    <div className="txNombreOrdenActivo">Orden ingresada</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="espacioLinea">
                                                            <div  className="lineaVerticalTrack"></div>
                                                        </div>

                                                        <div className="contPasos">
                                                            <div style={{display: "flex",alignItems:"center"}}>
                                                                <div className={res.steps[1].status == "1" ? "circuloPasoCompletado imgBlanco":"circuloPasoCompletado circuloPendiente"}>
                                                                    <img
                                                                        className="icon_preparacion"
                                                                        src="/images/icon_preparacion.png"
                                                                    />
                                                                </div>
                                                                <div className="contTextoPaso">
                                                                    <div className="txHora">{res.steps[1].time == "" ? "---":res.steps[1].time}</div>
                                                                    <div className={res.steps[1].status == "0" ? "txNombreOrden":"txNombreOrdenActivo"}>Orden en preparación</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="espacioLinea">
                                                            <div  className="lineaVerticalTrack"></div>
                                                        </div>


                                                        <div className="contPasos">
                                                            <div style={{display: "flex",alignItems:"center"}}>

                                                            <div className={res.steps[2].status == "1" ? "circuloPasoCompletado imgBlanco":"circuloPasoCompletado circuloPendiente"}>
                                                                    <img
                                                                        className="icon_camino"
                                                                        src="/images/icon_camino.png"
                                                                    />
                                                                </div>
                                                                <div className="contTextoPaso">
                                                                <div className="txHora">{res.steps[2].time == "" ? "---":res.steps[2].time}</div>
                                                                <div className={res.steps[2].status == "0" ? "txNombreOrden":"txNombreOrdenActivo"}>Orden en camino</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="espacioLinea">
                                                            <div  className="lineaVerticalTrack"></div>
                                                        </div>

                                                        <div className="contPasos">
                                                            <div style={{display: "flex",alignItems:"center"}}>
                                                                <div className={res.steps[3].status == "1" ? "circuloPasoCompletado imgBlanco":"circuloPasoCompletado circuloPendiente"}>
                                                                    <img
                                                                        className="icon_entregado"
                                                                        src="/images/icon_entregado.png"
                                                                    />
                                                                </div>
                                                                <div className="contTextoPaso">
                                                                <div className="txHora">{res.steps[3].time == "" ? "---":res.steps[3].time}</div>
                                                                    <div className={res.steps[3].status == "0" ? "txNombreOrden":"txNombreOrdenActivo"}>Orden entregada</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="espacioLinea"></div>

                                                        <div className="linea"></div>

                                                        <Button style={{float:"none",paddingTop:"0px",marginBottom:"30px"}} onClick={(e) => this.obtenerTrack(e)}  className="btn-agregar btnConfirmar">
                                                            {
                                                                this.state.loadReload == true ?
                                                                    <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                                                : 
                                                                "ACTUALIZAR"
                                                            }

                                                            
                                                        </Button>

                                                        </div>


                                                    </Col>
                                                    <Col sm={6} style={{textAlign:"left"}}>

                                                        <div>
                                                            <div className="txHora" style={{fontSize:"13px"}}>
                                                                <i className="glyphicon glyphicon-map-marker"></i> &nbsp;&nbsp;DIRECCIÓN
                                                            </div>
                                                            <div className='descripcionProductoModal' style={{paddingTop:"10px"}}>{res.direction.address}, {res.direction.locality.name}</div>                      
                                                        </div>

                                                        <div className='detalleOrden'>Detalle de tu orden:</div>

                                                        <div className='productosTrack'>
                                                            {res.details.map(item => (
                                                                <div>
                                                                    <div className="">
                                                                        {
                                                                            item.isTopping == true ?
                                                                                <div style={{display:"flex"}}>
                                                                                    <div className="circleTopping" style={{width:"80px",height:"80px",marginBottom:"10px",marginRight:"10px",marginLeft:'0px',flexGrow: 0,flexShrink: 0}}>
                                                                                        <div className="contentTopping">
                                                                                            <img className="imgTopping" src={item.product.small_image} />
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                    <div className="contInfo">
                                                                                            <div  className="nameProductoMain">({item.quantity}) {item.title} {res.currency}{item.price.toFixed(2)} (c/u)</div>
                                                                                        </div>
                                                                                </div>
                                                                            :
                                                                            null
                                                                        }

                                                                        {
                                                                        item.instructions.isPromo == true ?
                                                                            <div className="contProducto">
                                                                                
                                                                                <div className="contInfo">
                                                                                    <div  className="nameProducto">({item.quantity}) {item.title}</div>
                                                                                    {this.dataProductoCarritoPromo(item.instructions.prod_1,true,res)}
                                                                                    {this.dataProductoCarritoPromo(item.instructions.prod_2,true,res)}
                                                                                    <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"5px"}}>
                                                                                        <div className="txTotal">Subtotal</div>
                                                                                        <div className="subTotalCarrito">{res.currency}{parseFloat(item.subtotal).toFixed(2)}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            :
                                                                            null
                                                                        }

                                                                        {
                                                                            item.promo == false && item.isTopping == false  ?
                                                                                <div className="contProducto">
                                                                                    <img src={item.product.small_image} className="imgProducto" />
                                                                                    {this.dataProductoCarrito(item,true,res)}
                                                                                </div>  
                                                                            :
                                                                                null
                                                                        }

                                                                        

                                                                        
                                                                       

                                                                        
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            ))}

                                                        </div>

                                                        <div className="imagenesIlustrativa">* imagenes ilustrativas</div>

                                                        <div class="contEnvioCosto" style={{marginTop:"20px"}}><span class="txEnvioDomicilio">ENVÍO A DOMICILIO:</span><span class="txCostoEnvio">{res.currency} {res.delivery_fee.toFixed(2)}</span></div>
                                                        {
                                                            res.driver_tip != 0 ?
                                                                <div class="contEnvioCosto" style={{marginTop:"15px"}}><span class="txEnvioDomicilio">PROPINA PARA REPARTIDOR:</span><span class="txCostoEnvio">{res.currency} {res.driver_tip.toFixed(2)}</span></div>
                                                            : null
                                                        }

                                                        


                                                    </Col>
                                                   
                                                </div>
                                                :
                                                <Row>
                                                    <Col sm={6} style={{textAlign:"left"}}>

                                                    <div>
                                                        <div className="txHora" style={{fontSize:"13px"}}>
                                                            <i className="glyphicon glyphicon-map-marker"></i> &nbsp;&nbsp;DIRECCIÓN
                                                        </div>
                                                        <div className='descripcionProductoModal' style={{paddingTop:"10px"}}>{res.direction.address}, {res.direction.locality.name}</div>                      
                                                    </div>

                                                    <div className='detalleOrden'>Detalle de tu orden:</div>

                                                    <div className='productosTrack'>
                                                        {res.details.map(item => (
                                                            <div>
                                                                
                                                                <div className="">
                                                                    
                                                                    {
                                                                        item.isTopping == true ?
                                                                            <div className="contProducto">
                                                                                <div className="circleTopping" style={{width:"80px",height:"80px",marginBottom:"10px",marginRight:"10px",marginLeft:'0px',flexGrow: 0,flexShrink: 0}}>
                                                                                    <div className="contentTopping">
                                                                                        <img className="imgTopping" src={item.product.small_image} />
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                                <div className="contInfo">
                                                                                    <div  className="nameProductoMain">({item.quantity}) {item.title}  {res.currency}{item.price.toFixed(2)} (c/u)</div>
                                                                                    <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"5px"}}>
                                                                                        <div className="txTotal">Subtotal</div>
                                                                                        <div className="subTotalCarrito">{res.currency}{parseFloat(item.subtotal).toFixed(2)}</div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        :
                                                                        null
                                                                    }

                                                                    

                                                                    {
                                                                        item.instructions.isPromo == true ?
                                                                        <div className="contProducto">
                                                                            
                                                                            <div className="contInfo">
                                                                                <div  className="nameProducto">({item.quantity}) {item.title}</div>
                                                                                {this.dataProductoCarritoPromo(item.instructions.prod_1,true,res)}
                                                                                {this.dataProductoCarritoPromo(item.instructions.prod_2,true,res)}
                                                                                <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"5px"}}>
                                                                                    <div className="txTotal">Subtotal</div>
                                                                                    <div className="subTotalCarrito">{res.currency}{parseFloat(item.subtotal).toFixed(2)}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        :
                                                                        null
                                                                    }

                                                                    {
                                                                        item.promo == false && item.isTopping == false  ?
                                                                            <div className="contProducto">
                                                                                <img src={item.product.small_image} className="imgProducto" />
                                                                                {this.dataProductoCarrito(item,true,res)}
                                                                            </div>  
                                                                        :
                                                                            null
                                                                    }

                                                                    

                                                                    
                                                                </div>
                                                                <hr />
                                                            </div>
                                                        ))}

                                                    </div>

                                                    <div className="imagenesIlustrativa">* imagenes ilustrativas</div>
                                                
                                                    <div class="contEnvioCosto" style={{marginTop:"20px"}}><span class="txEnvioDomicilio">ENVÍO A DOMICILIO:</span><span class="txCostoEnvio">{res.currency} {res.delivery_fee.toFixed(2)}</span></div>
                                                    {
                                                        res.driver_tip != 0 ?
                                                            <div class="contEnvioCosto" style={{marginTop:"15px"}}><span class="txEnvioDomicilio">PROPINA PARA REPARTIDOR:</span><span class="txCostoEnvio">{res.currency} {res.driver_tip.toFixed(2)}</span></div>
                                                        : null
                                                    }


                                                    </Col>
                                                    <Col sm={6}>
                                                    <div className='estadoOrden'>Estado de la orden: <span>{res.cod_order}</span></div>
                                                    <div style={{justifyContent: 'center',alignItems: 'center',marginTop: 20}}>
                                                    <div className="contPasos">
                                                        <div style={{display: "flex",alignItems:"center"}}>
                                                            <div className="circuloPasoCompletado">
                                                                <img
                                                                    className="icon_ingresada"
                                                                    src="/images/icon_ingresada.png"
                                                                />
                                                            </div>
                                                            <div className="contTextoPaso">
                                                                <div className="txHora">{res.steps[0].time}</div>
                                                                <div className="txNombreOrdenActivo">Orden ingresada</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="espacioLinea">
                                                        <div  className="lineaVerticalTrack"></div>
                                                    </div>

                                                    <div className="contPasos">
                                                        <div style={{display: "flex",alignItems:"center"}}>
                                                            <div className={res.steps[1].status == "1" ? "circuloPasoCompletado imgBlanco":"circuloPasoCompletado circuloPendiente"}>
                                                                <img
                                                                    className="icon_preparacion"
                                                                    src="/images/icon_preparacion.png"
                                                                />
                                                            </div>
                                                            <div className="contTextoPaso">
                                                                <div className="txHora">{res.steps[1].time == "" ? "---":res.steps[1].time}</div>
                                                                <div className={res.steps[1].status == "0" ? "txNombreOrden":"txNombreOrdenActivo"}>Orden en preparación</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="espacioLinea">
                                                        <div  className="lineaVerticalTrack"></div>
                                                    </div>


                                                    <div className="contPasos">
                                                        <div style={{display: "flex",alignItems:"center"}}>

                                                        <div className={res.steps[2].status == "1" ? "circuloPasoCompletado imgBlanco":"circuloPasoCompletado circuloPendiente"}>
                                                                <img
                                                                    className="icon_camino"
                                                                    src="/images/icon_camino.png"
                                                                />
                                                            </div>
                                                            <div className="contTextoPaso">
                                                            <div className="txHora">{res.steps[2].time == "" ? "---":res.steps[2].time}</div>
                                                            <div className={res.steps[2].status == "0" ? "txNombreOrden":"txNombreOrdenActivo"}>Orden en camino</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="espacioLinea">
                                                        <div  className="lineaVerticalTrack"></div>
                                                    </div>

                                                    <div className="contPasos">
                                                        <div style={{display: "flex",alignItems:"center"}}>
                                                            <div className={res.steps[3].status == "1" ? "circuloPasoCompletado imgBlanco":"circuloPasoCompletado circuloPendiente"}>
                                                                <img
                                                                    className="icon_entregado"
                                                                    src="/images/icon_entregado.png"
                                                                />
                                                            </div>
                                                            <div className="contTextoPaso">
                                                            <div className="txHora">{res.steps[3].time == "" ? "---":res.steps[3].time}</div>
                                                                <div className={res.steps[3].status == "0" ? "txNombreOrden":"txNombreOrdenActivo"}>Orden entregada</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="espacioLinea"></div>

                                                    <div className="linea"></div>

                                                    <Button style={{float:"none"}} onClick={(e) => this.obtenerTrack(e)}  className="btn-agregar btnConfirmar">
                                                        {
                                                            this.state.loadReload == true ?
                                                                <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                                            : 
                                                            "ACTUALIZAR"
                                                        }

                                                        
                                                    </Button>

                                                    </div>


                                                    </Col>

                                                    <div className='lineaTrackFull'></div>
                                                </Row>
                                            }
                                           

                                            
                                        </Row>
                                    
                                }
                                

                                
                            </div>
                        
                        </Col>
                    </Row>     
                </Row>
                

                

                
            </Col>
        </Row>
      );
    }
}

export default TrackPedido;