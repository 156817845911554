import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import Modal from 'react-modal';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import validator from 'validator'
import InputMask from 'react-input-mask';
import FileBase64 from 'react-file-base64';
import $ from 'jquery'; 
import { HeaderBotones } from '../../header/header_botones'

class Contactenos extends React.Component {

    state = {
        nombre:"",
        nombreFranquicia:"",
        nombreLaboral:"",
        email:"",
        emailFranquicia:"",
        emailLaboral:"",
        duda:"",
        selectedIndex: 0,
        errorEmailText:"campo obligatorio.",
        errorEmailFranquiciaText:"campo obligatorio.",
        loadingServicioCliente: false,
        loadingFranquicia: false,
        loadingLaboral: false,
        country:[],
        pais:"",
        telefono:"",
        telefonoLaboral:"",
        isMobileSend: true,
        terminos: false,
        comunicaciones: false,
        htmlServicioCliente: "",
        htmlFranquicias: "",
        loadData: true,
        countries:[],
        files: null,
        filesLaboral: null,
        errorEnvioFranquicia:"",
        mensajeEnvioFranquicia:"",
        errorEnvio:"",
        mensajeEnvio:"",
        hiddenFranquicia: true
    };

    closeModal() {
        this.setState({setIsOpen:!this.state.setIsOpen})
    }

    handleSelectTabs = index => {
        this.setState({ selectedIndex: index });
    };

    getFiles(files){
        console.log(files)
        this.setState({ files: files })
    }

    getFilesLaboral(files){
        this.setState({ filesLaboral: files,errorCVLaboral:false })
    }

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    enviarDatosLaboral = async (e) => {
        if(this.state.loadingLaboral == false){
            var validado = true

            if(this.state.nombreLaboral == ""){
                this.setState({errorNombreLaboral:true})
                validado = false
            }

            if(this.state.emailLaboral == ""){
                this.setState({errorEmailLaboral:true,errorEmailLaboralText:"campo obligatorio."})
                validado = false
            }else if(validator.isEmail(this.state.emailLaboral) == false){
                this.setState({errorEmailLaboral:true,errorEmailLaboralText:"Email no valido"})
                validado = false
            }


            if(this.state.telefonoLaboral == ""){
                this.setState({errorTelefonoLaboral:true})
                validado = false
            }else if(this.state.telefonoLaboral.length != 8){
                this.setState({errorTelefonoLaboral:true})
                validado = false
            }

            if(this.state.filesLaboral == null){
                this.setState({errorCVLaboral:true})
                validado = false
            }

            
            if(validado == true){
                this.setState({loadingLaboral:true})

                var dataUserSend = {}
                dataUserSend.telefono = this.state.telefonoLaboral
                dataUserSend.email = this.state.emailLaboral
                dataUserSend.nombre = this.state.nombreLaboral
                if(this.state.filesLaboral != null){
                    dataUserSend.cv = this.state.filesLaboral.base64
                }else{
                    dataUserSend.cv = ""
                }
               
                console.log("dataUserSend",dataUserSend)


                const res = await Http.instance.post("rest/main/oportunity",JSON.stringify(dataUserSend));
                console.log("restaurante!!!!!! ",res)


                if(typeof res.errorHttp === 'undefined'){
                    if(res.status == 200){
                        this.setState({loadingLaboral:false,errorEnvioLaboral:"",mensajeEnvioLaboral:"Datos enviados",telefonoLaboral:"",emailLaboral:"",nombreLaboral:""})
                    }else{
                        this.setState({loadingLaboral:false,errorEnvioLaboral:"Hubo un error en el envio",mensajeEnvioLaboral:""})
                    }
                }else{
                    this.setState({loadingLaboral:false,errorEnvioLaboral:"Hubo un error en el envio",mensajeEnvioLaboral:""})
                }


                //console.log(dataUserSend)
            }
        }
    }

    enviarDatosFranquicia = async (e) => {
        if(this.state.loadingFranquicia == false){
            var validado = true
            if(this.state.nombreFranquicia == ""){
                this.setState({errorNombreFranquicia:true})
                validado = false
            }

            if(this.state.emailFranquicia == ""){
                this.setState({errorEmailFranquicia:true,errorEmailFranquiciaText:"campo obligatorio."})
                validado = false
            }else if(validator.isEmail(this.state.emailFranquicia) == false){
                this.setState({errorEmailFranquicia:true,errorEmailFranquiciaText:"Email no valido"})
                validado = false
            }


            if(this.state.telefono == ""){
                this.setState({errorTelefono:true})
                validado = false
            }else if(this.state.telefono.length != 8){
                this.setState({errorTelefono:true})
                validado = false
            }

            if(this.state.pais == ""){
                this.setState({errorPais:true})
                validado = false
            }

            if(this.state.terminos == false){
                this.setState({errorTerminos:true})
                validado = false
            }

            if(validado == true){
                this.setState({loadingFranquicia:true})

                var dataUserSend = {}
                dataUserSend.pais = this.state.pais
                dataUserSend.telefono = this.state.telefono
                dataUserSend.email = this.state.emailFranquicia
                dataUserSend.nombre = this.state.nombreFranquicia
                if(this.state.files != null){
                    dataUserSend.cv = this.state.files.base64
                    console.log(this.state.files)
                }else{
                    dataUserSend.cv = ""
                }


              
               
                const res = await Http.instance.post("rest/main/franchise",JSON.stringify(dataUserSend));
                console.log("restaurante!!!!!! ",res)


                if(typeof res.errorHttp === 'undefined'){
                    if(res.status == 200){
                        this.setState({loadingFranquicia:false,errorEnvioFranquicia:"",mensajeEnvioFranquicia:"Datos enviados",pais:"",telefono:"",emailFranquicia:"",nombreFranquicia:""})
                    }else{
                        this.setState({loadingFranquicia:false,errorEnvioFranquicia:"Hubo un error en el envio",mensajeEnvioFranquicia:""})
                    }
                }else{
                    this.setState({loadingFranquicia:false,errorEnvioFranquicia:"Hubo un error en el envio",mensajeEnvioFranquicia:""})
                }


                console.log(dataUserSend)
            }
        }
    }

    enviarDatos = async (e) => {

        if(this.state.loadingServicioCliente == false){
            var validado = true
            if(this.state.nombre == ""){
                this.setState({errorNombre:true})
                validado = false
            }
    
            if(this.state.email == ""){
                this.setState({errorEmail:true,errorEmailText:"campo obligatorio."})
                validado = false
            }else if(validator.isEmail(this.state.email) == false){
                this.setState({errorEmail:true,errorEmailText:"Email no valido"})
                validado = false
            }
    
            if(this.state.duda == ""){
                this.setState({errorDuda:true})
                validado = false
            }

            if(validado == true){
                //console.log(en)
                this.setState({loadingServicioCliente:true})
                var dataUserSend = {}
                dataUserSend.comentario = this.state.duda
                dataUserSend.email = this.state.email
                dataUserSend.nombre = this.state.nombre
               
                const res = await Http.instance.post("rest/main/contact",JSON.stringify(dataUserSend));
                console.log("restaurante!!!!!! ",res)


                if(typeof res.errorHttp === 'undefined'){
                    if(res.status == 200){
                        this.setState({loadingServicioCliente:false,errorEnvio:"",mensajeEnvio:"Datos enviados",duda:"",email:"",nombre:""})
                    }else{
                        this.setState({loadingServicioCliente:false,errorEnvio:"Hubo un error en el envio",mensajeEnvio:""})
                    }
                }else{
                    this.setState({loadingServicioCliente:false,errorEnvio:"Hubo un error en el envio",mensajeEnvio:""})
                }



                console.log(dataUserSend)
            }
        }
    }

    async componentDidMount() {


        this.setState({isMobileSend:this.props.isMobileSend})

        const res =  await Http.instance.get("rest/pages/contact-info");
        this.setState({htmlServicioCliente:res.contact.content,htmlFranquicias:res.franchice.content,loadData:false})

        const countries =  await Http.instance.get("rest/main/countries");
        this.setState({countries:countries})

        if(typeof this.props.match.params.tab != 'undefined'){
            this.setState({selectedIndex:1})
        }


        
    }

    cambiarFormato() {
        setTimeout(() => {
            $('.inputFile input[type="file"]').attr('accept', '.doc, .pdf, .docx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document');
        }, 1000);
       
    }

    render() {
      return (
        <Row className={this.state.isMobileSend ? "movil noPadding":""}>
            <Col xs={12} className={this.state.isMobileSend ? "noPadding":"" }>
                {
                    this.state.isMobileSend == false ?
                    <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                    : null
                }
                    
                <div className="clear"></div>
                <h1 className={this.state.isMobileSend == false ? 'tituloNuestrasTiendas' : 'tituloNuestrasTiendasMovil'}>Contáctenos</h1>
                
                <div className="clear"></div>

                
                <Row className={this.state.isMobileSend ? "altoMinimoMovil contMainMovil contContactenos noPadding":"itemCant1 contContactenos"}>
                    <form>
                        <Tabs  selectedIndex={this.state.selectedIndex} onSelect={this.handleSelectTabs}>
                                {
                                    this.state.isMobileSend ?
                                    <TabList className="movilTabs react-tabs__tab-list">
                                        <Tab tabFor="0">Contáctenos</Tab>
                                        {
                                            this.state.hiddenFranquicia == false ? <Tab tabFor="1" onClick={(e) => this.cambiarFormato(e)}>Franquicias</Tab> : null
                                        }
                                        
                                        <Tab tabFor="2" onClick={(e) => this.cambiarFormato(e)}>Oportunidades</Tab>
                                    </TabList>
                                    :
                                    <TabList className="react-tabs__tab-list">
                                        <Tab tabFor="0">&nbsp;&nbsp;&nbsp;Servicio al Cliente&nbsp;&nbsp;&nbsp;</Tab>
                                        {
                                            this.state.hiddenFranquicia == false ? <Tab tabFor="1" onClick={(e) => this.cambiarFormato(e)}>&nbsp;&nbsp;&nbsp;Franquicias&nbsp;&nbsp;&nbsp;</Tab>: null
                                        }
                                        
                                        <Tab tabFor="2" onClick={(e) => this.cambiarFormato(e)} >&nbsp;&nbsp;&nbsp;Oportunidades laborales&nbsp;&nbsp;&nbsp;</Tab>
                                    </TabList>
                                }

                            <TabPanel tabId="0">
                                <Row className="rowRegistro">
                                    <Col sm={6} >
                                        {
                                            this.state.loadData == true ?
                                                <div className="contSpinner"><Spinner /></div>
                                            :
                                            <div dangerouslySetInnerHTML={{__html: this.state.htmlServicioCliente}}  className="contFormularioContactenosHtml" style={this.state.isMobileSend == false ? {} : {}}></div>
                                        }

                                    </Col>
                                    <Col sm={6}>
                                        <div className="contFormularioContactenos">
                                            <div className="tituloContactenos">Formulario de Contacto</div>

                                            <div style={{marginTop:"30px"}}>
                                                <div>
                                                    NOMBRE COMPLETO
                                                </div>
                                                <input 
                                                onChange={(text) => {
                                                    this.setState({nombre:text.target.value,errorNombre:false})
                                                }}
                                                value={this.state.nombre}
                                                className={this.state.errorNombre ? "error" : ""} 
                                                id="firstName"
                                                maxchar="1"
                                                name="firstName" type="text" maxlength="30" />
                                                {
                                                    this.state.errorNombre == true ?
                                                        <label className="error" style={{marginBottom:"20px"}}>campo obligatorio.</label>
                                                    :null
                                                }
                                                

                                            </div>


                                            <div>
                                                <div>
                                                CORREO ELECTRÓNICO
                                                </div>
                                                <input 
                                                onChange={(text) => {
                                                    this.setState({email:text.target.value,errorEmail:false})
                                                }}
                                                value={this.state.email}
                                                className={this.state.errorEmail ? "error" : ""} 
                                                id="firstName"
                                                maxchar="1"
                                                name="firstName" type="text" maxlength="30" />
                                                {
                                                    this.state.errorEmail == true ?
                                                        <label className="error" style={{marginBottom:"20px"}}>{this.state.errorEmailText}</label>
                                                    :null
                                                }
                                                

                                            </div>


                                            <div>
                                                <div>
                                                    DUDA O COMENTARIO
                                                </div>
                                                
                                                <textarea 
                                                onChange={(text) => {
                                                    this.setState({duda:text.target.value,errorDuda:false})
                                                }}
                                                value={this.state.duda}
                                                className={this.state.errorDuda ? "error" : ""} 
                                                id="streetaddress" name="streetaddress" required="" ></textarea>
                                                {
                                                    this.state.errorDuda == true ?
                                                        <label className="error" style={{marginBottom:"20px"}}>campo obligatorio.</label>
                                                    :null
                                                }         
                                                

                                            </div>

                                            {
                                                this.state.errorEnvio != '' ?
                                                    <label className="error" style={{marginBottom:"20px",display:"block",textAlign:"center",fontSize:"14px"}}>{this.state.errorEnvio}</label>
                                                :null
                                            }

                                            {
                                                this.state.mensajeEnvio != '' ?
                                                    <label className="error" style={{marginBottom:"20px",color:"#8FD400",display:"block",textAlign:"center",fontSize:"14px"}}>{this.state.mensajeEnvio}</label>
                                                :null
                                            }

                                            <Button style={{width:"100%"}} onClick={(e) => this.enviarDatos(e)} className="btn-agregar btnContinuarCrearCuenta">
                                            {
                                                this.state.loadingServicioCliente == true ?
                                                    <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                                : 
                                                "ENVIAR MENSAJE"
                                            }
                                            </Button>

                                        </div>
                                    </Col> 
                                </Row>


                            </TabPanel>
                            {
                                this.state.hiddenFranquicia == false ? 
                                <TabPanel tabId="1">
                                    <Row className="rowRegistro">
                                        <Col sm={6}>

                                            {
                                                this.state.loadData == true ?
                                                    <div className="contSpinner"><Spinner /></div>
                                                :
                                                <div dangerouslySetInnerHTML={{__html: this.state.htmlFranquicias}}  className="contFormularioContactenosHtml"></div>
                                            }



                                        </Col>
                                        <Col sm={6}>
                                            <div className="contFormularioContactenos">
                                                <div className="tituloContactenos">Formulario</div>

                                                <div style={{marginTop:"30px"}}>
                                                    <div>
                                                        NOMBRE COMPLETO
                                                    </div>
                                                    <input 
                                                    onChange={(text) => {
                                                        this.setState({nombreFranquicia:text.target.value,errorNombreFranquicia:false})
                                                    }}
                                                    value={this.state.nombreFranquicia}
                                                    className={this.state.errorNombreFranquicia ? "error" : ""} 
                                                    id="firstName"
                                                    maxchar="1"
                                                    name="firstName" type="text" maxlength="30" />
                                                    {
                                                        this.state.errorNombreFranquicia == true ?
                                                            <label className="error" style={{marginBottom:"20px"}}>campo obligatorio.</label>
                                                        :null
                                                    }
                                                    

                                                </div>


                                                <div>
                                                    <div>
                                                    CORREO ELECTRÓNICO
                                                    </div>
                                                    <input 
                                                    onChange={(text) => {
                                                        this.setState({emailFranquicia:text.target.value,errorEmailFranquicia:false})
                                                    }}
                                                    value={this.state.emailFranquicia}
                                                    className={this.state.errorEmailFranquicia ? "error" : ""} 
                                                    id="firstName"
                                                    maxchar="1"
                                                    name="firstName" type="text" maxlength="30" />
                                                    {
                                                        this.state.errorEmailFranquicia == true ?
                                                            <label className="error" style={{marginBottom:"20px"}}>{this.state.errorEmailFranquiciaText}</label>
                                                        :null
                                                    }
                                                    

                                                </div>


                                                <div>
                                                    <div>
                                                        TELÉFONO
                                                    </div>
                                                    <InputMask 
                                                    onChange={(text) => {
                                                        const valor = text.target.value.replace(/\D/g, '')
                                                        this.setState({telefono:valor,errorTelefono:false})
                                                    }}
                                                    value={this.state.telefono}
                                                    className={this.state.errorTelefono ? "error" : ""} 
                                                    id="phoneNumber" mask="9999-9999" name="phoneNumber" placeholder="0000-0000"  inputmode="tel" required="true" type="tel" aria-required="true" aria-invalid="false" />
                                                    {
                                                        this.state.errorTelefono == true ?
                                                            <label className="error">campo obligatorio.</label>
                                                        :null
                                                    }
                                                </div>

                                                <div>
                                                    <div>
                                                        PAÍS EN EL QUE DESEAS UBICAR TU NEGOCIO
                                                    </div>
                                                    <select 
                                                    onChange={(text) => {

                                                        this.setState({pais:text.target.value,errorPais:false})


                                                    }}
                                                    value={this.state.pais}
                                                    className={this.state.errorPais ? "error" : ""} 
                                                    id="pais" name="pais">
                                                        <option value="">Seleccionar</option>

                                                        {this.state.countries.map(item => (
                                                            <option value={item.id}>{item.name}</option>
                                                        ))}

                                                    </select>
                                                    {
                                                        this.state.errorPais == true ?
                                                            <label className="error">campo obligatorio.</label>
                                                        :null
                                                    }
                                                </div>


                                                <div>
                                                    <div className='inputFile'>
                                                        HOJA DE VIDA O PERFIL COMERCIAL


                                                        <FileBase64
                                                            multiple={ false }
                                                            id="avatar" name="avatar"
                                                            
                                                            onDone={ this.getFiles.bind(this) } />
                                                        <small>Formatos permitidos: .doc, .docx, .pdf (Máx: 2MB)</small>   
                                                    </div>
                                                    
                                                    {
                                                        this.state.errorDepartamento == true ?
                                                            <label className="error">campo obligatorio.</label>
                                                        :null
                                                    }
                                                </div>
                                                
                                                

                                                <div className='titleAlergenosItems'>

                                                    <input 
                                                        checked={this.state.terminos}
                                                        onChange={(text) => {
                                                            this.setState({terminos:text.target.checked,errorTerminos:!text.target.checked})
                                                        }}
                                                    id="create-account-terms"  type="checkbox" />
                                                    <label className="checkboxCustom" for="create-account-terms"></label>
                                                    <label for="create-account-terms" className="acepto">He leído y acepto los 
                                                        <a href="/terminoscondiciones" target="_blank" alt="Terminos de Uso" className="terminos"> términos y  condiciones del aviso legal</a>.
                                                    </label>
                                                </div>
                                                <label  className={this.state.errorTerminos ? "error" : ""}  style={{display: this.state.errorTerminos ? "" : "none", width: "100%",textAlign: "center"}}>campo obligatorio.</label>

                                                {
                                                    this.state.errorEnvioFranquicia != '' ?
                                                        <label className="error" style={{marginBottom:"20px",display:"block",textAlign:"center",fontSize:"14px"}}>{this.state.errorEnvioFranquicia}</label>
                                                    :null
                                                }

                                                {
                                                    this.state.mensajeEnvioFranquicia != '' ?
                                                        <label className="error" style={{marginBottom:"20px",color:"#8FD400",display:"block",textAlign:"center",fontSize:"14px"}}>{this.state.mensajeEnvioFranquicia}</label>
                                                    :null
                                                }

                                                <Button style={{width:"100%"}} onClick={(e) => this.enviarDatosFranquicia(e)} className="btn-agregar btnContinuarCrearCuenta">
                                                {
                                                    this.state.loadingFranquicia == true ?
                                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                                    : 
                                                    "ENVIAR SOLICITUD"
                                                }
                                                </Button>

                                            </div>
                                        </Col> 
                                        
                                    </Row>
                                </TabPanel>
                                : null
                            }

                           
                            <TabPanel tabId="2">
                                <Row className="rowRegistro">

                                     <div class="detalle">Si estás interesado en trabajar con nosotros, completa el siguiente formulario y al contar con una oportunidad laboral nos pondremos en contacto contigo.</div>

                                     <Col sm={6} >
                                        <img style={{marginTop:"20px",width:"96%",marginLeft:"2%"}} className="logo-image" src="images/contacto.png" alt="llaollao" />


                                    </Col>
                                    <Col sm={6}>
                                        <div className="contFormularioContactenos">
                                            <div className="tituloContactenos" style={{marginBottom:"30px"}}>Formulario de solicitud de empleo</div>
                                            <Row className="nopadding">
                                                <Col sm={12}>
                                                    <div >
                                                        <div>
                                                            NOMBRE COMPLETO
                                                        </div>
                                                        <input 
                                                        onChange={(text) => {
                                                            this.setState({nombreLaboral:text.target.value,errorNombreLaboral:false})
                                                        }}
                                                        value={this.state.nombreLaboral}
                                                        className={this.state.errorNombreLaboral ? "error" : ""} 
                                                        maxchar="1"
                                                        type="text" maxlength="30" />
                                                        {
                                                            this.state.errorNombreLaboral == true ?
                                                                <label className="error" style={{marginBottom:"20px"}}>campo obligatorio.</label>
                                                            :null
                                                        }
                                                        

                                                    </div>

                                                </Col>
                                                <Col sm={12}>
                                                    <div>
                                                        <div>
                                                        CORREO ELECTRÓNICO
                                                        </div>
                                                        <input 
                                                        onChange={(text) => {
                                                            this.setState({emailLaboral:text.target.value,errorEmailLaboral:false})
                                                        }}
                                                        value={this.state.emailLaboral}
                                                        className={this.state.errorEmailLaboral ? "error" : ""} 
                                                        id="firstName"
                                                        maxchar="1"
                                                        name="firstName" type="text" maxlength="30" />
                                                        {
                                                            this.state.errorEmailLaboral == true ?
                                                                <label className="error" style={{marginBottom:"20px"}}>{this.state.errorEmailLaboralText}</label>
                                                            :null
                                                        }
                                                        

                                                    </div>
                                                </Col>

                                                <Col sm={12}>
                                                    <div>
                                                        <div>
                                                            TELÉFONO
                                                        </div>
                                                        <InputMask 
                                                        onChange={(text) => {
                                                            const valor = text.target.value.replace(/\D/g, '')
                                                            this.setState({telefonoLaboral:valor,errorTelefonoLaboral:false})
                                                        }}
                                                        value={this.state.telefonoLaboral}
                                                        className={this.state.errorTelefonoLaboral ? "error" : ""} 
                                                        id="phoneNumber" mask="9999-9999" name="phoneNumber" placeholder="0000-0000"  inputmode="tel" required="true" type="tel" aria-required="true" aria-invalid="false" />
                                                        {
                                                            this.state.errorTelefonoLaboral == true ?
                                                                <label className="error">campo obligatorio.</label>
                                                            :null
                                                        }
                                                    </div>

                                                </Col>
                                                <Col sm={12}>
                                                    <div>
                                                        <div className='inputFile'>
                                                            HOJA DE VIDA


                                                            <FileBase64
                                                                multiple={ false }
                                                                id="fileCvLaboral" name="fileCvLaboral"
                                                                onDone={ this.getFilesLaboral.bind(this) } />
                                                            <small>Formatos permitidos: .doc, .docx, .pdf (Máx: 2MB)</small>   
                                                            {
                                                                this.state.errorCVLaboral == true ?
                                                                    <label className="error" style={{padding:"0px"}}>campo obligatorio.</label>
                                                                :null
                                                            }

                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </Col>
                                                
                                            </Row>
         

                                            {
                                                this.state.errorEnvioLaboral != '' ?
                                                    <label className="error" style={{marginBottom:"20px",display:"block",textAlign:"center",fontSize:"14px"}}>{this.state.errorEnvioLaboral}</label>
                                                :null
                                            }

                                            {
                                                this.state.mensajeEnvioLaboral != '' ?
                                                    <label className="error" style={{marginBottom:"20px",color:"#8FD400",display:"block",textAlign:"center",fontSize:"14px"}}>{this.state.mensajeEnvioLaboral}</label>
                                                :null
                                            }

                                            <Button style={{width:"300px"}} onClick={(e) => this.enviarDatosLaboral(e)} className="btn-agregar btnContinuarCrearCuenta">
                                            {
                                                this.state.loadingLaboral == true ?
                                                    <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                                : 
                                                "ENVIAR SOLICITUD"
                                            }
                                            </Button>

                                        </div>
                                    </Col> 
                                    
                                </Row>
                            </TabPanel>
                        </Tabs>
                    </form>        
                </Row>
                

                

                
            </Col>
        </Row>
      );
    }
}

export default Contactenos;