import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { withRouter } from 'react-router-dom';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import Modal from 'react-modal';
import { HeaderBotones } from '../../header/header_botones'
import Constants from '../../libs/constants'

import $ from 'jquery'; 

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 0,
      width: "700px",
      bottom: 'auto',
      transform: 'translate(-50%,-50%)',
      //transform: 'translate(0%, -50%)',
    },
  };


class Home extends React.Component {

    state = {
        loadingProductos: true,
        loadingCarrucel: true,
        dataProductos:{"main-top":{name:"",products:[]}},
        itemProducto:{price:0},
        dataCarrucel:[],
        setIsOpen: false,
    };


    closeOpenModal() {
        this.setState({setIsOpen:!this.state.setIsOpen})
    }

    verDetalle(e,item,url) {
        item.url = url
        console.log(item)
        this.setState({itemProducto:item,setIsOpen:!this.state.setIsOpen})
    }

    constructor(props) {
        super(props);
        this.closeOpenModal = this.closeOpenModal.bind(this);
    } 

    async componentDidMount() {

        //sesion iniciada
        var idUser = 0
        const customer = localStorage.getItem("customer");
        const initialCustomer = JSON.parse(customer);
        if(initialCustomer != null){
            idUser = initialCustomer.id
        }

        var dataUser = {}
        dataUser.customer = idUser
        dataUser.location = Constants.instance.idDireccion()

        const res =  await Http.instance.post("rest/main/home-product",JSON.stringify(dataUser));
        console.log("homeproductos",res)
        if(typeof res.errorHttp == 'undefined'){
            this.setState({dataProductos:res,loadingProductos:false})
        }

        const resCarrucel =  await Http.instance.get("rest/main/home-slider");
        if(typeof resCarrucel.errorHttp == 'undefined'){
            this.setState({dataCarrucel:resCarrucel,loadingCarrucel:false})
        }
        


        console.log("resCarrucel",resCarrucel)
        
    }

    render() {
      return (
        <div>
             <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
             <Row>
                <Col xs={8}>
                    {
                        this.state.loadingCarrucel == true ?
                            null
                        :
                        <Carousel
                            autoPlay={true}
                            showArrows={false}
                            infiniteLoop={true}
                            showThumbs={false}
                            showStatus={false}
                            interval={6000}
                            >

                            {this.state.dataCarrucel.map(item => (
                                <div>
                                    {
                                        item.url == "null" ?
                                        <img src={item.image} />
                                        :
                                        <a href={item.url} target={item.url_blank == true ? "_blank" :""} style={{display:'block'}}>
                                            <img src={item.image} />
                                        </a>
                                    }
                                    
                                </div>
                            ))}
                            
                            
                        </Carousel>
                    }
                    
                    {
                    this.state.loadingProductos == true ?
                        <div className="contSpinner"><Spinner /></div>
                    : 
                        <div>
                            <Row>
                                <h1 className="tituloCat1">{this.state.dataProductos["main-top"].name}</h1>
                                <Row  className="nopadding">
                                {this.state.dataProductos["main-top"].products.map(item => (
                                    <Col xs={4}>
                                    <div className="itemCant1">
                                        <div><span className="titleCant1">{item.name_online.split(' ')[0]}</span> <span className="tamanioCant1">{item.name_online.split(' ')[1]}</span></div>
                                        <div className="detalleCant1">{item.small_desc}</div>
                                        {
                                            Constants.instance.onlyCatalog == false ?
                                                <div className="precioCant1">{item.currency}{item.price.toFixed(2)}</div>
                                            : null
                                        }
                                        <Row className="contAdd">
                                        <Col xs={7}>
                                            {
                                                Constants.instance.onlyCatalog() == false ?
                                                <Button onClick={(e) => {
                                                    if(Constants.instance.idDireccion() == 0){
                                                        $(".linkDirecciones").click()
                                                    }else{
                                                        window.location.href=`categoria/${item.categoryId}/producto/${item.id}`
                                                    }
                                                }
                                                } className="btn-agregar">AGREGAR</Button>
                                                : null
                                            }
                                           

                                            <a className="linkDetalle" onClick={(e) => this.verDetalle(e,item,`categoria/${item.categoryId}/producto/${item.id}`)}>Ver detalle</a>
                                            <div className="descripcionCant1">
                                                {item.home_desc}
                                            </div>
                                        </Col>
                                        <Col xs={5} className="nopadding">
                                            <img src={item.small_image} className="imgCant1" />
                                        </Col>
                                        </Row>
                                    </div>
                                    </Col>
                                ))}
                                </Row>
                            </Row>

                            {
                                this.state.dataProductos["main-middle"].products.length == 0 ?
                                    null
                                :
                                <Row>
                                    <Col xs={12} className="contCant2">
                                        <div className="itemCant2">
                                            <Row>
                                                <Col xs={6}>
                                                    <h2 className="tituloCat2">{this.state.dataProductos["main-middle"].name}</h2>
                                                    <span className="descripcionCat2">{this.state.dataProductos["main-middle"].desc}</span>
                                                    <Row style={{marginTop:"20px"}}>
                                                        {this.state.dataProductos["main-middle"].products.map((item, index)  => (
                                                            <Col xs={6} style={{position:"relative"}}>
                                                                <img src="/images/vaso.png" className="imgCant2" style={{marginBottom:"5px"}} />
                                                                <span className="sizeCat2">{item.name_online}</span>
                                                                <span  className="detalleCat2">{item.small_desc}</span>
                                                                {
                                                                    Constants.instance.onlyCatalog == false ?
                                                                        <span  className="precioCat2" style={{marginTop:"15px"}}>{item.currency}{item.price.toFixed(2)}</span>
                                                                    : null
                                                                }
                                                                {
                                                                    Constants.instance.onlyCatalog == false ?
                                                                        <Button onClick={(e) => {

                                                                            if(Constants.instance.idDireccion() == 0){
                                                                                $(".linkDirecciones").click()
                                                                            }else{
                                                                                window.location.href=`categoria/${item.categoryId}/producto/${item.id}`
                                                                            }

                                                                            
                                                                            }} className="btn-agregar" style={{margin:"0px auto",width:"80%",display:"block"}}>AGREGAR</Button>
                                                                    : null
                                                                }


                                                                <a onClick={(e) => this.verDetalle(e,item,`categoria/${item.categoryId}/producto/${item.id}`)} className="linkDetalle">Ver detalle</a>
                                                                {
                                                                    index == 0 ?  <div className="lineaVertical"></div> : null
                                                                }
                                                            
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    
                                                    
                                                </Col>
                                                <Col xs={6} className="nopadding" style={{position:"relative"}}>
                                                    <img src="/images/take.png" className="imgCant1" style={{marginTop:"15px",marginBottom:"30px"}} />
                                                    <div className="imagenesIlustrativaTake">{this.state.dataProductos["main-middle"].restriction}</div>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            

                            {
                                this.state.dataProductos["main-bottom"].product === null ?
                                    null
                                :

                                <Row>
                                    <Col xs={12} className="contCant2">
                                        <div className="itemCant2">
                                            <Row>
                                                <Col xs={7} className="nopadding">
                                                    <img src={this.state.dataProductos["main-bottom"].product.image_home} className="imgCant1" style={{marginTop:"15px"}} />
                                                    <span className="descripcionCat3">{this.state.dataProductos["main-bottom"].restriction}</span>

                                                
                                                </Col>

                                                <Col xs={5} style={{paddingRight:"25px"}}>
                                                    <h2 className="tituloCat3">{this.state.dataProductos["main-bottom"].product.name}</h2>
                                                    <span  className="detalleCat3">{this.state.dataProductos["main-bottom"].product.small_desc}</span>
                                                    <span className="subTituloCat3">{this.state.dataProductos["main-bottom"].product.desc}</span>

                                                    <Row>
                                                        <Col xs={6}></Col>
                                                        <Col xs={6}>
                                                            {
                                                                Constants.instance.onlyCatalog == false ?
                                                                    <span  className="precioCat2" style={{marginTop:"10px"}}>${this.state.dataProductos["main-bottom"].product.price.toFixed(2)}</span>
                                                                : null
                                                            }
                                                            {
                                                                Constants.instance.onlyCatalog() == false ?
                                                                    <Button onClick={(e) => {
                                                                        if(Constants.instance.idDireccion() == 0){
                                                                            $(".linkDirecciones").click()
                                                                        }else{
                                                                            window.location.href=`categoria/${this.state.dataProductos["main-bottom"].product.name}`
                                                                        }

                                                                        
                                                                        }}  className="btn-agregar" style={{margin:"0px auto",width:"100%",display:"block"}}>AGREGAR</Button>
                                                                    : null
                                                            }

                                                            <a className="linkDetalle" onClick={(e) => this.verDetalle(e,this.state.dataProductos["main-bottom"].product,`${this.state.dataProductos["main-bottom"].url_link}`)} >Ver detalle</a>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            }

                            
                
                        </div>
                    }
                    
                </Col>

                <Col xs={4}>
                
                    <div className="clear"></div>
                    
                    {
                    this.state.loadingProductos == true ?
                        <div className="contSpinner"><Spinner /></div>
                    : 
                    <Row>

                            {this.state.dataProductos["side-top"].products.map(item => (
                                <Col xs={12}>
                                    <div className="itemCantLateralRight" style={{paddingBottom:"10px"}}>
                                        <Row>
                                            <Col xs={7}>
                                            <div><span className="titleCant1">{item.name_online.split(' ')[0]}</span> <span className="tamanioCant1">{item.name_online.split(' ')[1]}</span></div>
                                            <div className="detalleCant1" dangerouslySetInnerHTML={{__html: item.small_desc}}></div>

                                            </Col>
                                            <Col xs={5} className="nopadding">
                                                <img src={item.small_image} className="imgCant1" />
                                            </Col>
                                        </Row>
                                        <Row className="flex-row-reverse">
                                            
                                            <Col xs={5} className="nopadding">
                                            {
                                                Constants.instance.onlyCatalog() == false ?
                                                    <Button onClick={(e) => {
                                                        if(Constants.instance.idDireccion() == 0){
                                                            $(".linkDirecciones").click()
                                                        }else{
                                                            window.location.href=item.url_link
                                                        }

                                                        
                                                        } } className="btn-agregar" style={{marginTop:"0px"}}>AGREGAR</Button>
                                                : null
                                            }
                                            <a className="linkDetalle" onClick={(e) => this.verDetalle(e,item,item.url_link)}>Ver detalle</a>

                                            </Col>
                                            <Col xs={3} className="nopadding">
                                            {
                                                Constants.instance.onlyCatalog == false ?
                                                    <div className="precioCant1" style={{textAlign:"right",marginRight:"12px"}}>{item.currency}{item.price.toFixed(2)}</div>
                                                : null
                                            }
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            ))}


                            

                        

                        

                            <Col xs={12}>
                                {
                                    this.state.dataProductos["side-promo"].type == "single_b" ?
                                        <div className='itemPromo'>
                                            <a href={this.state.dataProductos["side-promo"].url_link} target={this.state.dataProductos["side-promo"].link_target}>
                                                <img src={this.state.dataProductos["side-promo"].image} />
                                            </a>

                                        </div>
                                    :
                                    <div className="itemMain">
                                        <Row>
                                            <h2 className="tituloCat3" style={{textAlign:"left"}}>{this.state.dataProductos["side-promo"].product.name}</h2>
                                            <span  className="detalleCat3" style={{textAlign:"left"}}>{this.state.dataProductos["side-promo"].product.small_desc}</span>
                                            


                                            <Col xs={6}>
                                            <span className="subTituloCat3" style={{textAlign:"left"}}>{this.state.dataProductos["side-promo"].product.desc}</span>

                                            </Col>
                                            <Col xs={6} className="nopadding">
                                                {
                                                    Constants.instance.onlyCatalog == false ?
                                                        <div className="precioCat2" style={{marginTop:"5px"}}>{this.state.dataProductos["side-promo"].product.currency}{this.state.dataProductos["side-promo"].product.price.toFixed(2)}</div>
                                                    : null
                                                }
                                                {
                                                    Constants.instance.onlyCatalog() == false ?
                                                        <Button onClick={(e) => {
                                                            if(Constants.instance.idDireccion() == 0){
                                                                $(".linkDirecciones").click()
                                                            }else{
                                                                window.location.href=`${this.state.dataProductos["side-promo"].url_link}`
                                                            }

                                                            
                                                            }} className="btn-agregar" style={{margin:"0px auto",width:" 80%",display: "block"}}>AGREGAR</Button>
                                                    : null
                                                }
                                                <a className="linkDetalle" onClick={(e) => this.verDetalle(e,this.state.dataProductos["side-promo"].product,`${this.state.dataProductos["side-promo"].url_link}`)}>Ver detalle</a>

                                            </Col>
                                        </Row>
                                        <img src={this.state.dataProductos["side-promo"].product.image_home} className="imgMain" />
                                    </div>
                                }
                            
                            </Col>


                    </Row>
                    }
                </Col>

                <div style={{display:"none"}}>
                    <Modal
                        isOpen={this.state.setIsOpen}
                        //onAfterOpen={afterOpenModal}
                        //onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        {
                            this.state.itemProducto.price != 0 ?
                                <div>
                                    <img onClick={this.closeOpenModal} src="/images/cross.png" className="closeModal" />
                                    <h2 className="tituloTienda">{this.state.itemProducto.name}</h2>
                                    <div className="detalleProductoModal" style={{paddingLeft:"0px"}}>{this.state.itemProducto.desc}</div>

                                    <Row style={{marginTop:"10px"}}>
                                    <Col xs={6}>
                                        <img src={this.state.itemProducto.image} className="imgCant1" />
                                    </Col>
                                    <Col xs={6}>
                                        <div className="descripcionProductoModal">{this.state.itemProducto.prod_desc}</div>
                                        <div className="contValorNutricional">
                                            <div className="titleValoresNutricionales">VALORES NUTRICIONALES POR CADA 100 GRAMOS DE YOGURT HELADO:</div>
                                            <div style={{margin:"5px 20px"}}>
                                                <Row>

                                                    {typeof this.state.itemProducto.custom_data.nutricional_info.cal !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Calorías:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.cal}</span></Col> : null}
                                                    {typeof this.state.itemProducto.custom_data.nutricional_info.pro !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Proteínas:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.pro}</span></Col> : null}
                                                    {typeof this.state.itemProducto.custom_data.nutricional_info.fat !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Grasas:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.fat}</span></Col> : null}
                                                    {typeof this.state.itemProducto.custom_data.nutricional_info.clc !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Calcio:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.clc}</span></Col> : null}
                                                    {typeof this.state.itemProducto.custom_data.nutricional_info.sal !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Sal:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.sal}</span></Col> : null}
                                                    {typeof this.state.itemProducto.custom_data.nutricional_info.ftrans !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Grasas trans:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.ftrans}</span></Col> : null}
                                                    {typeof this.state.itemProducto.custom_data.nutricional_info.azu !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Azúcar:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.azu}</span></Col> : null}

                                                </Row>
                                            </div>
                                            
                                        </div>
                                            <div>
                                                {
                                                    this.state.itemProducto.custom_data.allergens.length == 10000 ?
                                                        <div>
                                                            <div className="titleAlergenos">ALÉRGENOS {this.state.itemProducto.custom_data.allergens.EGG}</div>
                                                            <div>
                                                            <Row>
                                                                {typeof this.state.itemProducto.custom_data.allergens.hasOwnProperty("EGG") !== 'undefined' ? 
                                                                    <Col xs={4} className="titleAlergenosItems">
                                                                        <img src="/images/huevo.png" className="iconAlergenos" />
                                                                        <div>Huevo</div>
                                                                    </Col>
                                                                : null}

                                                                {typeof this.state.itemProducto.custom_data.allergens.hasOwnProperty("NUTS") !== 'undefined' ? 
                                                                    <Col xs={4} className="titleAlergenosItems">
                                                                        <img src="/images/frutossecos.png" className="iconAlergenos" />
                                                                        <div>NUECES</div>
                                                                    </Col>
                                                                : null}

                                                                {typeof this.state.itemProducto.custom_data.allergens.hasOwnProperty("MILK") !== 'undefined' ? 
                                                                    <Col xs={4} className="titleAlergenosItems">
                                                                        <img src="/images/leche.png" className="iconAlergenos" />
                                                                        <div>Leche</div>
                                                                    </Col>
                                                                : null}
                                                            </Row>
                                                            </div>
                                                        </div>
                                                    : null
                                                }
                                            

                                                <div className="contAgregarModal">
                                                    {
                                                        Constants.instance.onlyCatalog() == false ?
                                                            <button onClick={(e) => {
                                                                if(Constants.instance.idDireccion() == 0){
                                                                    this.setState({setIsOpen:false})
                                                                    $(".linkDirecciones").click()
                                                                }else{
                                                                    window.location.href=this.state.itemProducto.url
                                                                }

                                                                
                                                                }} type="button" className="btn-agregar btn btn-primary">AGREGAR</button>
                                                        : null
                                                    }
                                                    {
                                                        Constants.instance.onlyCatalog == false ?
                                                            <div className="precioCat2">{this.state.itemProducto.currency}{this.state.itemProducto.price.toFixed(2)}</div>
                                                        : null
                                                    }
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            :
                            null
                        }
                        
                    </Modal>
                </div>

            </Row>
        </div>
        
      );
    }
}

export default Home;