import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import InputMask from 'react-input-mask';
import validator from 'validator'
import { HeaderBotones } from '../../header/header_botones'

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 0,
      width: "500px",
      bottom: 'auto',
      transform: 'translate(-50%,-50%)',
      //transform: 'translate(0%, -50%)',
    },
  };

  const customStylesModal = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 0,
      width: "500px",
      bottom: 'auto',
      transform: 'translate(-50%,-50%)',
      //transform: 'translate(0%, -50%)',
    },
  };

class Club extends React.Component {

    state = {
        loadingTiendas: false,
        setIsOpen: false,
        dataTienda:[],
        dui:"",
        errorDui:false,
        loadingPuntos:false,
        setIsOpenPuntos:false,
        setIsOpenRegistro:false,
        setIsOpenmensaje:false,
        resPuntos:{points:{activos:0},prizes:[]},
        nombre:"",
        apellido:"",
        telefono:"",
        duiRegistro:"",
        email:"",
        loadingRegistroLeal:false,
        errorRegistro:"",
        customer:{id:-1},
        tipoDocumento:"",
        duiRegistro:"",
        genero:"",
    };

    closeOpenModal() {
        this.setState({setIsOpen:!this.state.setIsOpen})
    }

    closeOpenModalPuntos() {
        this.setState({setIsOpenPuntos:!this.state.setIsOpenPuntos})
    }

    closeOpenModalRegistro() {
        this.setState({setIsOpenRegistro:!this.state.setIsOpenRegistro})
    }

    closeOpenModalMensaje() {
        this.setState({setIsOpenmensaje:!this.state.setIsOpenmensaje})
    }


    constructor(props) {
        super(props);
        this.closeOpenModal = this.closeOpenModal.bind(this);
        this.closeOpenModalPuntos = this.closeOpenModalPuntos.bind(this);
        this.closeOpenModalRegistro = this.closeOpenModalRegistro.bind(this);
        this.closeOpenModalMensaje = this.closeOpenModalMensaje.bind(this);

    }

    openConsultaPunto(e) {
        if(this.state.customer.id == -1){
            this.props.loginRequerido()
        }else{
            this.setState({setIsOpen:true})

        }
        
        
    }

    openRegistroLeal(e) {
        this.setState({setIsOpenRegistro:true})
    }

    async comprobarPuntos(e){
        if(this.state.dui == ""){
            this.setState({errorDui:true})
            return
        }else if(this.state.dui.length < 8){
            this.setState({errorDui:true})
            return
        }

        this.setState({loadingPuntos:true})

        var dataUserSend = {}
        dataUserSend.identity = this.state.dui
        dataUserSend.customer = this.state.customer.id
        dataUserSend.user = this.state.customer.userId

        const res = await Http.instance.post("rest/main/llaoclub-points",JSON.stringify(dataUserSend));

        console.log(res)
        console.log(dataUserSend)
        if(typeof res.errorHttp === 'undefined'){
            this.setState({loadingPuntos:false,resPuntos:res,setIsOpen:false,setIsOpenPuntos:true})
        }else{
            this.setState({loadingPuntos:false})
        }

    }
    
    async registrarLeal(e){
        if(this.state.nombre == ""){
            this.setState({errorNombre:true})
            return
        }

        if(this.state.apellido == ""){
            this.setState({errorApellido:true})
            return
        }

        

        if(this.state.telefono == ""){
            this.setState({errorTelefono:true})
            return
        }else if(this.state.telefono.length != 8){
            this.setState({errorTelefono:true})
            return
        }

        if(this.state.email == ""){
            this.setState({errorEmail:true,errorEmailText:"campo obligatorio"})
            return
        }else if(validator.isEmail(this.state.email) == false){
            this.setState({errorEmail:true,errorEmailText:"Email no valido"})
            return
        }

        const date = this.state.year+'/'+this.state.mes+'/'+this.state.dia;

        if (validator.isDate(date)) {            
            this.setState({errorFecha:false,errorFechaTexto:""})
        } else {
            this.setState({errorFecha:true,errorFechaTexto:"Fecha no valida"})
            return
        }

        if(this.state.tipoDocumento == ""){
            this.setState({errorTipoDocumento:true})
            return
        }

        if(this.state.duiRegistro == ""){
            this.setState({errorDuiRegistro:true})
            return
        }

        if(this.state.genero == ""){
            this.setState({errorGenero:true})
            return
        }

        
        this.setState({loadingRegistroLeal:true})

        var dataUserSend = {}
        dataUserSend.identity = this.state.duiRegistro
        dataUserSend.email = this.state.email
        dataUserSend.gender = this.state.genero
        dataUserSend.typeIdentity = this.state.tipoDocumento
        dataUserSend.birthday =  `${this.state.dia}/${this.state.mes}/${this.state.year}`
        dataUserSend.email = this.state.email
        dataUserSend.phone = this.state.telefono
        if(this.state.customer.id == -1){
            dataUserSend.customer = 0
            dataUserSend.user = 0
        }else{
            dataUserSend.customer = this.state.customer.id
            dataUserSend.user = this.state.customer.userId
        }
        
        dataUserSend.firstname = this.state.nombre
        dataUserSend.lastname = this.state.apellido

        console.log(dataUserSend)

        const res = await Http.instance.post("rest/main/llaoclub-register",JSON.stringify(dataUserSend));

        console.log(res)


        if(typeof res.errorHttp === 'undefined'){
            if(res.status != 200){
                this.setState({loadingRegistroLeal:false,errorRegistro:res.message})
            }else{
                this.setState({loadingRegistroLeal:false,setIsOpenRegistro:false,setIsOpenmensaje:true})
            }
        }else{
            this.setState({loadingRegistroLeal:false,errorRegistro:"Hubo un error en el regisro"})
        }
    }

    async componentDidMount() {

        this.setState({loadingTiendas:false,isMobileSend:this.props.isMobileSend}, () => {
            
            this.state.isMobileSend ?  customStyles.content.width = "80%" : customStyles.content.width = "500px"
            this.state.isMobileSend ?  customStylesModal.content.width = "80%" : customStylesModal.content.width = "500px"
            this.state.isMobileSend ?  customStylesModal.content.height = "85%" : customStylesModal.content.height = "96%"
            
        });


        const res =  await Http.instance.get("rest/main/llaoclub-prizes");
        this.setState({dataTienda:res,loadingTiendas:true})

         //sesion iniciada
         const customer = localStorage.getItem("customer");
         const initialCustomer = JSON.parse(customer);

         console.log("initialCustomer",initialCustomer)
         if(initialCustomer != null){

             this.setState({customer:initialCustomer,dui:initialCustomer.identity}, () => {});
         }
        
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
      return (
        <Row className={this.state.isMobileSend ? "movil noPadding":""}>
            <Col xs={12}>
                {
                    this.state.isMobileSend == false ?
                    <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                    : null
                }
                    
                <div className="clear"></div>
                <h1 className={this.state.isMobileSend == false ? 'tituloNuestrasTiendas' : 'tituloNuestrasTiendasMovil'}>llaollao club</h1>
                <div className="clear"></div>

                {
                    this.state.loadingTiendas == false ?
                        <div className="contSpinner"><Spinner /></div>
                    :
                    <Row className={this.state.isMobileSend ? "contMainMovil":"itemCant1"}>
                        <img src="https://llaollao-assets.sfo3.digitaloceanspaces.com/common-files/page/top-llaollaoclub.png" className={this.state.isMobileSend ? "imgTienda noPadding":"imgTienda"} />
                        <Row className={this.state.isMobileSend ? "noPadding":""} style={this.state.isMobileSend == false ? {paddingLeft:"15%",paddingRight:"15%",marginTop:"20px"} : {}}>
                            <Col sm={6} className={this.state.isMobileSend ? "noPadding":""}>
                                <Button onClick={(e) => this.openConsultaPunto(e)} style={this.state.isMobileSend ? {height:"50px"} : {}} className="btn-agregar btConsulta">Consulta tus puntos aquí</Button>
                            </Col>
                            <Col sm={6} className={this.state.isMobileSend ? "noPadding":""}>
                                <Button onClick={(e) => this.openRegistroLeal(e)} style={this.state.isMobileSend ? {height:"50px"} : {}} className="btn-agregar btRegistrateClub">Regístrate<div className='soloUnMinuto'>Solo te tomará 1 mínuto</div></Button>
                            </Col>
                        </Row>

                        <div style={{height:"50px"}}></div>
                        {this.state.dataTienda.map(item => (
                            <Col xs={this.state.isMobileSend ? 6 : 2}>
                                <div className="itemProductoLeal">
                                    <img src={item.image} className="imgTienda" />
                                    <Row style={{marginTop:"0px"}}>
                                        <h2 className="nombreProductoLeal" dangerouslySetInnerHTML={{__html: item.name}}></h2>
                                        <div className="puntoProductoLeal">{item.points} puntos</div>
                                    </Row>
                                    <div className="clear"></div>
        
                                </div>
                            </Col>
                        ))}
                        <div className='contTusPuntos' style={{textAlign:"center",fontSize:"13px"}}>Tus puntos de Ilaollao club son canjeables únicamente en tienda. Consulta todos los términos y condiciones de Ilaollao club  <a href="/terminoscondiciones" alt="Terminos de Uso" className="teGustaria"> aquí</a></div>

                    </Row>
                }


                <Modal
                    isOpen={this.state.setIsOpen}
                    style={customStyles}
                    contentLabel=""
                >
                    <div>
                        <img onClick={this.closeOpenModal} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left"}}>Consulta de Puntos</div>
                        <div className="detalleProductoModal" style={{paddingLeft:"0px",fontSize:"14px"}}>Puedes consultar únicamente tu Documento personal.</div>

                        <Row className="rowRegistro">
                            <div style={{marginTop:"30px",paddingRight:"10%",paddingLeft:"10%"}}>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-file"></i> &nbsp;&nbsp;DOCUMENTO
                                </div>
                                <input
                                readonly
                                value={this.state.dui}
                                type="text" 
                                className={this.state.errorDui ? "error" : ""} 
                                id="duiNumber"  name="phoneNumber" placeholder="0000000-0" />
                                {
                                    this.state.errorDui == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                            </div>

                            <Button onClick={(e) => this.comprobarPuntos(e,false)} style={{width:"80%",marginLeft:"10%"}} className="btn-agregar btnContinuarCrearCuenta">
                                {
                                    this.state.loadingPuntos == true ?
                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                    : 
                                    "CONSULTAR PUNTOS"
                                }

                                
                            </Button>

                        </Row>
                        
                    </div>
                    
                </Modal>

                <Modal
                    isOpen={this.state.setIsOpenPuntos}
                    style={customStyles}
                    contentLabel=""
                >
                    <div>
                        <img onClick={this.closeOpenModalPuntos} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left"}}>Consulta de Puntos llaollao</div>
                        <div className="detalleProductoModal" style={{paddingLeft:"0px",fontSize:"14px"}}>El cálculo de tus puntos acumulados es:</div>

                        <Row className="rowRegistro" style={{paddingRight:"7%",paddingLeft:"7%"}}>
                            <Col sm={4}></Col>
                            <Col sm={4}>
                                <div style={{position:"relative"}}>
                                    <div className='tienes'>tienes</div>
                                    <div className='cantidadPuntosLeal'>{this.state.resPuntos.points.activos}</div>
                                    <div className='puntosLeal'>puntos</div>
                                    <img src="/images/silueta_llaollao.png" style={this.state.isMobileSend ? {width:"80%",marginLeft:"10%"} : {}} className="imgTienda" />
                                </div>
                            </Col>
                        </Row>
                        <div className='contTusPuntos' style={{textAlign:"center",fontSize:"13px"}}>Canjeables únicamente en nuestras tiendas</div>
                    </div>
                    
                </Modal>


                <Modal
                    isOpen={this.state.setIsOpenRegistro}
                    style={customStylesModal}
                    contentLabel=""
                >
                    <div>
                        <img onClick={this.closeOpenModalRegistro} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left"}}>Regístrate en llaollao club</div>
                        <div className="detalleProductoModal" style={{paddingLeft:"0px",fontSize:"14px"}}>Comienza a acumular puntos con las compras que realices en llaollao</div>

                        <Row className="rowRegistro" style={{paddingRight:"7%",paddingLeft:"7%"}}>
                            <div style={{marginTop:"30px"}}>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-user"></i> &nbsp;&nbsp;NOMBRES
                                </div>
                                <input 
                                onChange={(text) => {
                                    this.setState({nombre:text.target.value,errorNombre:false})
                                }}
                                value={this.state.nombre}
                                className={this.state.errorNombre ? "error" : ""} 
                                id="firstName"
                                maxchar="1"
                                name="firstName" type="text" maxlength="30" />
                                {
                                    this.state.errorNombre == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                                

                            </div>

                            <div>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-user"></i> &nbsp;&nbsp;APELLIDOS
                                </div>
                                <input 
                                onChange={(text) => {
                                    this.setState({apellido:text.target.value,errorApellido:false})
                                }}
                                value={this.state.apellido}
                                className={this.state.errorApellido ? "error" : ""} 
                                id="firstName"
                                maxchar="1"
                                name="firstName" type="text" maxlength="30" />
                                {
                                    this.state.errorApellido == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                                

                            </div>

                            <div>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-phone"></i> &nbsp;&nbsp;TELÉFONO
                                </div>
                                <InputMask 
                                onChange={(text) => {
                                    const valor = text.target.value.replace(/\D/g, '')
                                    this.setState({telefono:valor,errorTelefono:false})
                                }}
                                value={this.state.telefono}
                                className={this.state.errorTelefono ? "error" : ""} 
                                id="phoneNumber" mask="9999-9999" name="phoneNumber" placeholder="0000-0000"  inputmode="tel" required="true" type="tel" aria-required="true" aria-invalid="false" />
                                {
                                    this.state.errorTelefono == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                            </div>


                            <div>
                                <div className="tituloCampo">
                                <i className="bi bi-envelope"></i>

                                    <i className="glyphicon glyphicon-envelope"></i> &nbsp;&nbsp;EMAIL
                                </div>
                                <input 
                                onChange={(text) => {
                                    this.setState({email:text.target.value,errorEmail:false,errorEmailText:""})
                                }}
                                value={this.state.email}
                                className={this.state.errorEmail ? "error" : ""} 
                                type="email" id="emailAddress" name="emailAddress" required="" aria-required="true"  aria-invalid="true" />
                                {
                                    this.state.errorEmail == true ?
                                        <label className="error">{this.state.errorEmailText}.</label>
                                    :null
                                }
                            </div>

                            <div>

                                                
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-gift"></i> &nbsp;&nbsp;CUMPLEAÑOS
                                </div>
                                <div>
                                    <select 
                                    onChange={(text) => {
                                        this.setState({dia:text.target.value,errorFecha:false,errorFechaTexto:""})
                                    }}
                                    value={this.state.dia}
                                    className={this.state.errorFecha ? "error inputSelectBirthDay birthDay" : "inputSelectBirthDay birthDay"} 
                                    id="birthDay" name="birthDay">
                                        <option value="">Día</option>
                                        <option value="01">1</option>
                                        <option value="02">2</option>
                                        <option value="03">3</option>
                                        <option value="04">4</option>
                                        <option value="05">5</option>
                                        <option value="06">6</option>
                                        <option value="07">7</option>
                                        <option value="08">8</option>
                                        <option value="09">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                </select>

                                <select 
                                    onChange={(text) => {
                                        this.setState({mes:text.target.value,errorFecha:false,errorFechaTexto:""})
                                    }}
                                    value={this.state.mes}
                                    className={this.state.errorFecha ? "error inputSelectBirthDay birthMonth" : "inputSelectBirthDay birthMonth"} 
                                    id="birthMonth" name="birthMonth" aria-invalid="false">
                                    <option value="">Mes</option>
                                        <option value="01">Enero</option>
                                        <option value="02">Febrero</option>
                                        <option value="03">Marzo</option>
                                        <option value="04">Abril</option>
                                        <option value="05">Mayo</option>
                                        <option value="06">Junio</option>
                                        <option value="07">Julio</option>
                                        <option value="08">Agosto</option>
                                        <option value="09">Septiembre</option>
                                        <option value="10">Octubre</option>
                                        <option value="11">Noviembre</option>
                                        <option value="12">Diciembre</option>
                                </select>

                                    <select 
                                        onChange={(text) => {
                                            this.setState({year:text.target.value,errorFecha:false,errorFechaTexto:""})
                                        }}
                                        value={this.state.year}
                                        className={this.state.errorFecha ? "error inputSelectBirthDay birthYear" : "inputSelectBirthDay birthYear"} 
                                        id="birthYear" name="birthYear" aria-invalid="false">
                                        <option value="">Año</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option><option value="2002">2002</option><option value="2001">2001</option><option value="2000">2000</option><option value="1999">1999</option><option value="1998">1998</option><option value="1997">1997</option><option value="1996">1996</option><option value="1995">1995</option><option value="1994">1994</option><option value="1993">1993</option><option value="1992">1992</option><option value="1991">1991</option><option value="1990">1990</option><option value="1989">1989</option><option value="1988">1988</option><option value="1987">1987</option><option value="1986">1986</option><option value="1985">1985</option><option value="1984">1984</option><option value="1983">1983</option><option value="1982">1982</option><option value="1981">1981</option><option value="1980">1980</option><option value="1979">1979</option><option value="1978">1978</option><option value="1977">1977</option><option value="1976">1976</option><option value="1975">1975</option><option value="1974">1974</option><option value="1973">1973</option><option value="1972">1972</option><option value="1971">1971</option><option value="1970">1970</option><option value="1969">1969</option><option value="1968">1968</option><option value="1967">1967</option><option value="1966">1966</option><option value="1965">1965</option><option value="1964">1964</option><option value="1963">1963</option><option value="1962">1962</option><option value="1961">1961</option><option value="1960">1960</option><option value="1959">1959</option><option value="1958">1958</option><option value="1957">1957</option><option value="1956">1956</option><option value="1955">1955</option><option value="1954">1954</option><option value="1953">1953</option><option value="1952">1952</option><option value="1951">1951</option><option value="1950">1950</option>
                                    </select>
                                </div>

                                {
                                    this.state.errorFecha == true ?
                                        <label className="error">{this.state.errorFechaTexto}.</label>
                                    :null
                                }

                            </div>

                            <div>
                                <div>

                                
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-file"></i> &nbsp;&nbsp;TIPO DOCUMENTO
                                </div>
                                <div>
                                    <select 
                                    onChange={(text) => {
                                        this.setState({tipoDocumento:text.target.value,errorTipoDocumento:false})
                                    }}
                                    style={{width:"100%"}}
                                    value={this.state.tipoDocumento}
                                    className={this.state.errorTipoDocumento ? "error inputSelectBirthDay birthDay" : "inputSelectBirthDay birthDay"} 
                                    id="birthDay" name="birthDay">
                                        <option value="">Seleccionar</option>
                                        <option value="7">Documento Único Identidad</option>
                                        <option value="8">Pasaporte</option>
                                        <option value="12">Carné de Residencia</option>
                                    </select>
                                </div>

                                {
                                    this.state.errorTipoDocumento == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }

                                </div>
                            </div>


                            <div>
                                <div>
                                    <div className="tituloCampo">
                                        <i className="glyphicon glyphicon-file"></i> &nbsp;&nbsp;DOCUMENTO
                                    </div>
                                    <input 
                                    onChange={(text) => {
                                        const valor = text.target.value.replaceAll("_", "");
                                        console.log(valor)
                                        this.setState({duiRegistro:valor,errorDuiRegistro:false})
                                    }}
                                    value={this.state.duiRegistro}
                                    className={this.state.errorDuiRegistro ? "error" : ""} 
                                    id="duiNumber" name="phoneNumber"  type="text" />
                                    {
                                        this.state.errorDuiRegistro == true ?
                                            <label className="error">campo obligatorio.</label>
                                        :null
                                    }
                                </div>
                            </div>
                                          
                            <div>
                                <div>

                                
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-file"></i> &nbsp;&nbsp;GENERO
                                </div>
                                <div>
                                    <select 
                                    onChange={(text) => {
                                        this.setState({genero:text.target.value,errorGenero:false})
                                    }}
                                    style={{width:"100%"}}
                                    value={this.state.genero}
                                    className={this.state.errorGenero ? "error inputSelectBirthDay birthDay" : "inputSelectBirthDay birthDay"} 
                                    id="genero" name="genero">
                                        <option value="">Seleccionar</option>
                                        <option value="male">Hombre</option>
                                        <option value="female">Mujer</option>
                                    </select>
                                </div>

                                {
                                    this.state.errorGenero == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }

                                </div>
                            </div>
                                            



                            {
                                this.state.errorRegistro != "" ?
                                    <label className="error" style={{display:"block",textAlign:"center"}}>{this.state.errorRegistro}</label>
                                :null
                            }

                            <Button onClick={(e) => this.registrarLeal(e)} style={{width:"97%",marginLeft:"calc(var(--bs-gutter-x) * .5)"}} className="btn-agregar btnContinuarCrearCuenta">
                                {
                                    this.state.loadingRegistroLeal== true ?
                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                    : 
                                    "REGISTRARSE"
                                }

                                
                            </Button>

                        </Row>
                        <div className='contTusPuntos' style={{textAlign:"center",fontSize:"13px"}}>Si tienes consultas:<br/>escríbenos en el chat de la página</div>
                    </div>
                    
                </Modal>



                <Modal
                    closeTimeoutMS={500}
                    isOpen={this.state.setIsOpenmensaje}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <img onClick={this.closeOpenModalMensaje} src="/images/cross.png" className="closeModal" />
                    <div className='productoAgregado'>Registro agregado</div>
                    <div className='nombreProductoAgregado'>Ahora ya puedes acumular y consultar tus puntos</div>
                    <Button onClick={(e) => this.closeOpenModalMensaje(e) }  className="btn-agregar btnContinuarCrearCuenta">ACEPTAR</Button>

                </Modal>

                
            </Col>

        </Row>
      );
    }
}

export default Club;