import React, { useState, useEffect } from 'react';
import imagesLoaded from 'imagesloaded'; 
import $ from 'jquery'; 


class Animaciones {
    static instance = new Animaciones()

    agregarTopping = async (itemChildren,categoryId,data,thisFrozen,price,cantidad,toppingsFree,toppingsAll,custom_type,idProduct,div,listToppingsFree) => {
        var imageDos = false
        var zIndex = 25

        //console.log(itemChildren)
        //console.log(categoryId)
        //console.log(idProduct)
        //return

        //validar para no agregar 2 gratis
        if(itemChildren.price == 0){
            //buscar canela
            var toppingsValidos = toppingsAll.filter((item) => item.price == 0 && item.id == 34)

            if(toppingsValidos.length > 0){
                return {error:true,mensaje:""}
            }
        }

        if(idProduct == 7 && categoryId == 3 && itemChildren.price != 0){
            //sanum

            //validar cantidad toppings
            var toppingsValidos = toppingsAll.filter((item) => item.id != "")
            var toppingValidosAll = toppingsValidos.filter((item) => item.price != 0)
            /*if(toppingValidosAll.length == 7){
                return {error:true,mensaje:"Has alcanzado el máximo de toppings disponibles",position:-1}
            }*/

            //comprobar cantidad de salsas
            var toppingSalsas = toppingsAll.filter((item) => item.categoryId == 9 && item.price != 0)
            var toppingCrunches = toppingsAll.filter((item) => item.categoryId == 10 && item.price != 0)
            var toppingFrutas = toppingsAll.filter((item) => item.categoryId == 11 && item.price != 0)
            
            
            if(itemChildren.categoryId == 9){
                //entro a salsa
                console.log("entrooo",toppingValidosAll.length)
                if(toppingSalsas.length == 2){
                    if(toppingCrunches.length != 2 && toppingValidosAll.length < 7){
                        return {error:true,mensaje:"Es hora de escoger tus crunches",position:1}
                    }else if(toppingFrutas.length != 3 && toppingValidosAll.length < 7){
                        return {error:true,mensaje:"Es hora de escoger tus frutas",position:2}
                    }
                }
            }else if(itemChildren.categoryId == 10){
                //entro a crunches
                if(toppingCrunches.length == 2){
                    if(toppingSalsas.length != 2 && toppingValidosAll.length < 7){
                        return {error:true,mensaje:"Es hora de escoger tus salsas",position:0}
                    }else if(toppingFrutas.length != 3 && toppingValidosAll.length < 7){
                        return {error:true,mensaje:"Es hora de escoger tus frutas",position:2}
                    }
                }
            }else if(itemChildren.categoryId == 11){
                //entro a frutas
                if(toppingFrutas.length == 3){
                    if(toppingSalsas.length != 2 && toppingValidosAll.length < 7){
                        return {error:true,mensaje:"Es hora de escoger tus salsas",position:0}
                    }else if(toppingCrunches.length != 2 && toppingValidosAll.length < 7){
                        return {error:true,mensaje:"Es hora de escoger tus crunches",position:1}
                    }
                }
            }
            

        }
       
        var index = toppingsAll.findIndex((item) => item.id == "")
        console.log("aqui",itemChildren.categoryId,categoryId)
       
        if(itemChildren.categoryId == 9 && categoryId == 1){
            //salsas y tarrina

            itemChildren.position = "base"
            index != -1 ? toppingsAll[index] = itemChildren : toppingsAll.push(itemChildren)
            zIndex = 1

        }else if(itemChildren.categoryId == 9 && categoryId == 2){
            //salsas y llaovasito

            itemChildren.position = "base"
            index != -1 ? toppingsAll[index] = itemChildren : toppingsAll.push(itemChildren)
            zIndex = 1

        }else if((itemChildren.categoryId == 8 && categoryId == 4) || (categoryId == 35) ){
            //granizado

            var indexChildren = toppingsAll.findIndex((item) => item.position == "base")
           
            var itemChildrenNew = {}
            itemChildrenNew.id = itemChildren.id
            itemChildrenNew.name = itemChildren.name
            itemChildrenNew.description = itemChildren.description
            itemChildrenNew.categoryId = itemChildren.categoryId
            itemChildrenNew.code = itemChildren.code
            itemChildrenNew.icon_image = itemChildren.icon_image
            itemChildrenNew.image = itemChildren.image
            itemChildrenNew.image2 = itemChildren.image2
            itemChildrenNew.online_zindex = itemChildren.online_zindex
            itemChildrenNew.price = itemChildren.price

            var keysJson = Object.keys(data.availableToppingsByCategory)
            var toppingCatRemove = keysJson[0]
            console.log("toppingCatRemove",toppingCatRemove)
            $("."+toppingCatRemove).remove();

            itemChildrenNew.name = itemChildren.name + " ("+toppingCatRemove+" base)"


            if(indexChildren == -1){
                itemChildrenNew.position = "base"
                index != -1 ? toppingsAll[index] = itemChildrenNew : toppingsAll.push(itemChildrenNew)
            }else{
                itemChildrenNew.position = "base"
                //index != -1 ? toppingsAll[index] = itemChildren : toppingsAll.push(itemChildren)
                toppingsAll[indexChildren] = itemChildrenNew
            }

            zIndex = 1

            
        
            

           
        }else if(itemChildren.categoryId != 9 && categoryId == 2){
            //entrado a llaovasito con otra categoria que no sea salsa
            var indexChildren = toppingsAll.findIndex((item) => item.position == "base")

            if(indexChildren == -1){
                itemChildren.position = "base"
                index != -1 ? toppingsAll[index] = itemChildren : toppingsAll.push(itemChildren)
                imageDos = true
                zIndex = 1
            }else{
                itemChildren.position = "top"
                index != -1 ? toppingsAll[index] = itemChildren : toppingsAll.push(itemChildren)
            }
        }else{
            itemChildren.position = "top"

            if(idProduct == 7 && categoryId == 3 && itemChildren.categoryId == 9){
                //Sanum

                var itemChildrenNew = {}
                itemChildrenNew.id = itemChildren.id
                itemChildrenNew.name = itemChildren.name
                itemChildrenNew.description = itemChildren.description
                itemChildrenNew.categoryId = itemChildren.categoryId
                itemChildrenNew.code = itemChildren.code
                itemChildrenNew.icon_image = itemChildren.icon_image
                itemChildrenNew.image = itemChildren.image
                itemChildrenNew.image2 = itemChildren.image2
                itemChildrenNew.online_zindex = itemChildren.online_zindex
                itemChildrenNew.price = itemChildren.price
                itemChildrenNew.position = itemChildren.position

                //comprobar si existe salsa base
                var buscarSalsaBase = toppingsAll.findIndex((item) => item.tipoSalsa == "base")
                var buscarSalsaSuperior = toppingsAll.findIndex((item) => item.tipoSalsa == "superior")

                if(buscarSalsaBase == -1){
                    //no se encontro
                    itemChildrenNew.tipoSalsa = "base"
                    itemChildrenNew.name = itemChildren.name + " (Salsa abajo)"
                }else if(buscarSalsaSuperior == -1){
                    //no se encontro
                    itemChildrenNew.tipoSalsa = "superior"
                    itemChildrenNew.name = itemChildren.name + " (Salsa arriba)"
                } 

                index != -1 ? toppingsAll[index] = itemChildrenNew : toppingsAll.push(itemChildrenNew)

            }else{
                index != -1 ? toppingsAll[index] = itemChildren : toppingsAll.push(itemChildren)
            }

        }

        if(itemChildren.categoryId == 9 && categoryId == 4){
            //salsa de plus solo modificamos zIndex
            zIndex = 2
        }

        var toppingsAllClone = JSON.parse(JSON.stringify(toppingsAll));

        //buscamos si es topping gratis por la promocion
        var toppingsValidosCarrito= toppingsAllClone.filter((item) => item.price !== 0)
        console.log("listToppingsFree",listToppingsFree)
        console.log("listToppingsFree",itemChildren.id)
        if(typeof listToppingsFree !== 'undefined'){

            var itemTopingPromotion = listToppingsFree.find((item) => item == itemChildren.id)

            if(typeof itemTopingPromotion !== 'undefined'){
                if(toppingsValidosCarrito.length > toppingsFree){
                    //buscar topping que sean gratis menos canela
                    var indexToppingGratis = toppingsAllClone.findIndex((item) => item.id != 34 && item.price == 0)
                    //item.id == 34
                    if(indexToppingGratis == -1){
                        var indexToppingPagadoAGratis = toppingsAllClone.findIndex((item) => item.id == itemChildren.id && item.price != 0)
                        console.log("====",indexToppingPagadoAGratis)
                        toppingsAllClone[indexToppingPagadoAGratis].price = 0
                    }
    
                }
            }
        }
        

        
        
        //reordenar para dejar los gratis por ultimo

        var toppingsGratis = toppingsAllClone.filter((item) => item.price == 0)
        var toppingsNoGratis = toppingsAllClone.filter((item) => item.price !== 0)

        var customFinal = []

        customFinal.push.apply(customFinal, toppingsNoGratis);
        customFinal.push.apply(customFinal, toppingsGratis);

        console.log("customFinal",customFinal)

        toppingsAll = []
        toppingsAll = customFinal

        //validar precio`
        var total = price
        var precioExtras = 0

        //calcular total y precio de los extras
        var toppAll = toppingsAll.filter((item) => item.price != 0)
        if(toppAll.length > toppingsFree){
            for (var i = 0; i < (toppAll.length - toppingsFree); i++) {
                total = total + toppAll[i].price
                precioExtras  = precioExtras + toppAll[i].price
            } 
        }

        total = total * cantidad


      
        

        //this.setState({toppings:toppingsAll,total:total})

        var nameCategory = ""

        if(itemChildren.categoryId == 9){
            nameCategory = "Salsas"
        }else if(itemChildren.categoryId == 10){
            nameCategory = "Crunches"
        }else if(itemChildren.categoryId == 11){
            nameCategory = "Frutas"
        }else if(itemChildren.categoryId == 8){
            nameCategory = "Granizados"
        }
        
        if(custom_type != 5){
            Animaciones.instance.addTopping(itemChildren.id, nameCategory, false, 'whole', zIndex,imageDos,categoryId,data,thisFrozen,custom_type,div);
        }


        return {toppings:toppingsAll,total:total,toppingExtra:precioExtras}
    }


    addTopping = async (toppingID, toppingCat, noToppingFall, side, zIndex,imageDos,categoryId,data,thisFrozen,custom_type,div) => {

        if(toppingCat == ''){
            var keysJson = Object.keys(data.availableToppingsByCategory)
            /*for (var j=0; j < keysJson.length; j++) {
                var toppingCatFind = keysJson[j]
                console.log("toppingCatFind",toppingCatFind[toppingID])
            }*/
            toppingCat = keysJson[0]
        }

        console.log("aqui ====",data.availableToppingsByCategory,"---",toppingID,"---",toppingCat)

        var slugPrefix = 'topping',
            toppingSlug = slugPrefix+toppingID,
            categorySlug = 'categoria'+categoryId,
            toppingLongName = data.availableToppingsByCategory[toppingCat][toppingID].title,
            toppingData = data.availableToppingsByCategory[toppingCat][toppingID],
            fallingA = null,
            fallingB = null,
            fallingC = null,
            finalImage = null,
            noImage = false,
            toppingImageNode = toppingData.toppingImagesForBuilderAnimation;
        
        //console.log("categoria",toppingCat)

       
        
        if (toppingImageNode){


            if(toppingImageNode[thisFrozen.size].fall_image1 !== null){
                fallingA = toppingImageNode[thisFrozen.size].fall_image1;
            }else{
                fallingA = "https://llaollao-assets.sfo3.digitaloceanspaces.com/common-files/blank.png"
            }

            if(toppingImageNode[thisFrozen.size].fall_image2 !== null){
                fallingB = toppingImageNode[thisFrozen.size].fall_image2;
            }else{
                fallingB = "https://llaollao-assets.sfo3.digitaloceanspaces.com/common-files/blank.png"
            }


            if(toppingImageNode[thisFrozen.size].fall_image3 !== null){
                fallingC = toppingImageNode[thisFrozen.size].fall_image3;
            }else{
                fallingC = "https://llaollao-assets.sfo3.digitaloceanspaces.com/common-files/blank.png"
            }


            if(toppingImageNode[thisFrozen.size].final_image !== null){
                finalImage = toppingImageNode[thisFrozen.size].final_image;
            }else{
                finalImage = ""
            }

            if(imageDos){
                fallingA = "https://llaollao-assets.sfo3.digitaloceanspaces.com/common-files/blank.png"
                fallingB = "https://llaollao-assets.sfo3.digitaloceanspaces.com/common-files/blank.png"
                fallingC = "https://llaollao-assets.sfo3.digitaloceanspaces.com/common-files/blank.png"

                if(toppingImageNode[thisFrozen.size].bottom_image !== null){
                    finalImage = toppingImageNode[thisFrozen.size].bottom_image;
                }else{
                    finalImage = ""
                }
            }


        }
        if ( (finalImage === '' || finalImage === null) ) {
            noImage = true;
        }


        if(custom_type == 5){
            finalImage = ""
        }
        
        if ( !zIndex ) {
            zIndex = toppingData.makelineOrderForBuilderAnimation;
        }

        var toppingQty = 1; //parseInt($('[data-topping-listing-item="' + toppingID + '"]').length, 10);



        if (noImage === false) {

            // images are available!
            if (toppingQty === 1) {
                var frozen = div

                if(frozen === null){
                    frozen = '.frozen-toppings'
                }
                console.log("frozen",frozen)

                // Set up containing elements
                $(frozen)
                    .append('<div class="topping-drop ' + toppingSlug + ' ' + categorySlug + ' ' + toppingCat + '" data-builder-topping-drop="' + toppingID + '" style="z-index: 1000000"></div>');
                $('.topping-drop.' + toppingSlug)
                    .append('<div class="final-toppings ' + toppingSlug + '"></div>')
                    .append('<div class="falling-toppings ' + toppingSlug + '"></div>');

                // Add baked images
                $('.final-toppings.' + toppingSlug + '')
                    .append('<img class="topping topping-final ' + toppingSlug + ' transparent fadeIn" src="' + finalImage + '" data-builder-img alt="" class="showOnToppingPage">');

                // Add falling toppings


                if (fallingA && fallingB && fallingC) {

                    $('.falling-toppings.' + toppingSlug + '')
                        .append('<img class="drop-img transparent" src="' + fallingA + '" data-builder-img alt="" class="showOnToppingPage">')
                        .append('<img class="drop-img transparent" src="' + fallingB + '" data-builder-img alt="" class="showOnToppingPage">')
                        .append('<img class="drop-img transparent" src="' + fallingC + '" data-builder-img alt="" class="showOnToppingPage">');
                }
            }


            var fallingToppings = $('.falling-toppings.' + toppingSlug + ''),
                toppingDrop = $('.topping-drop.' + toppingSlug);

            var imgLoad = imagesLoaded($('.frozen-image'));

            imgLoad.on('progress', function () {
                //addSpinner();
            });
            imgLoad.on('always', function () {
                //removeSpinner();
                //checkMultiple(toppingID, toppingCat);
                fallingToppings.addClass('basic-topping').one('animationend webkitAnimationEnd oanimationend MSAnimationEnd', function () {
                    toppingDrop.css('z-index', zIndex);
                    //console.log('entroooooo')

                });
                //toppingDrop.css('z-index', zIndex);
                if ($('html').hasClass('cssanimations') === false) {
                   

                    $('.topping.' + toppingSlug).removeClass('transparent');
                }
            });
        }
        else {
            // if images are not available
           // addFallbackMessage(noToppingImgMessage);
           console.log('imagen no disponible')
        }

    }

    removerTopping = async (posicion,toppingsAll,toppingsFree,cantidad,price,idProduct,categoryId,listToppingsFree) => {
        console.log("toppingsAll",toppingsAll)
        var slugPrefix = '.topping'+toppingsAll[posicion].id
        var itemTopping = toppingsAll[posicion]
        //console.log(slugPrefix)

        $(slugPrefix).first().addClass('fadeOut').one('animationend webkitAnimationEnd oanimationend MSAnimationEnd', function (e) {
            $(e.target).first().remove();
           // $('.parent').eq(1).removeClass('selectedchildren');
        });


        if(toppingsAll.length == toppingsFree){
            toppingsAll[posicion] = {id: "",name: "",type: ""}
        }else{
            toppingsAll.splice(posicion, 1);
        }


        //buscamos si es topping gratis por la promocion
        var toppingsAllClone = JSON.parse(JSON.stringify(toppingsAll));
        var toppingsValidosCarrito = toppingsAllClone.filter((item) => item.price !== 0)
 
        if(typeof listToppingsFree !== 'undefined'){

            

            for (var i = 0; i < toppingsAllClone.length; i+=1) {
                var itemT = toppingsAllClone[i]
                var itemTopingPromotion = listToppingsFree.find((item) => item == itemT.id)
                if(typeof itemTopingPromotion !== 'undefined'){
                    if(toppingsValidosCarrito.length > toppingsFree){
                        //buscar topping que sean gratis menos canela
                        var indexToppingGratis = toppingsAllClone.findIndex((item) => item.id != 34 && item.price == 0)
                        //item.id == 34
                        if(indexToppingGratis == -1){
                            var indexToppingPagadoAGratis = toppingsAllClone.findIndex((item) => item.id == itemT.id && item.price != 0)
                            console.log("====",indexToppingPagadoAGratis)
                            toppingsAllClone[indexToppingPagadoAGratis].price = 0
                        }
        
                    }
                }
            }
        }


        //reordenar para dejar los gratis por ultimo

        var toppingsGratis = toppingsAllClone.filter((item) => item.price == 0)
        var toppingsNoGratis = toppingsAllClone.filter((item) => item.price !== 0)

        var customFinal = []

        customFinal.push.apply(customFinal, toppingsNoGratis);
        customFinal.push.apply(customFinal, toppingsGratis);

        console.log("customFinal",customFinal)

        toppingsAll = []
        toppingsAll = customFinal


         //validar precio
         var total = price
         var precioExtras = 0

         var toppAll = toppingsAll.filter((item) => item.price != 0)
         if(toppAll.length > toppingsFree){
             for (var i = 0; i < (toppAll.length - toppingsFree); i++) {
                 total = total + toppAll[i].price
                 precioExtras  = precioExtras + toppAll[i].price
             } 
         }
        
         total = total * cantidad


        if(idProduct == 7 && categoryId == 3 && itemTopping.categoryId == 9){
            //Sanum


            //comprobar si existe salsa base o superior
            var buscarSalsaBase = toppingsAll.findIndex((item) => item.tipoSalsa == "base")
            var buscarSalsaSuperior = toppingsAll.findIndex((item) => item.tipoSalsa == "superior")

            if(buscarSalsaBase == -1){
                //no se encontro
                var buscarSalsa = toppingsAll.findIndex((item) => item.categoryId == 9 && item.tipoSalsa != "superior")
                if(buscarSalsa != -1){
                    //encontre salsa disponible
                    toppingsAll[buscarSalsa].tipoSalsa = "base"
                    toppingsAll[buscarSalsa].name = toppingsAll[buscarSalsa].name + " (Salsa abajo)"
                }
            }else if(buscarSalsaSuperior == -1){
                //no se encontro

                var buscarSalsa = toppingsAll.findIndex((item) => item.categoryId == 9 && item.tipoSalsa != "base")
                if(buscarSalsa != -1){
                    //encontre salsa disponible
                    toppingsAll[buscarSalsa].tipoSalsa = "superior"
                    toppingsAll[buscarSalsa].name = toppingsAll[buscarSalsa].name + " (Salsa arriba)"
                }
            } 
        }

         return {toppings:toppingsAll,total:total,toppingExtra:precioExtras}
    }

    cantidad = async (accion,cantidad,toppingsAll,total,toppingsFree) => {

        if(accion){
            cantidad = cantidad + 1
        }else{
            if(cantidad != 1){
                cantidad = cantidad - 1
            }
        }

        //validar precio
        if(toppingsAll.length > toppingsFree){
            for (var i = 0; i < (toppingsAll.length - toppingsFree); i++) {
                total = total + toppingsAll[i].price
            } 
        }

        total = total * cantidad

        return {toppings:toppingsAll,total:total,cantidad:cantidad}
       
    }

}

export default Animaciones;