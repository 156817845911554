import React,{ useState,useEffect }  from 'react';
import { Row,Col,Button} from "react-bootstrap";
import Modal from 'react-modal';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { NavLink } from "react-router-dom";
import $ from 'jquery';
import Constants from '../../libs/constants'

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 0,
      width: "80%",
      bottom: 'auto',
      transform: 'translate(-50%,-50%)',
      //transform: 'translate(0%, -50%)',
    },
  };

class DetalleCategoriaMovil extends React.Component {

    state = {
        dataCategoria:{},
        loadData:false,
        itemProducto:{price:0}
    };


    closeModal() {
        this.setState({setIsOpen:!this.state.setIsOpen})
    }

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    } 

    async componentDidMount() {
        this.setState({ dataCategoria: this.props.data}, () => {
            this.setState({loadData:true})
        });
    }

    verDetalle(e,item,url) {
        item.url = url
        this.setState({itemProducto:item,setIsOpen:!this.state.setIsOpen})
    }

    render() {
      return (
        <Row className="contHomeMovil noPadding movil">
            {
                this.state.loadData == false ?
                    null
                :

                <div className="contNuestrasTiendas noPadding">
                    <div className="nuestrasTiendas">{this.state.dataCategoria.name}</div>
                    <div className="clear"></div>
                    <Row className="contListTiendas noPadding">
                        <span className="descripcionCat" dangerouslySetInnerHTML={{__html: this.state.dataCategoria.description}}></span>


                        <Row  className="paddingCategoria">
                        
                            {this.state.dataCategoria.products.map(item => (
                                
                                    <div className="itemCategoria">
                                        <Row className="noPadding">
                                        <Col xs={7} className="noPadding">
                                            <div className="nameItemProducto textLeft"><span className="titleCant1">{item.name_online.split(' ')[0]}</span> <span className="tamanioCant1">{item.name_online.split(' ')[1]}</span></div>
                                            <div className="detalleCant1 textLeft"  dangerouslySetInnerHTML={{__html: item.home_desc}}></div>
                                                {
                                                    Constants.instance.onlyCatalog == false ?
                                                        <div className="precioCant1 textLeft">{item.currency}{item.price.toFixed(2)}</div>
                                                    : null
                                                }
                                                    <Row className="contAdd noPadding">
                                                <Col className="noPadding" md={{ span: 6, offset: 3 }}>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <a className="linkDetalle" onClick={(e) => this.verDetalle(e,item,"/categoria/"+this.state.dataCategoria.id+"/producto/"+item.id)}>VER<br/>DETALLE</a>
                                                        </Col>
                                                        <Col xs={6}>
                                                            {
                                                                Constants.instance.onlyCatalog() == false ?
                                                                    Constants.instance.idDireccion() == 0 ?
                                                                    <Button  onClick={(e) => {
                                                                            $(".linkDirecciones").click()
                                                                        }
                                                                        }  className="btn-agregar btn btn-primary">AGREGAR</Button>
                                                                    
                                                                    :
                                                                        <NavLink style={{paddingTop:"10px"}} className="btn-agregar btn btn-primary" to={"/categoria/"+this.state.dataCategoria.id+"/producto/"+item.id}>AGREGAR</NavLink>
                                                                : <NavLink style={{paddingTop:"10px"}} className="btn-agregar btn btn-primary" to={"/categoria/"+this.state.dataCategoria.id+"/producto/"+item.id}>ENTRAR</NavLink>
                                                            }

                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={5}>
                                            <img src={item.small_image} className="imgCategoriaMovil" />
                                        </Col>
                                    
                                        

                                        
                                        </Row>
                                    </div>
                                
                            ))}
                        </Row>
                    </Row>
                </div>
                
            }

            <Modal
                    isOpen={this.state.setIsOpen}
                    style={customStyles}
                    contentLabel=""
                >
                    {
                        this.state.itemProducto.price != 0 ?
                            <div>
                                <img onClick={(e) => this.setState({setIsOpen:!this.state.setIsOpen})} src="/images/cross.png" className="closeModal" />
                                <h2 className="tituloTienda">{this.state.itemProducto.name_online}</h2>
                                <div className="detalleProductoModal" style={{paddingLeft:"0px"}}>{this.state.itemProducto.desc}</div>

                                <Row style={{marginTop:"10px"}}>
                                <Col sm={6}>
                                    <img src={this.state.itemProducto.image_home} className="imgCant1" />
                                </Col>
                                <Col sm={6}>
                                    <div className="descripcionProductoModal">{this.state.itemProducto.prod_desc}</div>
                                    <div className="contValorNutricional">
                                        <div className="titleValoresNutricionales">VALORES NUTRICIONALES POR CADA 100 GRAMOS DE YOGURT HELADO:</div>
                                        <div style={{marginTop:"20px"}}>
                                            <Row>


                                                {typeof this.state.itemProducto.custom_data.nutricional_info.cal !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Calorías:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.cal}</span></Col> : null}
                                                {typeof this.state.itemProducto.custom_data.nutricional_info.pro !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Proteínas:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.pro}</span></Col> : null}
                                                {typeof this.state.itemProducto.custom_data.nutricional_info.fat !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Grasas:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.fat}</span></Col> : null}
                                                {typeof this.state.itemProducto.custom_data.nutricional_info.clc !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Calcio:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.clc}</span></Col> : null}
                                                {typeof this.state.itemProducto.custom_data.nutricional_info.sal !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Sal:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.sal}</span></Col> : null}
                                                {typeof this.state.itemProducto.custom_data.nutricional_info.ftrans !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Grasas trans:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.ftrans}</span></Col> : null}
                                                {typeof this.state.itemProducto.custom_data.nutricional_info.azu !== 'undefined' ? <Col xs={6}><span style={{display:"inline-block",width:"65%",paddingRight:"0.3em"}}>Azúcar:</span><span style={{display:"inline-block",textAlign:"right",width:"35%"}}>{this.state.itemProducto.custom_data.nutricional_info.azu}</span></Col> : null}


    
                                            </Row>
                                        </div>
                                        
                                    </div>
                                        <div>
                                            {
                                                this.state.itemProducto.custom_data.allergens.length == 10000 ?
                                                    <div>
                                                        <div className="titleAlergenos">ALÉRGENOS {this.state.itemProducto.custom_data.allergens.EGG}</div>
                                                        <div>
                                                        <Row>
                                                            {typeof this.state.itemProducto.custom_data.allergens.hasOwnProperty("EGG") !== 'undefined' ? 
                                                                <Col xs={4} className="titleAlergenosItems">
                                                                    <img src="/images/huevo.png" className="iconAlergenos" />
                                                                    <div>Huevo</div>
                                                                </Col>
                                                            : null}

                                                            {typeof this.state.itemProducto.custom_data.allergens.hasOwnProperty("NUTS") !== 'undefined' ? 
                                                                <Col xs={4} className="titleAlergenosItems">
                                                                    <img src="/images/frutossecos.png" className="iconAlergenos" />
                                                                    <div>NUECES</div>
                                                                </Col>
                                                            : null}

                                                            {typeof this.state.itemProducto.custom_data.allergens.hasOwnProperty("MILK") !== 'undefined' ? 
                                                                <Col xs={4} className="titleAlergenosItems">
                                                                    <img src="/images/leche.png" className="iconAlergenos" />
                                                                    <div>Leche</div>
                                                                </Col>
                                                            : null}
                                                        </Row>
                                                        </div>
                                                    </div>
                                                : null
                                            }
                                        

                                            <div className="contAgregarModal">
                                                {
                                                    Constants.instance.onlyCatalog() == false ?

                                                        <button onClick={(e) => {
                                                            if(Constants.instance.idDireccion() == 0){
                                                                this.setState({setIsOpen:false})
                                                                $(".linkDirecciones").click()
                                                            }else{
                                                                window.location.href=this.state.itemProducto.url}
                                                            }
        
                                                            } type="button" className="btn-agregar btn btn-primary">AGREGAR</button>
                                                    : null
                                                }
                                               

                                               {
                                                    Constants.instance.onlyCatalog == false ?
                                                        <div className="precioCat2">{this.state.itemProducto.currency}{this.state.itemProducto.price.toFixed(2)}</div>
                                                    : null
                                                }
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        :
                        null
                    }
                    
            </Modal>

        </Row>
      );
    }
}

export default DetalleCategoriaMovil;