import { Navigation } from "react-minimal-side-navigation";
import { useHistory, useLocation } from "react-router-dom";
import React, { useState } from "react";

import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";


export const NavSidebar = ({cerrarMenu,blog_url}) => {
  const history = useHistory();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <React.Fragment>
        <Navigation
            // you can use your own router's api to get pathname
            activeItemId="/management/members"
            onSelect={({itemId}) => {
                console.log("aqui",itemId)
                //this.handleClickMenu()

                //const history = useHistory();
                if(itemId == "politica"){
                    window.location.replace('/politicasprivacidad');
                }else if(itemId == "condiciones"){
                    window.location.replace('/terminoscondiciones');
                }else if(itemId == "blog"){
                    window.open(blog_url, blog_url.startsWith('http') ? "_blank" : "_self");
                }else{
                    history.push(itemId);
                }
                
                cerrarMenu()
                //this.context.router.push('/acercade');
            }}
            items={[
            {
                title: 'Nosotros',
                itemId: '/nosotros',
                // you can use your own custom Icon component as well
                // icon is optional
                elemBefore: () => <img className="iconMenLateral" src="/images/icon_acercade.png"/>,
            },
            {
                title: 'Tiendas',
                itemId: '/tiendas',
                // you can use your own custom Icon component as well
                // icon is optional
                elemBefore: () => <img className="iconMenLateral" src="/images/icon_restaurante.png"/>,
            },
            {
                title: 'Vida sanum',
                itemId: 'blog',
                elemBefore: () => <img className="iconMenLateral" src="/images/blog_icon.png"/>,
            },
            {
                title: 'Contáctenos',
                itemId: '/contactenos',
                elemBefore: () => <img className="iconMenLateral" src="/images/icon_contactenos.png"/>,
            },
            {
                title: 'Políticas de Privacidad',
                itemId: 'politica',
                elemBefore: () => <img className="iconMenLateral" src="/images/icon_politicas.png"/>,
            },
            {
                title: 'Condiciones de Uso',
                itemId: 'condiciones',
                elemBefore: () => <img className="iconMenLateral" src="/images/icon_condiciones.png"/>,
            }
            ]}
        />
    </React.Fragment>
  );
};
