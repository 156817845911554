import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import Modal from 'react-modal';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import {NavItem} from "react-bootstrap";
import { NavLink } from "react-router-dom";

const AnyReactComponent = ({ text }) => 
    <div className="float-end linkLocationTienda">
        <img onClick={(e) => {
            try {
                window.open("https://www.google.com/maps?ll="+text.lat+","+text.lng+"&z=17", '_blank').focus();
            } catch (error) {
                console.log(error);
            }
            
        }}  
        src="/images/location_pin.png" className="imgLocation" />
    </div>;

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      left: '5%',
      right: '5%'
    },
  };

  const renderMarkers = (map, maps,center,item) => {
    /*return (
        <Marker
            onClick={(e) => this.verMapa(e,item)}
            key={item.id}
            position={{ lat: center.lat, lng: center.lng }}
            slug={item.name}
        />
    );*/

    let marker = new maps.Marker({
        position: { lat: center.lat, lng: center.lng },
        map,
        title: item.name
    });
    return marker;
   };

class TiendasMovil extends React.Component {

    state = {
        loadingTiendas: false,
        setIsOpen: false,
        dataTienda:[],
        itemTienda:{text_hours:[]},
        center: {
            lat: 13.679645000000011,
            lng: -89.2522751651123
        }
    };

    closeModal() {
        this.setState({setIsOpen:!this.state.setIsOpen})
    }

    openCoordenates(e,item) {
       console.log(item)
    }


    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);

    }

    verMapa(e,item) {
        console.log(item)
        var coords = item.coords.split(', ');
        var center = {
            lat: parseFloat(coords[1]),
            lng: parseFloat(coords[0])
        }
        console.log("center ",center)
        this.setState({itemTienda:item,center:center,setIsOpen:!this.state.setIsOpen})
    }
    
    async componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
    }

    async componentDidMount() {

        const res =  await Http.instance.get("rest/main/locations");
        this.setState({dataTienda:res,loadingTiendas:true})

        //console.log("resss",this.props.match.params)
        
    }

    render() {
      return (
        <Row className="contHomeMovil noPadding">
            <div className="contNuestrasTiendas noPadding">
                <div className="nuestrasTiendas">Nuestras tiendas</div>

                {
                    this.state.loadingTiendas == false ?
                        <div className="contSpinner"><Spinner /></div>
                    :
                    <Row className="contListTiendas noPadding">
                        {this.state.dataTienda.map(item => (
                            <Col xs={12} onClick={(e) => this.verMapa(e,item)}>
                                <div className="itemTiendaMovil">
                                    <Row>
                                        <Col xs={3}><img src="/images/tienda_llaollao.png" className="imgTienda" /></Col>
                                        <Col xs={9}>
                                            <h2 className="tituloTiendaMovil">{item.name}</h2>
                                            {
                                                item.open == 1 ?
                                                <div className="localAbierto">ABIERTO</div>
                                                :
                                                <div className="localCerrado">CERRADO</div>
                                            }

                                            <div className="descripcionTienda" style={{display:"none"}}>Consumo en el lugar / Para llevar</div>
                                            <div className="descripcionTienda" style={{fontSize:"13px",display:"none"}}>+503 2104-5444</div>
        
                                        </Col>

                                        <img src="/images/arrow.png" className="arrowNext" />
                                    </Row>
                                    <div className="clear"></div>
        
                                </div>
                            </Col>
                        ))}                
                    </Row>
                }

            </div>

            <div style={{display:"none"}}>
                <button onClick={this.closeModal}>Open Modal</button>
                <Modal
                    isOpen={this.state.setIsOpen}
                    //onAfterOpen={afterOpenModal}
                    //onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel=""
                >
                    <img onClick={this.closeModal} src="/images/cross.png" className="closeModal" />
                    <h2 className="tituloTienda" style={{marginTop:"0px"}}>{this.state.itemTienda.name}</h2>


                    <div className="bloqueDetalleTienda">
                        <img src="/images/direccion.png" className="imgTiendaIconDireccion float-start" />
                        <div className="descripcionTiendaChild">{this.state.itemTienda.direction}</div>
                    </div>

                    <div className="bloqueDetalleTienda">
                        <img src="/images/horario.png" className="imgTiendaIconHorario float-start" />
                        <div className="descripcionTiendaChild">
                            <span className="tituloHorario">HORARIOS DE ATENCIÓN</span>
                            <span className="descripcionTiendaChild removePaddingLeft" dangerouslySetInnerHTML={{__html: this.state.itemTienda.text_hours}}></span>
                        </div>
                    </div>

                    <div style={{ height: '75%', width: '100%', display:"block", marginTop:"20px"}}>
                        <GoogleMapReact
                        defaultCenter={{
                            lat: 59.95,
                            lng: 30.33
                        }}
                        center={this.state.center}
                        bootstrapURLKeys={{ key: "AIzaSyD1BDqiPv2uoe68yzbhubBm5hV5IHIYYKk" }}
                        defaultZoom={16}
                        //onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps,this.state.center,this.state.itemTienda)}
                        >

                            <AnyReactComponent
                                lat={this.state.center.lat}
                                lng={this.state.center.lng}
                                text={this.state.center}
                                />
                        </GoogleMapReact>
                    </div>
                </Modal>
            </div>
            
            <div className="mobile-stores-menu visible-mobile above noPadding">
                <ul className="menu-icons">
                    <li>
                        <NavLink className="navlink" to={"/"} activeClassName="off" title="Menú">
                            <i className="iconbot iconf-menu"></i>MENÚ
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="navlink" to={"/promociones"} activeClassName="off" title="Promociones">
                            <i className="iconbot iconf-promotions"></i>PROMOCIONES
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="navlink" to={"/tiendas"} activeClassName="active off" title="Restaurantes">
                        <i className="iconbot iconf-rest"></i>TIENDAS
                        </NavLink>
                    </li>
                </ul>
            </div>

        </Row>
      );
    }
}

export default TiendasMovil;