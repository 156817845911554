import React, { useState, useEffect } from 'react';
import imagesLoaded from 'imagesloaded'; 
import $ from 'jquery'; 


class Constants {
    static instance = new Constants()

    onlyCatalog = () => {
        return true
    }
    
    idDireccion = () => {
        /*var location = 0
        const direccion = localStorage.getItem("direccionSelect");
        const initialDireccion = JSON.parse(direccion);
        if(initialDireccion != null){
            location = initialDireccion.zone.locationId
        }
        return location*/
        return -1
    }
}

export default Constants;