import React,{ useState,useEffect }  from 'react';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { Route, Redirect } from 'react-router'
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InputMask from 'react-input-mask';
import validator from 'validator'
import { withRouter } from 'react-router-dom';
import $ from 'jquery'; 
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import Select from 'react-select'
import Moment from 'moment';
import 'moment/min/locales'
import { HeaderBotones } from '../../header/header_botones'

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 0,
      width: "500px",
      bottom: 'auto',
      transform: 'translate(-50%,-50%)',
      //transform: 'translate(0%, -50%)',
    },
  };

var premitirOrdenar = true


class DetalleOrden extends React.Component {
    state = {
        carrito: [],
        selectedIndex: 0,
        pagoTarjeta:true,
        pagoEfectivo:false,
        factura: false,
        visibleTarjeta: false,
        cambioMonto: "",
        total:0,
        subTotal:0,
        propina: 0,
        nitFactura: "",
        exacto: true,
        customer:{id:-1},
        addresses:[],
        direccion:"",
        loadDataDirecciones: true,
        direccionSeleccionada:{zone:{delivery_fee:0}},
        driverTip:[],
        nombreTarjeta: "",
        numeroTarjeta: "",
        fechaExpiracion: "",
        cvcTarjeta: "",
        nombreFactura: "",
        nitFactura: "",
        direccionFactura: "",
        giroFactura: "",
        cambio:false,
        terminos:false,
        loadSendData:false,
        descuento: 0,
        setIsOpenDireccion:false,
        departamentos:[],
        municipios:[],
        colonias:[],
        options:  [],
        direccionSelect: null,
        setIsOpenDelete:false,
        coloniaNombre:null,
        direccionNueva:"",
        loadingSaveDireccion:false,
        otroValor: false,
        propinaCustom: null,
        observaciones:"",
        beneficioAgregado:{idCode:0},
        activarCompra:false,
        mensajeCompraDesactivada:"",
        delivery_time:"",
        setIsOpenCard: false,
        mensaje: "",
        referencia:"",
        dataToppings:[]
    };

    closeModal() {
        this.setState({setIsOpen:!this.state.setIsOpen})
    }

    async openCloseModalDireccion() {

        this.setState({setIsOpenDireccion:!this.state.setIsOpenDireccion,departamento:"",direccionNueva:"",municipio:"",colonia:"",tipoDireccion:""})
    }

    handleSelectTabs = index => {
        this.setState({ selectedIndex: index });
    };

    GetCardType = (number) => {
        // visa
        var re = new RegExp("^4");
        if (number.match(re) != null)
            return "Visa";

        // Mastercard 
        // Updated for Mastercard 2017 BINs expansion
        if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
            return "Mastercard";

        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null)
            return "AMEX";

        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (number.match(re) != null)
            return "Discover";

        // Diners
        re = new RegExp("^36");
        if (number.match(re) != null)
            return "Diners";

        // Diners - Carte Blanche
        re = new RegExp("^30[0-5]");
        if (number.match(re) != null)
            return "Diners - Carte Blanche";

        // JCB
        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (number.match(re) != null)
            return "JCB";

        // Visa Electron
        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (number.match(re) != null)
            return "Visa Electron";

        return "OTHER";
    }

    async toppingsSugeridos(){
        var idUser = 0
        const customer = localStorage.getItem("customer");
        const initialCustomer = JSON.parse(customer);
        if(initialCustomer != null){            
            idUser = initialCustomer.id
        }

        var dataUserSend = {}
        dataUserSend.customer = idUser
        const resToppings =  await Http.instance.post("rest/product/toppings",JSON.stringify(dataUserSend));
        if(typeof resToppings.errorHttp == 'undefined'){
            console.log("resToppings",resToppings)

            this.setState({dataToppings:resToppings})
            
        }

    }

    onlyNumberKey(evt) {
          
        // Only ASCII character in that range allowed
        var ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
            return false;
        return true;
    }

    removerProducto(e,item) {

        const key = `carrito-${item.keyProduct}`

        localStorage.removeItem(key)
        this.handleCarrito()
        this.props.actualizarCarrito()
    }

    enviarDatosOrden = async (e) => {

        if(this.state.direccion == ""){
            this.setState({errorDireccion:true,selectedIndex:0})
            return
        }

        

        if(this.state.selectedIndex == 0){
            this.setState({selectedIndex:1})
            return
        }else if(this.state.selectedIndex == 1){
            this.setState({selectedIndex:2})
            return
        }

        if(this.state.pagoTarjeta){

            if(this.state.nombreTarjeta == ""){
                this.setState({errorNombreTarjeta:true})
                return
            }

            if(this.state.numeroTarjeta.length < 16){
                this.setState({errorNumeroTarjeta:true})
                return
            }


            var limpiarFechaExpiracion = this.state.fechaExpiracion.replaceAll("_", '')

            if(limpiarFechaExpiracion.length < 5){
                this.setState({errorFechaExpiracion:true})
                return
            }

            if(this.state.yyTarjeta == ""){
                this.setState({errorYYTarjeta:true})
                return
            }

            if(this.state.cvcTarjeta.length < 3){
                this.setState({errorCVCTarjeta:true})
                return
            }
        }else{
            //efectivo
            if(this.state.cambioMonto){
                if(isNaN(parseFloat(this.state.cambioMonto).toFixed(2))){
                    this.setState({errorCambio:true})
                    return
                }

                
                if(this.state.cambioMonto < this.state.total){
                    this.setState({errorCambio:true})
                    return
                }
                
            }
        }


        if(this.state.factura){
            if(this.state.nombreFactura == ""){
                this.setState({errorNombreFactura:true})
                return
            }

            var limpiarNitFactura = this.state.nitFactura.replaceAll("_", '')


            if(limpiarNitFactura.length < 17){
                this.setState({errorNit:true})
                return
            }

            if(this.state.direccionFactura == ""){
                this.setState({errorDireccion:true})
                return
            }

            if(this.state.giroFactura == ""){
                this.setState({errorGiro:true})
                return
            }
        }

        if(this.state.terminos == false){
            this.setState({errorTerminos:true})
            return
        }

        if(this.props.amountMin > (this.state.beneficioAgregado.idCode != 0 ? (this.state.subTotal - (this.state.subTotal * (parseFloat(this.state.beneficioAgregado.value) / 100))) : this.state.subTotal)){
            return
        }

        if(premitirOrdenar == false){
            return
        }else{
            premitirOrdenar = false
        }
        
        var dataSendCarrito = {}
        dataSendCarrito.annotations = this.state.observaciones
        dataSendCarrito.scheduled_time = ""
        dataSendCarrito.scheduled_date = ""

        if(this.state.factura){
            dataSendCarrito.invoice_name = this.state.nombreFactura
            dataSendCarrito.invoice_nit = this.state.nitFactura
            dataSendCarrito.invoice_dir = this.state.direccionFactura
            dataSendCarrito.invoice_giro = this.state.giroFactura
        }else{
            dataSendCarrito.invoice_name = ""
            dataSendCarrito.invoice_nit = ""
            dataSendCarrito.invoice_dir = ""
            dataSendCarrito.invoice_giro = ""
        }

        if(this.state.pagoTarjeta){
            var numeroSinFormato =  this.state.numeroTarjeta.replace(" ", "");
            numeroSinFormato = numeroSinFormato.replace(" ", "");
            numeroSinFormato = numeroSinFormato.replace(" ", "");
            numeroSinFormato = numeroSinFormato.replace(" ", "");
            numeroSinFormato = numeroSinFormato.replace(" ", "");

            const fechaArray = this.state.fechaExpiracion.split('/');


            dataSendCarrito.card_name = this.state.nombreTarjeta
            dataSendCarrito.card_number = numeroSinFormato
            dataSendCarrito.card_cvv = this.state.cvcTarjeta
            dataSendCarrito.card_expmonth = fechaArray[0]
            dataSendCarrito.card_expyear = "20"+fechaArray[1]
            dataSendCarrito.card_type = this.GetCardType(dataSendCarrito.card_number).toUpperCase()

            dataSendCarrito.payment = "CREDIT_CARD"
            dataSendCarrito.pay_change = 0

        }else{

            dataSendCarrito.card_name = ""
            dataSendCarrito.card_number = ""
            dataSendCarrito.card_cvv = ""
            dataSendCarrito.card_expmonth = ""
            dataSendCarrito.card_expyear = ""
            dataSendCarrito.card_type = ""
            dataSendCarrito.payment = "CASH"
            if(this.state.cambioMonto == ""){
                dataSendCarrito.pay_change = 0
            }else{
                dataSendCarrito.pay_change = parseFloat(this.state.cambioMonto)
            }

        }


        if(this.props.show_tip == "1"){
            if(this.state.otroValor == false){
                dataSendCarrito.driver_tip = parseFloat(this.state.propina)
            }else{
                dataSendCarrito.driver_tip = parseFloat(this.state.propinaCustom)
            }
        }else{
            dataSendCarrito.driver_tip = parseFloat(0)
        }


        dataSendCarrito.who_name = ""
        dataSendCarrito.who_lastname = ""
        dataSendCarrito.location = this.state.direccionSeleccionada.zone.locationId
        dataSendCarrito.address = this.state.direccionSeleccionada.id
        dataSendCarrito.type_order = "DELIVERY"
        dataSendCarrito.customer = this.state.customer.id
        dataSendCarrito.user = this.state.customer.userId
        dataSendCarrito.total_cart = parseFloat(this.state.total)
        dataSendCarrito.discount = this.state.beneficioAgregado.idCode != 0 ? parseFloat(parseFloat(this.state.subTotal) * (parseFloat(this.state.beneficioAgregado.value) / 100)) : 0.00
        dataSendCarrito.cart = []

        for (var i=0; i < this.state.carrito.length; i++) {

            var item = this.state.carrito[i]
            var itemProducto = {}
            if(item.promo == true){
                itemProducto.promo_id = item.promo_id
                //primer producto
                itemProducto.prod_1 = {}
                itemProducto.prod_1.productId =  item.productoUno.productId
                itemProducto.prod_1.precio_unitario =  item.productoUno.precio_unitario + item.productoUno.precioExtra
                itemProducto.prod_1.custom =  item.productoUno.custom

                itemProducto.prod_2 = {}
                itemProducto.prod_2.productId =  item.productoDos.productId
                itemProducto.prod_2.precio_unitario =  item.productoDos.precio_unitario + item.productoDos.precioExtra
                itemProducto.prod_2.custom =  item.productoDos.custom
                
                itemProducto.quantity = item.quantity
                itemProducto.total = item.total
                itemProducto.precio_unitario = item.total / item.quantity
                itemProducto.isTopping = false
                itemProducto.isPromo = true

            }else{
                itemProducto.custom = item.custom
                itemProducto.isTopping = item.isTopping == true ? true : false
                itemProducto.precio_unitario = item.precio_unitario
                itemProducto.productId = item.productId
                itemProducto.quantity = item.quantity
                itemProducto.total = item.total
                itemProducto.isPromo = false
            }
            
            dataSendCarrito.cart.push(itemProducto)
        }

         //verificar tipo de descuento
        //obtener descuento
        if (this.state.beneficioAgregado.idCode != 0) {

            var itemCreate = {}
            itemCreate.isCode = 1
            itemCreate.idCode = this.state.beneficioAgregado.idCode
            itemCreate.nameCode = this.state.beneficioAgregado.nameCode
            itemCreate.productId = 0
            itemCreate.quantity = 1
            itemCreate.total = 0
            itemCreate.precio_unitario = 0
            dataSendCarrito.cart.push(itemCreate)
        }

        console.log("dataSendCarrito",dataSendCarrito)
        //return

        this.setState({loadSendData:true})
        
        const res = await Http.instance.post("rest/cart/place-order",JSON.stringify(dataSendCarrito));
        premitirOrdenar = true

        if(typeof res.errorHttp === 'undefined'){
            
            if(res.status == "error"){
                this.setState({mensajeError:res.message,loadSendData:false})
                setTimeout(() => {
                    this.setState({mensajeError:""})
                }, 7000);
            }else{
                //remover
                try {
                    //const allKeys = { ...localStorage };
                    var query = "carrito-"
                    
                    var i, results = [];
                    for (i in localStorage) {
                        if (localStorage.hasOwnProperty(i)) {
                            if (i.match(query) || (!query && typeof i === 'string')) {
                                localStorage.removeItem(i)
                            }
                        }
                    }

                    localStorage.removeItem("descuento")
                    
                } catch (error) {
                    console.log(error);
                }

                if(this.state.pagoTarjeta){
                    this.setState({loadSendData:false,setIsOpenCard:true,mensaje:res.transaction.mensaje,referencia:res.transaction.referencia})
                }else{
                    this.setState({loadSendData:false})
                    window.location.replace("/ordenfinalizada");
                }
            }
        }else{
            this.setState({mensajeError:"Ha ocurrido un error al procesar su orden",loadSendData:false})
            setTimeout(() => {
                this.setState({mensajeError:""})
            }, 7000);
        }


        /*this.setState({loadSendData:false})



        console.log(dataSendCarrito)*/
        console.log(res)
  
        
    }

    obtenerCuenta = async (e) => {
        var dataUser = {}
        dataUser.customer = this.state.customer.id

        const direccionSelect = localStorage.getItem("direccionSelect");
        const initialDireccionSelect = JSON.parse(direccionSelect);

        const res = await Http.instance.post("rest/account",JSON.stringify(dataUser));
        
        var direccion = res.addresses
        var direccionSeleccionada = {}

        if(initialDireccionSelect != null){
            var itemDireccion = direccion.find((item) => item.id == initialDireccionSelect.id)
            direccion[direccion.indexOf(itemDireccion)].selected = true
            direccionSeleccionada = direccion[direccion.indexOf(itemDireccion)]
        }else{
            //no hay direccion seleccionada
            direccion[0].selected = true
            direccionSeleccionada = direccion[0]
        }

        
        

        this.setState({direccion:direccionSeleccionada.address,addresses:direccion,direccionSeleccionada:direccionSeleccionada,loadDataDirecciones:false}, () => {
            this.handleCarrito()
        });

        this.verificarDireccion(direccionSeleccionada.id)
    }

    verificarDireccion = async (idDireccion) => {
        this.setState({loadDireccion:true})

        
        var dataDir = {}
        dataDir.customer = this.state.customer.id
        dataDir.address = idDireccion

        const resValidateDir =  await Http.instance.post("rest/main/validate-dir",JSON.stringify(dataDir));
        console.log("resValidateDir",resValidateDir)
        console.log("resValidateDir",dataDir)
        
        if(typeof resValidateDir.errorHttp == 'undefined'){
            this.handleCarrito()

            this.setState({loadDireccion:false})

            if(resValidateDir.status == 300){
                this.setState({activarCompra:false,mensajeCompraDesactivada:resValidateDir.message,delivery_time:""})
            }else{
                this.setState({activarCompra:true,mensajeCompraDesactivada:"",delivery_time:resValidateDir.delivery_time})
            }
        }
    }

    actualizarCantidad(e,item,numero){
        var nuevaCantidad = numero

        console.log(item)

        if(numero != 0 && numero < 26){
            if(item.promo == true){
                var totalProductoUno = (item.productoUno.precio_unitario + item.productoUno.precioExtra)
                var totalProductoDos = (item.productoDos.precio_unitario + item.productoDos.precioExtra)
                item.quantity = nuevaCantidad
                item.total = (totalProductoUno + totalProductoDos) * nuevaCantidad
    
                var carrito = JSON.stringify(item)
                const key = `carrito-${item.keyProduct}`
    
                localStorage.setItem(key,carrito)
            }else{
                var totalProducto = (item.precio_unitario + item.precioExtra) * nuevaCantidad
                item.quantity = nuevaCantidad
                item.total = totalProducto
    
                var carrito = JSON.stringify(item)
                const key = `carrito-${item.keyProduct}`
    
                localStorage.setItem(key,carrito)
            }

            this.handleCarrito()
            this.props.actualizarCarrito()
        }
    }

    guardarActualizarDireccion = async (e) => {
        
        
        var validado = true
        if(this.state.departamento == ""){
            this.setState({errorDepartamento:true})
            validado = false
        }

        if(this.state.municipio == ""){
            this.setState({errorMunicipio:true})
            validado = false
        }

        if(this.state.colonia == ""){
            this.setState({errorColonia:true})
            validado = false
        }

        if(this.state.tipoDireccion == ""){
            this.setState({errorTipoDireccion:true})
            validado = false
        }

        if(this.state.direccionNueva == ""){
            this.setState({errorDireccionNueva:true})
            validado = false
        }


        if(validado){

            var dataUserDireccion = {}
            dataUserDireccion.alias = "Dirección"
            dataUserDireccion.departamento = parseInt(this.state.departamento)
            dataUserDireccion.municipio = parseInt(this.state.municipio)
            dataUserDireccion.zona = parseInt(this.state.colonia)
            dataUserDireccion.tipo = parseInt(this.state.tipoDireccion)
            dataUserDireccion.direccion = this.state.direccionNueva
            dataUserDireccion.customer = this.state.customer.id
            dataUserDireccion.principal = false

            
            this.setState({loadingSaveDireccion:true})

            const res = await Http.instance.post("rest/account/new-address",JSON.stringify(dataUserDireccion));
            console.log(res)
            console.log(dataUserDireccion)

            if(typeof res.errorHttp === 'undefined'){
                if(res.status == 200){
                    this.setState({loadingSaveDireccion:false,setIsOpenDireccion:false,titulo:"dirección guardada exitosamente",mensaje:"",setIsOpenmensaje:true,errorSaveDireccion:""})
                    this.obtenerCuenta()
                }else{
                    this.setState({loadingSaveDireccion:false,errorSaveDireccion:res.message})
                }
            }else{
                this.setState({loadingSaveDireccion:false,errorSaveDireccion:"Ha ocurrido un error"})
            }
            

        }
      
        
    }

    async handleAgregarProductoTopingsD(e,item) {

        var dataProductoSave = {}
        dataProductoSave.productId = item.id
        dataProductoSave.isTopping = true
        dataProductoSave.quantity = 1
        dataProductoSave.total = item.price
        dataProductoSave.precio_unitario = item.price
        dataProductoSave.currency = "$"
        dataProductoSave.base_image = item.icon_image
        dataProductoSave.desc = item.description
        dataProductoSave.small_desc = item.description
        dataProductoSave.name = item.name
        dataProductoSave.orden = parseFloat(Moment().format("DDMMYYYYHHmmss"))
        var custom = []

        dataProductoSave.custom = custom
        dataProductoSave.keyProduct =  `${dataProductoSave.productId}${(Math.floor(Math.random() * 100) + 1)}`

        const key = `carrito-${dataProductoSave.keyProduct}`
        var carrito = JSON.stringify(dataProductoSave)

        localStorage.setItem(key,carrito)

        this.handleCarrito()
        this.props.actualizarCarrito()
       

    }

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    handleCarrito() {
        try {
            //const allKeys = { ...localStorage };
            var query = "carrito-"
            var totalCalcular = 0
            
            var i, results = [];
            for (i in localStorage) {
                if (localStorage.hasOwnProperty(i)) {
                    if (i.match(query) || (!query && typeof i === 'string')) {
                        var value = JSON.parse(localStorage.getItem(i));

                        if(value.promo == true){
                            var precioExtra = 0
                            var toppingsValidos = value.productoUno.custom.filter((item) => item.price !== 0)
                            if(value.productoUno.toppingsFree < toppingsValidos.length){
                                for (var j=value.productoUno.toppingsFree; j < toppingsValidos.length; j++) {
                                    precioExtra += toppingsValidos[j].price
                                } 
                            }

                            value.productoUno.precioExtra = precioExtra
                            value.productoUno.keyProduct = value.keyProduct

                            var precioExtraDos = 0
                            var toppingsValidosDos = value.productoDos.custom.filter((item) => item.price !== 0)
                            if(value.productoDos.toppingsFree < toppingsValidosDos.length){
                                for (var j=value.productoDos.toppingsFree; j < toppingsValidosDos.length; j++) {
                                    precioExtraDos += toppingsValidosDos[j].price
                                } 
                            }

                            value.productoDos.precioExtra = precioExtraDos
                            value.productoDos.keyProduct = value.keyProduct



                        }else{
                            var precioExtra = 0
                            var toppingsValidos = value.custom.filter((item) => item.price !== 0)
    
                            if(value.toppingsFree < toppingsValidos.length){
                                for (var j=value.toppingsFree; j < toppingsValidos.length; j++) {
                                    precioExtra += toppingsValidos[j].price
                                } 
                            }
                        }

                        value.precioExtra = precioExtra
                        if(typeof value.orden === 'undefined'){
                            value.orden = 0
                        }

                        results.push(value);
                        totalCalcular += value.total
                    }
                }
            }

            var descuento = 0.0
            const dataDescuento = localStorage.getItem("descuento");
            const initialDescuento = JSON.parse(dataDescuento);
            if(initialDescuento != null){
                this.setState({beneficioAgregado:initialDescuento})

                descuento = parseFloat(parseFloat(totalCalcular) * (parseFloat(initialDescuento.value) / 100))
            }else{
                this.setState({beneficioAgregado:{idCode:0}})

            }

            var totalNeto = totalCalcular + this.state.direccionSeleccionada.zone.delivery_fee + this.state.propina - descuento

            results.sort((a, b) => (a.orden) - (b.orden));


            this.setState({cantidadProductos:results.length,carrito:results,total:totalNeto,subTotal:totalCalcular,descuento:descuento})

            if(results.length == 0){
                window.location = "/";
            }
        } catch (error) {
            console.log(error);
        }
        
    }

    async componentDidMount() {
        //console.log("aquiiii",this.props.status)
        //sesion iniciada
        premitirOrdenar = true

        const customer = localStorage.getItem("customer");
        const initialCustomer = JSON.parse(customer);
        if(initialCustomer != null){
           
            
            
            this.setState({customer:initialCustomer}, () => {
                this.obtenerCuenta()
                
            });

            const resDelivery =  await Http.instance.get("rest/main/driver-tip");
            this.setState({driverTip:resDelivery})

            const res =  await Http.instance.get("rest/main/departments");
            this.setState({departamentos:res})

        }else{
            window.location = "/";
        }

        this.toppingsSugeridos()
        
       
    }

    dataProductoCarrito(item,showCantidad){
        
        return(
            <div className="contInfo">
                <div className="contInfo" style={{width:"100%"}}>
                    <div  className="nameProducto">{item.name} {item.currency}{item.precio_unitario.toFixed(2)} (c/u)</div>
                    {
                    item.custom.length != 0 ?
                        <div>
                            <div className="toppings"><span>{item.toppingsFree} toppings</span></div>
                            <ul>
                                {item.custom.map((itemToppping, index) => (

                                        index < item.toppingsFree ? <li>{itemToppping.name}</li>
                                        : null

                                    
                                ))}

                                
                            </ul>
                            {
                                item.toppingsFree < item.custom.length ?
                                    <div>
                                        <div className="nameProducto">topping extra(s) {item.currency}{item.custom[0].price.toFixed(2)} (c/u)</div>
                                        <ul>
                                            {item.custom.map((itemToppping, index) => (

                                                    index >= item.toppingsFree ? <li className={itemToppping.price == 0 ? "gratis" : ""}>{itemToppping.name} {itemToppping.price == 0 ? "(Gratis)": null}</li>
                                                    : null

                                                
                                            ))}
                                        </ul>

                                    </div>
                                : null
                            }

                        </div>
                    : <div><br /></div>
                    }


                    <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginBottom:"15px"}}>
                            <div className="txTotal">Subtotal</div>
                            <div className="subTotalCarrito">{item.currency}{parseFloat(item.precio_unitario + item.precioExtra).toFixed(2)}</div>
                    </div>
                    {
                    showCantidad ?
                        <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"5px"}}>
                            <div className="txTotal">total producto</div>
                            <div className="subTotalCarrito">{this.props.currency}{item.total.toFixed(2)}</div>
                        </div>
                    : null
                    }


                </div>
                <div className="contCantidad" style={{position:"absolute",top:"12px",right:"0px"}}>
                    {
                    showCantidad ?
                        <div style={{display:"flex",marginRight:"50px"}}>
                            <div className="contMenos" onClick={(e) => this.actualizarCantidad(e,item,item.quantity-1)}>-</div>
                            <div className="txCantidad">{item.quantity}</div>
                            <div className="contMenos" onClick={(e) => this.actualizarCantidad(e,item,item.quantity+1)}>+</div>
                        </div>
                        : null
                    }

                    

                    <div className='contEditAddress' style={{top:"3px"}} onClick={(e) => this.removerProducto(e,item)}>
                        <i className="glyphicon glyphicon-trash"></i>
                    </div>
                </div>
            </div>
        )
    }

    async componentDidUpdate(prevProps) {
        if(this.props.status == "offline"){
           window.location = "/";
        }
    }

    render() {
      return (
        <Row>
            <Col xs={12}>
                <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                <div className="clear"></div>
                <h1 className="tituloNuestrasTiendas">domicilio de envío</h1>
                
                <div className="clear"></div>

                
                <Row className="itemCant1 contContactenos contCuenta">
                    <div>
                        <Tabs  selectedIndex={this.state.selectedIndex} onSelect={this.handleSelectTabs}>
                            <TabList>
                                <Tab tabFor="0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Domicilio&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Tab>
                                <Tab tabFor="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Detalle de orden&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Tab>
                                <Tab tabFor="2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pago&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Tab>
                            </TabList>

                            <TabPanel tabId="0">
                                <Row className="rowRegistro">
                                    
                                    <Col xs={12}>
                                        <div className="contFormularioContactenos" style={{marginLeft:"50px",marginRight:"50px",padding:"25px"}}>
                                            
                                            {
                                                this.state.loadDataDirecciones == true ?
                                                    <div className="contSpinner"><Spinner /></div>
                                                :
                                                <div>
                                                    <div className='tiempoEntrega'>Tiempo de entrega aproximado: {this.state.delivery_time != "" ? <span>{this.state.delivery_time} minutos</span> : null}</div>
                                                    <div className="tituloContactenos">Selecciona tu dirección:</div>
                                                    <div className="contListDirecciones">

                                                    {this.state.addresses.map(itemChildren => (

                                                        
                                                        <div className='itemDireccion'>
                                                            <input 
                                                            checked={this.state.direccionSeleccionada.id == itemChildren.id ? true:false}
                                                            name="radioDireccion"
                                                            onChange={(text) => {
                                                                var direccion =  itemChildren.address
                                                                this.setState({direccionSeleccionada:itemChildren,direccion:direccion})
                                                                this.verificarDireccion(itemChildren.id)
                                                            }}
                                                            id={itemChildren.id}  type="radio" />
                                                            <label className="checkboxCustom" for={itemChildren.id}></label>
                                                            <label for={itemChildren.id} className="acepto">
                                                                {
                                                                    itemChildren.type == 1 ?
                                                                        "Casa"
                                                                    : null
                                                                }

                                                                {
                                                                    itemChildren.type == 2 ?
                                                                        "Oficina"
                                                                    : null
                                                                }

                                                                {
                                                                    itemChildren.type == 3 ?
                                                                        "Apartamento"
                                                                    : null
                                                                }

                                                                {
                                                                    itemChildren.type == 4 ?
                                                                        "Otros"
                                                                    : null
                                                                }
                                                            </label>
                                                        </div>

                                                    ))}

                                                        

                                                      

                                                        <div className='itemDireccionLink' onClick={() => this.openCloseModalDireccion()}>
                                                            <img className="iconMas" src="/images/icon_mas.png" alt="llaollao" />
                                                            <a>Ingresar nueva dirección</a>
                                                        </div>

                                                    </div>

                                                    <div className="contDetalleDireccion">

                                                        <div className="contItemDetalle">
                                                            <div className="itemTitulo">Departamento:</div>
                                                            <div className="itemValor">{this.state.direccionSeleccionada.locality.departmentName}</div>
                                                        </div>

                                                        <div className="contItemDetalle">
                                                            <div className="itemTitulo">Municipio:</div>
                                                            <div className="itemValor">{this.state.direccionSeleccionada.locality.name}</div>
                                                        </div>

                                                        <div className="contItemDetalle">
                                                            <div className="itemTitulo">Colonia:</div>
                                                            <div className="itemValor">{this.state.direccionSeleccionada.zone.name}</div>
                                                        </div>

                                                        
                                                    </div>

                                                    <div className="lineaDirecciones"></div>

                                                    <div>
                                                        <div className="tituloCampo">
                                                            <i className="glyphicon glyphicon-home"></i> &nbsp;&nbsp;DIRECCIÓN
                                                        </div>
                                                        <textarea 
                                                        onChange={(text) => {
                                                            this.setState({direccion:text.target.value,errorDireccion:false})
                                                        }}
                                                        value={this.state.direccion}
                                                        className={this.state.errorDireccion ? "error observaciones" : "observaciones"} 
                                                        id="streetaddress" name="streetaddress" required="" ></textarea>
                                                        {
                                                            this.state.errorDireccion == true ?
                                                                <label className="error">campo obligatorio.</label>
                                                            :null
                                                        }                                
                                                    </div>

                                                    <div>

                                                        {
                                                            this.state.mensajeCompraDesactivada != "" ?
                                                                <label className="error" style={{marginBottom:"20px"}}>{this.state.mensajeCompraDesactivada}</label>
                                                            : null
                                                        }

                                                        {
                                                        this.state.loadDireccion == true ?
                                                            <div className="contSpinner"><Spinner /></div>
                                                        : 
                                                            this.state.activarCompra ?
                                                                <div>
                                                                    <Button className="btn-agregar btnConfirmar" onClick={(e) => this.enviarDatosOrden(e)} >CONFIRMAR DIRECCIÓN</Button>
                                                                    <Button className="btn-agregar btnCancelar" onClick={(e) =>  window.location = "/"}>Cancelar</Button>
                                                                </div>
                                                            : null 
                                                        }
                                                        

                                                    </div>

                                                    <div className="clear"></div>
                                                </div>
                                            }

                                           

                                        </div>
                                    </Col> 
                                </Row>


                            </TabPanel>
                            <TabPanel tabId="1">
                                <Row className="">
                                    <Col xs={12}>
                                        <div className="contFormularioContactenos contCarritoProducto" style={{marginLeft:"50px",marginRight:"50px",padding:"25px"}}>
                                            <div className="tituloContactenos">productos seleccionados:</div>

                                            {this.state.carrito.map(item => (
                                                <div>
                                                    <div className="contProducto">
                                                    {
                                                        item.isTopping == true ?
                                                            <div style={{marginLeft:"10px",width:"100px",marginRight:"16px"}}>
                                                                <div className="circleTopping" style={{height:"auto"}}>
                                                                    <div className="contentTopping">
                                                                        <img className="imgTopping" src={item.base_image} />
                                                                    </div>
                                                                    
                                                                </div>

                                                            </div>
                                                            
                                                        :
                                                        null
                                                    }

                                                    {
                                                        item.promo == false && item.isTopping == false  ?
                                                            <img src={item.base_image} className="imgProducto" style={{marginLeft:"5px"}} />
                                                        :
                                                            null
                                                    }

                                                    {
                                                        item.promo == true ?
                                                            <div className="contInfo">
                                                                <div  className="namePromo">{item.name}</div>
                                                                <div style={{display:"flex"}}>
                                                                    <img src={item.productoUno.base_image} className="imgProducto" />
                                                                    {this.dataProductoCarrito(item.productoUno,false)}
                                                                </div>

                                                                <div style={{display:"flex"}}>
                                                                    <img src={item.productoDos.base_image} className="imgProducto" />
                                                                    {this.dataProductoCarrito(item.productoDos,false)}
                                                                </div>

                                                                <div className="contCantidad" style={{position:"absolute",top:"12px",right:"0px"}}>
                                                                    
                                                                    <div style={{display:"flex",marginRight:"50px"}}>
                                                                        <div className="contMenos" onClick={(e) => this.actualizarCantidad(e,item,item.quantity-1)}>-</div>
                                                                        <div className="txCantidad">{item.quantity}</div>
                                                                        <div className="contMenos" onClick={(e) => this.actualizarCantidad(e,item,item.quantity+1)}>+</div>
                                                                    </div>

                                                                    

                                                                    <div className='contEditAddress' style={{top:"3px"}} onClick={(e) => this.removerProducto(e,item)}>
                                                                        <i className="glyphicon glyphicon-trash"></i>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"5px"}}>
                                                                    <div className="txTotal">total producto</div>
                                                                    <div className="subTotalCarrito">{this.props.currency}{item.total.toFixed(2)}</div>
                                                                </div>
                                                            </div>
                                                        :
                                                        null
                                                    }

                                                    {
                                                        item.promo != true ?
                                                        <div className="contInfo">
                                                            {this.dataProductoCarrito(item,true)}
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                        
                                                        

                                                        
                                                    </div>
                                                    <hr />
                                                </div>
                                            ))}

                                            <div  style={{marginLeft:"10px"}} className="imagenesIlustrativa">* imagenes ilustrativas</div>

                                            <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"5px",marginBottom:"20px"}}>
                                                <div className="txTotal">subtotal</div>
                                                <div className="subTotalCarrito">{this.props.currency}{this.state.subTotal.toFixed(2)}</div>
                                            </div>
                                            
                                            <div className="contEnvioCosto" style={{paddingRight:"33px"}}>
                                                <span className="txEnvioDomicilio">ENVÍO A DOMICILIO:</span>
                                                <span className="txCostoEnvio">{this.props.currency} {parseFloat(this.state.direccionSeleccionada.zone.delivery_fee).toFixed(2)}</span>
                                            </div>
                                            <div className="clear"></div>
                                            <div className="contListDirecciones" style={{marginTop:"16px",paddingRight:"30px"}}>

                                                <div className='itemDireccion d-flex justify-content-end' style={{marginLeft:"16px",width: "-webkit-fill-available",paddingTop:"11px",marginRight:"0px"}}>TOTAL DE COMPRA</div>
                                                <span className='txTotalOrden'>{this.props.currency} {parseFloat(this.state.total).toFixed(2)}</span>
                                            </div>

                                            

                                            <div className="contToppings contToppingsCarrito" style={{minHeight:"100px"}}>               
                                                <div className="agregaToppingsCarrito">Agrega toppings extras a tu orden</div>                       
                                                <Tabs style={{marginTop:"10px"}}>
                                                    <TabList>
                                                        {this.state.dataToppings.map((item, index) => (

                                                            <Tab tabFor={index}>{item.name}</Tab>
                                                        ))}
                                                    </TabList>

                                                    {this.state.dataToppings.map((item, index) => (
                                                        <TabPanel tabId={index}>
                                                            <Row className="rowRegistro">
                    
                                                                {item.items.map(itemChildren => (
                    
                                                                    
                                                                    itemChildren.id != 35 ?
                                                                        <Col xs={2} style={{width:"100px"}} className="contMainItem" onClick={(e) => this.handleAgregarProductoTopingsD(e,itemChildren)}>
                                                                            <div className="circleTopping">
                                                                                <div className="contentTopping">
                                                                                    <div className="masMenos">+</div>
                                                                                    <img className="imgTopping" src={itemChildren.icon_image} alt={itemChildren.name} />
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            <div className="nameTopping">{itemChildren.name}</div>
                                                                        </Col>
                    
                                                                    :
                                                                    null
                                                                    
                                                                    
                    
                                                                    
                                                                ))}
                    
                    
                                                                {item.items.filter((item) => item.id == 35).map(itemChildren => (
                    
                                                                    <div className='contMascotas'>
                                                                        <div className='titleMascotas'>Mascotas</div>
                    
                                                                        <Col xs={2} style={{width:"100px"}} className="contMainItem" onClick={(e) => this.handleAgregarProductoTopingsD(e,itemChildren)}>
                                                                            <div className="circleTopping">
                                                                                <div className="contentTopping">
                                                                                    <div className="masMenos">+</div>
                                                                                    <img className="imgTopping" src={itemChildren.icon_image} alt={itemChildren.name} />
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            <div className="nameTopping">{itemChildren.name}</div>
                                                                        </Col>
                                                                    </div>
                    
                                                                ))}
                    
                                                                
                    
                                                            </Row>
                                                            
                                                        </TabPanel>
                                                    ))}
                                                </Tabs>

                                            </div>
                                                            
                                            
                                            <div className="clear"></div>
                                            {
                                                this.props.show_tip == "1" ?
                                                    <div className="contListDirecciones" style={{marginTop:"16px"}}>

                                                    <div className='itemDireccion' style={{marginLeft:"16px"}}>PROPINA PARA REPARTIDOR:</div>


                                                    <div className='itemDireccion'>
                                                        <input 
                                                        checked={(this.state.propina == 0) && !this.state.otroValor ? true : false}
                                                        name="radioPropina"
                                                        onChange={(text) => {
                                                            //text.target.checked
                                                        
                                                            var calcularTotal = this.state.subTotal + this.state.direccionSeleccionada.zone.delivery_fee - this.state.descuento
                                                            this.setState({propina:0,total:calcularTotal,propinaCustom:"",otroValor:false})
                                                            
                                                        }}
                                                        id={0}  type="radio" />
                                                        <label className="checkboxCustom" for={0}></label>
                                                        <label for={0} className="acepto">Sin propina
                                                        </label>
                                                    </div>

                                                    {this.state.driverTip.map(item => (
                                                        <div className='itemDireccion'>
                                                            <input 
                                                            checked={(this.state.propina == item.amount) && !this.state.otroValor ? true : false}
                                                            name="radioPropina"
                                                            onChange={(text) => {
                                                                //text.target.checked
                                                                console.log(this.state.propina)
                                                                console.log(item.amount)
                                                                if(this.state.propina == item.amount){

                                                                    var calcularTotal = this.state.subTotal + this.state.direccionSeleccionada.zone.delivery_fee - this.state.descuento
                                                                    this.setState({propina:0,total:calcularTotal,propinaCustom:"",otroValor:false})
                                                                }else{
                                                                    var calcularTotal = this.state.subTotal + this.state.direccionSeleccionada.zone.delivery_fee + item.amount - this.state.descuento
                                                                    this.setState({propina:item.amount,total:calcularTotal,propinaCustom:"",otroValor:false})
                                                                }
                                                                
                                                            }}
                                                            id={item.amount}  type="radio" />
                                                            <label className="checkboxCustom" for={item.amount}></label>
                                                            <label for={item.amount} className="acepto">{item.currency}{parseFloat(item.amount).toFixed(2)}
                                                            </label>
                                                        </div>
                                                    ))}



                                                    <div className='itemDireccion'>
                                                        <input 
                                                        checked={this.state.otroValor}
                                                        name="radioPropina"
                                                        onChange={(text) => {
                                                            //text.target.checked
                                                        
                                                            var calcularTotal = this.state.subTotal + this.state.direccionSeleccionada.zone.delivery_fee - this.state.descuento
                                                            this.setState({otroValor:text.target.checked,propina:0,total:calcularTotal})
                                                            
                                                        }}
                                                        id="otro"  type="radio" />
                                                        <label className="checkboxCustom" for="otro"></label>
                                                        <label for="otro" className="acepto">Otro valor
                                                        </label>

                                                        <input
                                                            disabled={!this.state.otroValor}
                                                            onChange={(text) => {
                                                                var precio = 0
                                                                if(text.target.value != ""){
                                                                    precio = parseFloat(text.target.value)
                                                                }
                                                                var calcularTotal = parseFloat(this.state.subTotal) + parseFloat(this.state.direccionSeleccionada.zone.delivery_fee) + precio - this.state.descuento
                                                                this.setState({total:calcularTotal,propinaCustom:text.target.value})

                                                                //this.setState({propinaCustom:text.target.value})
                                                            }}
                                                            value={this.state.propinaCustom}
                                                            placeholder='$ 00.00'
                                                            style={{width:"100px",marginLeft:"10px"}}
                                                            type="number" maxlength="100" />

                                                    


                                                    </div>

                                                    </div>
                                                : null
                                                
                                            }
                                            

                                            <div className="lineaDirecciones"></div>

                                            {
                                                this.state.beneficioAgregado.idCode != 0 ?
                                                <div>
                                                    <div className="contListDirecciones"  style={{marginTop:"0px",paddingTop:"0px"}}>
                                                        <div className='itemDireccion' style={{marginLeft:"16px",width: "-webkit-fill-available"}}>SUB-TOTAL</div>
                                                        <span className='txTotalBeneficio'>{this.props.currency} {parseFloat(this.state.subTotal).toFixed(2)}</span>
                                                    </div>

                                                    <div className="contListDirecciones" style={{marginTop:"0px",paddingTop:"0px"}}>
                                                        <div className='itemDireccion' style={{marginLeft:"16px",width: "-webkit-fill-available"}}>{this.state.beneficioAgregado.title} ({this.state.beneficioAgregado.description2})</div>
                                                        <span className='txTotalBeneficio'>{this.props.currency} {parseFloat(parseFloat(this.state.subTotal) * (parseFloat(this.state.beneficioAgregado.value) / 100)).toFixed(2)}</span>
                                                    </div>

                                                    <div className="lineaDirecciones"></div>
                                                </div>
                                            : null
                                            }

                                            <div style={{marginTop:"0px"}}>
                                                <div className="tituloCampo">
                                                OBSERVACIONES
                                                </div>

                                                <textarea 
                                                onChange={(text) => {
                                                    this.setState({observaciones:text.target.value})
                                                }}
                                                value={this.state.observaciones}
                                                className="observaciones"
                                                maxlength="150"
                                                ></textarea>

                                               

                                            </div>
                                            

                                            <div className="contListDirecciones" style={{marginTop:"16px"}}>

                                                <div className='itemDireccion d-flex justify-content-end' style={{marginLeft:"16px",width: "-webkit-fill-available",paddingTop:"11px",marginRight:"0px"}}>TOTAL DE COMPRA</div>
                                                <span className='txTotalOrden'>{this.props.currency} {parseFloat(this.state.total).toFixed(2)}</span>
                                            </div>

                                            <div>

                                                {
                                                    this.state.mensajeCompraDesactivada != "" ?
                                                        <label className="error" style={{marginBottom:"20px"}}>{this.state.mensajeCompraDesactivada}</label>
                                                    : null
                                                }

                                                {
                                                    this.state.activarCompra ?
                                                        <div>
                                                            <Button onClick={(e) => this.enviarDatosOrden(e)}  className="btn-agregar btnConfirmar">CONFIRMAR ORDEN</Button>
                                                            <Button className="btn-agregar btnCancelar"   onClick={(e) =>  window.location = "/"}>Cancelar</Button>
                                                        </div>
                                                    : null
                                                }



                                               

                                            </div>

                                            <div className="clear"></div>



                                        </div>
                                        
                                    </Col> 
                                    
                                </Row>
                            </TabPanel>
                            <TabPanel tabId="2">
                                <Row className="rowRegistro">
                                    <Col xs={12}>

                                        <div className="contFormularioContactenos contCarritoProducto" style={{marginLeft:"50px",marginRight:"50px",padding:"25px"}}>
                                            <Row>
                                                <Col xs={4} className="nopadding">
                                                    <div className="tituloContactenos">método de pago</div>
                                                    <div>

                                                        

                                                        <div className='itemMetodoPago' onClick={() => this.setState({pagoTarjeta:true,pagoEfectivo:false}) }>
                                                            <input 
                                                            checked={this.state.pagoTarjeta}
                                                            name="radioMetodo"
                                                            onChange={(text) => {
                                                                this.setState({pagoTarjeta:text.target.checked,pagoEfectivo:false})
                                                            }}
                                                            id="pagoTarjeta"  type="radio" />
                                                            <label className="checkboxCustom" for="pagoTarjeta"></label>
                                                            <label for="1" className="acepto">Pago con tarjeta</label>
                                                            <img src="/images/tarjeta_credito.png" className="iconMetodoPago" />

                                                        </div>

                                                        <div className='aceptamosTarjeta'>
                                                            Aceptamos
                                                            <img src="/images/icon_visa.png" className="iconTarjetaVM" />
                                                            <img src="/images/icon_master.png" className="iconTarjetaVM" />
                                                        </div>


                                                        <div className='itemMetodoPago'  onClick={() => this.setState({pagoEfectivo:true,pagoTarjeta:false}) }>
                                                            <input 
                                                            checked={this.state.pagoEfectivo}
                                                            name="radioMetodo"
                                                            onChange={(text) => {
                                                                this.setState({pagoEfectivo:text.target.checked,pagoTarjeta:false})
                                                            }}
                                                            id="pagoEfectivo"  type="radio" />
                                                            <label className="checkboxCustom" for="pagoEfectivo"></label>
                                                            <label for="1" className="acepto">Pago en efectivo</label>
                                                            <img src="/images/pago_efectivo.png" className="iconMetodoPago" />

                                                        </div>

                                                    </div>
                                                </Col>
                                                <Col xs={8} style={{paddingLeft:"40px"}}>

                                                    {
                                                        this.state.pagoTarjeta ?
                                                        <div>
                                                            <div>
                                                                <div style={{marginTop:"30px"}}>
                                                                    <div className="tituloCampo">
                                                                        NOMBRE COMO FIGURA EN SU TARJETA
                                                                    </div>
                                                                    <input 
                                                                    onChange={(text) => {
                                                                        this.setState({nombreTarjeta:text.target.value.toUpperCase(),errorNombreTarjeta:false})
                                                                    }}
                                                                    value={this.state.nombreTarjeta}
                                                                    className={this.state.errorNombreTarjeta ? "error" : ""} 
                                                                    placeholder='NOMBRE Y APELLIDO'
                                                                    maxchar="1"
                                                                    type="text" maxlength="100" />
                                                                    {
                                                                        this.state.errorNombreTarjeta == true ?
                                                                            <label className="error">campo obligatorio.</label>
                                                                        :null
                                                                    }
                                                                    
                    
                                                                </div>

                                                                <div style={{marginTop:"0px"}}>
                                                                    <div className="tituloCampo">
                                                                        NÚMERO DE TARJETA
                                                                    </div>
                                                                    <div className='contTarjeta'>
                                                                        <img className="iconTarjeta" src="/images/icon_tarjeta.png" alt="llaollao" />
                                                                        <InputMask 
                                                                            onChange={(text) => {
                                                                                const valor = text.target.value.replace(/\D/g, '')
                                                                                this.setState({numeroTarjeta:valor,errorNumeroTarjeta:false})
                                                                            }}
                                                                            value={this.state.numeroTarjeta}
                                                                            className={this.state.errorNumeroTarjeta ? "inputTarjeta error" : "inputTarjeta"}
                                                                            mask="9999-9999-9999-9999" 
                                                                            placeholder="0000 - 0000 - 0000 - 0000"  
                                                                            type="tel" />

                                                                        {
                                                                            this.state.errorNumeroTarjeta == true ?
                                                                                <label className="error">campo obligatorio.</label>
                                                                            :null
                                                                        }

                                                                    </div>
                                                                
                                                                    
                                                                    
                    
                                                                </div>
                                                            </div>
                                                        

                                                            <Row>
                                                                <Col xs={6}>
                                                                    <div style={{marginTop:"0px"}}>
                                                                        <div className="tituloCampo">
                                                                        FECHA DE EXPIRACIÓN
                                                                        </div>
                                                                        <div className='contTarjeta'>
                                                                            <img className="iconTarjeta" src="/images/icon_fecha.png" alt="llaollao" />
                                                                            <InputMask 
                                                                                onChange={(text) => {
                                                                                    //const valor = text.target.value.replace(/\D/g, '/')
                                                                                    const valor = text.target.value
                                                                                    this.setState({fechaExpiracion:valor,errorFechaExpiracion:false})
                                                                                }}
                                                                                value={this.state.fechaExpiracion}
                                                                                className={this.state.errorFechaExpiracion ? "inputTarjeta error" : "inputTarjeta"}
                                                                                mask="99/99" 
                                                                                placeholder="MM/AA"
                                                                                type="tel"
                                                                                />
                                
                                                                        </div>
                                                                        {
                                                                            this.state.errorFechaExpiracion == true ?
                                                                                <label className="error">campo obligatorio.</label>
                                                                            :null
                                                                        }
                                                                        
                                                                        
                        
                                                                    </div>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <div style={{marginTop:"0px"}}>
                                                                        <div className="tituloCampo">
                                                                        CVV
                                                                        </div>
                                                                        <div className='contTarjeta'>
                                                                            <img className="iconTarjeta" src="/images/icon_cvv.png" alt="llaollao" />
                                                                            <input 
                                                                                onChange={(text) => {
                                                                                    const valor = text.target.value.replace(/\D/g, '')
                                                                                    this.setState({cvcTarjeta:valor,errorCVCTarjeta:false})
                                                                                }}
                                                                                value={this.state.cvcTarjeta}
                                                                                className={this.state.errorCVCTarjeta ? "inputTarjeta error" : "inputTarjeta"}
                                                                                placeholder="000"
                                                                                type="number"
                                                                                />

                                                                        </div>
                                                                    
                                                                        {
                                                                            this.state.errorCVCTarjeta == true ?
                                                                                <label className="error">campo obligatorio.</label>
                                                                            :null
                                                                        }
                                                                        
                        
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        :
                                                        <div>
                                                             <div style={{marginTop:"30px"}}>
                                                                <div className="tituloCampo">
                                                                CON CUANTO PAGARÁS:
                                                                </div>
                                                                <div style={{marginTop:"10px"}}>
                                                                    <div className='itemDireccion'>
                                                                        <input 
                                                                        checked={this.state.exacto}
                                                                        onChange={(text) => {
                                                                            this.setState({exacto:text.target.checked,cambioMonto:"",cambio:false})
                                                                        }}
                                                                        id="exacto"  type="radio" />
                                                                        <label className="checkboxCustom" for="exacto"></label>
                                                                        <label for="exacto" className="acepto">Exacto</label>
                                                                        

                                                                        <input 
                                                                        checked={this.state.cambio}
                                                                        onChange={(text) => {
                                                                            this.setState({cambio:text.target.checked,cambioMonto:"",exacto:false})
                                                                        }}
                                                                        
                                                                        id="cambios"  type="radio" />
                                                                        <label className="checkboxCustom"  style={{marginLeft:"20px"}}  for="cambios"></label>
                                                                        <label for="cambios"className="acepto">Quiero cambio para</label>
                                                                        <span className='dolar'>$</span>


                                                                       
                                                                        <input
                                                                        disabled={this.state.exacto}
                                                                        onChange={(text) => {
                                                                            this.setState({cambioMonto:text.target.value,errorCambio:false})
                                                                        }}
                                                                        value={this.state.cambioMonto}
                                                                        placeholder='00.00'
                                                                        maxchar="1"
                                                                        style={{width:"80px",paddingRight:"5px"}}
                                                                        type="number" maxlength="100" />

                                                                   
                                                                    </div>
                                                                    
                                                                    <label className="error" style={{marginBottom:"20px",color:"#000",display:"block",fontSize:"12px"}}>No se aceptarán pagos con billetes de $50 y $100</label>


                                                                    {
                                                                        this.state.errorCambio ?
                                                                        <label className="error">POR FAVOR INGRESE UN VALOR MAYOR A {this.state.driverTip[0].currency}{this.state.total}</label>
                                                                        :
                                                                        <label className="error">&nbsp;&nbsp;</label>

                                                                    }
                                                                </div>
                                                                
                                                               

                                                             </div>
                                                            
                                                        </div>
                                                    }
                                                   

                                                    <div className='lineaDirecciones'></div>

                                                    <div className='itemDireccion'>
                                                        <input 
                                                        checked={this.state.factura}
                                                        onChange={(text) => {
                                                            this.setState({factura:text.target.checked})
                                                        }}
                                                        id="factura"  type="checkbox" />
                                                        <label className="checkboxCustom" for="factura"></label>
                                                        <label for="factura" className="acepto">Solicitar factura</label>
                                                    </div>
                                                    
                                                    {
                                                        this.state.factura ?
                                                        <div>
                                                        <div style={{marginTop:"30px"}}>
                                                            <div className="tituloCampo">
                                                                NOMBRE
                                                            </div>
                                                            <input 
                                                            onChange={(text) => {
                                                                this.setState({nombreFactura:text.target.value,errorNombreFactura:false})
                                                            }}
                                                            value={this.state.nombreFactura}
                                                            className={this.state.errorNombreFactura ? "error" : ""} 
                                                            maxchar="1"
                                                            type="text" maxlength="100" />
                                                            {
                                                                this.state.errorNombreFactura == true ?
                                                                    <label className="error">campo obligatorio.</label>
                                                                :null
                                                            }
                                                            
            
                                                        </div>

                                                        <div style={{marginTop:"0px"}}>
                                                            <div className="tituloCampo">
                                                                NIT
                                                            </div>
                                                            <div className='contTarjeta'>
                                                                
                                                                <InputMask 
                                                                    onChange={(text) => {
                                                                        //const valor = text.target.value.replace(/\D/g, '')
                                                                        this.setState({nitFactura:text.target.value,errorNit:false})
                                                                    }}
                                                                    value={this.state.nitFactura}
                                                                    className={this.state.errorNit ? "error" : ""} 
                                                                    mask="9999-999999-999-9" 
                                                                    placeholder="0000 000000 000 0"  
                                                                    type="tel" />


                                                                    

                                                            </div>
                                                        
                                                            {
                                                                this.state.errorNit == true ?
                                                                    <label className="error">campo obligatorio.</label>
                                                                :null
                                                            }
                                                            
            
                                                        </div>

                                                        <div style={{marginTop:"0px"}}>
                                                            <div className="tituloCampo">
                                                                DIRECCIÓN
                                                            </div>
                                                            <input 
                                                            onChange={(text) => {
                                                                this.setState({direccionFactura:text.target.value,errorDireccion:false})
                                                            }}
                                                            value={this.state.direccionFactura}
                                                            className={this.state.errorDireccion ? "error" : ""} 
                                                            maxchar="1"
                                                            type="text" maxlength="100" />
                                                            {
                                                                this.state.errorDireccion == true ?
                                                                    <label className="error">campo obligatorio.</label>
                                                                :null
                                                            }
                                                            
            
                                                        </div>


                                                        <div style={{marginTop:"0px"}}>
                                                            <div className="tituloCampo">
                                                                GIRO
                                                            </div>
                                                            <input 
                                                            onChange={(text) => {
                                                                this.setState({giroFactura:text.target.value,errorGiro:false})
                                                            }}
                                                            value={this.state.giroFactura}
                                                            className={this.state.errorGiro ? "error" : ""} 
                                                            maxchar="1"
                                                            type="text" maxlength="100" />
                                                            {
                                                                this.state.errorGiro == true ?
                                                                    <label className="error">campo obligatorio.</label>
                                                                :null
                                                            }
                                                            
            
                                                        </div>
                                                        </div>
                                                    
                                                        : null
                                                    }


                                                    <div className='itemDireccion' style={{marginTop:"20px"}}>
                                                        <input 
                                                        checked={this.state.terminos}
                                                        onChange={(text) => {
                                                            if(text.target.checked){
                                                                this.setState({terminos:text.target.checked,errorTerminos:false})
                                                            }else{
                                                                this.setState({terminos:text.target.checked})
                                                            }
                                                        }}
                                                        id="terminos"  type="radio" />
                                                        <label className="checkboxCustom" for="terminos"></label>
                                                        <label for="terminos" className="acepto">Acepto Términos y Condiciones</label>
                                                    </div>
                                                    {
                                                        this.state.errorTerminos == true ?
                                                            <label className="error">Acepte términos y condiciones.</label>
                                                        :null
                                                    }

                                                    {
                                                        this.state.mensajeError != "" ?
                                                            <div style={{textAlign:"right",paddingRight:"0px",marginTop:"10px"}}>
                                                                <label className="error" style={{fontSize:"12px"}}>{this.state.mensajeError}</label>
                                                            </div>
                                                        :null
                                                    }


                                                    

                                                    <div>

                                                    {
                                                        this.state.mensajeCompraDesactivada != "" ?
                                                            <label className="error" style={{marginBottom:"20px"}}>{this.state.mensajeCompraDesactivada}</label>
                                                        : null
                                                    }

                                                    {
                                                        this.state.activarCompra ?
                                                            <div>
                                                                <Button onClick={(e) => this.enviarDatosOrden(e)}  className={this.props.amountMin > (this.state.beneficioAgregado.idCode != 0 ? (this.state.subTotal - (this.state.subTotal * (parseFloat(this.state.beneficioAgregado.value) / 100))) : this.state.subTotal) ? "btn-agregar btnConfirmar btnContinuarDesactivado" : "btn-agregar btnConfirmar"}>
                                                                {
                                                                    this.state.loadSendData == true ?
                                                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                                                    : 
                                                                    "CONFIRMAR PAGO"
                                                                }
                                                                </Button>
                                                                <Button className="btn-agregar btnCancelar"  onClick={(e) =>  window.location = "/"}>Cancelar</Button>
                                                            </div>
                                                        : null
                                                    }



                                                        

                                                    </div>


                                                    <div className="clear"></div>
                                                    {
                                                        this.props.amountMin != "" ? 
                                                        this.props.amountMin > (this.state.beneficioAgregado.idCode != 0 ? (this.state.subTotal - (this.state.subTotal * (parseFloat(this.state.beneficioAgregado.value) / 100))) : this.state.subTotal) ?
                                                        <div>
                                                            <label className="error" style={{display:"block",textAlign:"right",marginTop:"20px"}}>no cubres el monto mínimo de producto</label>
                                                            <div className="espacioInfoDelivery d-flex justify-content-end" style={{marginTop:"20px"}}> <img className="imgDelivery" src="/images/food_delivery.png" alt="llaollao" /> 
                                                            Pedido mínimo de producto: {this.props.currency}{parseFloat(this.props.amountMin).toFixed(2)}</div> 
                                                        </div>
                                                        
                                                        : null
                                                        : ""
                                                    }
                                                    

                                                    <div className="clear"></div>
                                                    

                                                </Col>
                                            </Row>
                                            
                                        </div>
                                    
                                    </Col>
                                    
                                </Row>
                            </TabPanel>
                        </Tabs>
                    </div>        
                </Row>
                

                

                
            </Col>

            <Modal
                    isOpen={this.state.setIsOpenDireccion}
                    style={customStyles}
                    contentLabel=""
                >
                    <div>
                        <img onClick={() => this.openCloseModalDireccion(false)} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left"}}>{this.state.direccionSelect == null ? "Agregar Dirección":"Actualizar Dirección"}</div>

                        <Row className="rowRegistro" style={{paddingRight:"7%",paddingLeft:"7%"}}>
                            
                            <div style={{marginTop:"30px"}}>
                                <div className="tituloCampo">
                                    &nbsp;&nbsp;DEPARTAMENTO
                                </div>
                                <select 
                                onChange={(text) => {
                                    console.log("aqui",text.target.value)
                                    var listMunicipios = this.state.departamentos.filter((item) => item.id == text.target.value)

                                    this.setState({departamento:text.target.value,errorDepartamento:false,municipios:listMunicipios[0].items})

                                    $("#municipio").prop("selectedIndex", 0);

                                }}
                                value={this.state.departamento}
                                className={this.state.errorDepartamento ? "error" : ""} 
                                id="departamento" name="departamento">
                                    <option value="">Seleccionar</option>

                                    {this.state.departamentos.map(item => (
                                        <option value={item.id}>{item.label}</option>
                                    ))}

                                </select>
                                {
                                    this.state.errorDepartamento == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                            </div>


                            <div style={{marginTop:"0px"}}>
                                <div className="tituloCampo">
                                    &nbsp;&nbsp;MUNICIPIO
                                </div>
                                <select 
                                    onChange={async (text) => {
                                        this.setState({municipio:text.target.value,errorMunicipio:false})

                                        const resColonias =  await Http.instance.get(`rest/main/zones/${text.target.value}`);
                                        console.log(resColonias)
                                        

                                        var options = []
                                        resColonias.map(item => (
                                           options.push({value:item.id,label:item.name_online})
                                        ));

                                        this.setState({colonias:resColonias,options:options,coloniaNombre:null,colonia:"",coloniaNombre:""})

                                    }}
                                    value={this.state.municipio}
                                    className={this.state.errorMunicipio ? "error" : ""} 
                                    id="municipio" name="municipio">
                                    <option value="">Seleccionar</option>
                                    {this.state.municipios.map(item => (
                                        <option value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                                {
                                    this.state.errorMunicipio == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                            </div>

                            <div className='noInput'>
                                <div className="tituloCampo">
                                    &nbsp;&nbsp;COLONIA
                                </div>
                                <Select  
                                    onChange={(text) => {
                                        console.log(text.label)
                                        this.setState({colonia:text.value,coloniaNombre:text,errorColonia:false})
                                    }}
                                    placeholder="Seleccionar"
                                    value={this.state.coloniaNombre}
                                    className={this.state.errorColonia ? "errorSelect" : ""} 
                                    options={this.state.options}
                                    id="colonia" name="colonia">
                                   
                                </Select >
                                {
                                    this.state.errorColonia == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                            </div>

                            <div>
                                <div className="tituloCampo">
                                    &nbsp;&nbsp;TIPO DE DIRECCIÓN
                                </div>
                                <select 
                                    onChange={(text) => {
                                        this.setState({tipoDireccion:text.target.value,errorTipoDireccion:false})
                                    }}
                                    value={this.state.tipoDireccion}
                                    className={this.state.errorTipoDireccion ? "error" : ""} 
                                    id="colonia" name="colonia">
                                        <option value="">Seleccionar</option>
                                        <option value="1">Casa</option>
                                        <option value="2">Oficina</option>
                                        <option value="3">Apartamento</option>
                                        <option value="4">Otros</option>
                                </select>
                            </div>
                            {
                                this.state.errorTipoDireccion == true ?
                                    <label className="error">campo obligatorio.</label>
                                :null
                            }


                            <div>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-home"></i> &nbsp;&nbsp;DIRECCIÓN
                                </div>
                                <textarea 
                                onChange={(text) => {
                                    this.setState({direccionNueva:text.target.value,errorDireccionNueva:false})
                                }}
                                value={this.state.direccionNueva}
                                className={this.state.errorDireccionNueva ? "error" : ""} 
                                id="streetaddress" name="streetaddress" required="" ></textarea>
                                {
                                    this.state.errorDireccionNueva == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }                                
                            </div>

                            {
                                this.state.errorSaveDireccion != "" ?
                                    <label className="error" style={{display:"block",textAlign:"center"}}>{this.state.errorSaveDireccion}</label>
                                :null
                            }
    
                            <Button onClick={(e) => this.guardarActualizarDireccion(e)} style={{width:"97%",marginLeft:"calc(var(--bs-gutter-x) * .5)"}} className="btn-agregar btnContinuarCrearCuenta">
                                {
                                    this.state.loadingSaveDireccion== true ?
                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                    : 
                                    "GUARDAR CAMBIOS"
                                }

                                
                            </Button>

                        </Row>
                    </div>
                    
            </Modal>

            <Modal
                    closeTimeoutMS={500}
                    isOpen={this.state.setIsOpenCard}
                    style={customStyles}
                    contentLabel=""
                >
                <div className='productoAgregado'></div>
                <div className='nombreProductoAgregado'>{this.state.mensaje}</div>
                <div className='noOrden'>No. autorizacion: {this.state.referencia}</div>
                <Button onClick={(e) => window.location.replace("/ordenfinalizada") }  className="btn-agregar btnContinuarCrearCuenta">Aceptar</Button>

            </Modal>

        </Row>
      );
    }
}

export default DetalleOrden;