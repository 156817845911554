import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Row,Col,Button} from "react-bootstrap";
import "react-activity/dist/Spinner.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Spinner } from "react-activity";
import Http from '../../libs/http'
import { HeaderBotones } from '../../header/header_botones'


class TerminosCondiciones extends React.Component {

    state = {
        loadInfo: true,
    };

    handleSelectTabs = index => {
        this.setState({ selectedIndex: index });
    };

    constructor(props) {
        super(props);
    }


    async componentDidMount() {


        this.setState({isMobileSend:this.props.isMobileSend})

        const res =  await Http.instance.get("rest/pages/term-cond");
        console.log("res",res.info.content)
        this.setState({content:res.info.content,loadInfo:false,titulo:res.info.title})     
        
    }

    render() {
      return (
        <Row className={this.state.isMobileSend ? "movil noPadding":""}>
            <Col xs={12} className={this.state.isMobileSend ? "noPadding":"" }>
                {
                    this.state.isMobileSend == false ?
                    <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                    : null
                }
                    
                <div className="clear"></div>
                <h1 className={this.state.isMobileSend == false ? 'tituloNuestrasTiendas' : 'tituloNuestrasTiendasMovil'}>{this.state.titulo}</h1>

                <div className="clear"></div>

                {
                    this.state.loadInfo == true ?
                        <div className="contSpinner"><Spinner /></div>
                    :

                
                    <Row dangerouslySetInnerHTML={{__html: this.state.content}} className={this.state.isMobileSend ? "altoMinimoMovil contAcercaDe noPadding":"contAcercaDe"}>
                            
                    </Row>

                }
                

                

                
            </Col>
        </Row>
      );
    }
}

export default TerminosCondiciones;