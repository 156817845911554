
import React from 'react';
import {Navbar, Row, Col,NavItem} from "react-bootstrap";
import { NavLink } from "react-router-dom";

function Menu(data) {

  return (
     <Row>
       <Col xs={9}>
          <Navbar id="navbar">
              <NavItem className="navitem">
                <NavLink className="navlink" exact to="/"  activeClassName="activeMenu">INICIO</NavLink>
              </NavItem>

              <NavItem className="navitem">
                <NavLink className="navlink" to="/tiendas" activeClassName="activeMenu" title="TIENDAS">TIENDAS</NavLink>
              </NavItem>

              <NavItem className="navitem">
                <NavLink className="navlink" to="/nosotros" activeClassName="activeMenu" title="NOSOTROS">NOSOTROS</NavLink>
              </NavItem>

              <NavItem className="navitem">
                <a class="navlink" title="VIDA SANUM" href={data.blog_url} target={data.blog_url.startsWith('http') ? "_blank" : ""}>VIDA SANUM</a>
              </NavItem>

              <NavItem className="navitem">
                <NavLink className="navlink" to="/contactenos" activeClassName="activeMenu" title="CONTÁCTENOS">CONTÁCTENOS</NavLink>
              </NavItem>
          </Navbar>
       </Col>
       <Col xs={3} fluid="true" className="contRedes">
          
          <a target="_blank" className="navlink" href="https://www.tiktok.com/@llaollao_sv?" title="TikTok">
              <img className="logo-tt" src="/images/ico_tiktok.svg" alt="TikTok" />
          </a>

          <a target="_blank" className="navlink" href="https://www.instagram.com/llaollao_sv/" title="Instagram">
              <img className="logo-ins" src="/images/ico_instagram.svg" alt="Instagram" />
          </a>
          <a target="_blank" className="navlink" href="https://www.facebook.com/llaollaoElSalvador" title="Facebook">
              <img className="logo-fb" src="/images/ico_fb.svg" alt="Facebook" />
          </a>

          

       </Col>
    </Row>
    
  );
}

export default Menu;
