import React,{ useState,useEffect }  from 'react';
import { Row,Col,Button} from "react-bootstrap";
import Modal from 'react-modal';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { withRouter } from "react-router";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Animaciones from '../../libs/animaciones'
import Moment from 'moment';
import 'moment/min/locales'
import $ from 'jquery'; 
import Constants from '../../libs/constants'

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 0,
      width: "320px",
      bottom: 'auto',
      transform: 'translate(-50%,-50%)',
      //transform: 'translate(0%, -50%)',
    },
  };
class DetalleProducto extends React.Component {
    
    state = {
        dataProducto:{},
        loadData:false,
        toppings: [],
        precio: 0.00,
        total: 0.00,
        categoryId: 0,
        cantidad: 1,
        toppingExtra:0,
        setIsOpenValidacion:false,
        subTotal:0,
        toppingsFree: 0,
        data:{},
        mensajeAlerta:"",
        noBaseImgMessage : '',
        noSauceImgMessage :'',
        noToppingImgMessage : '',
        totalToppingLimitMsg : 'Máximo 10 ingredientes por lado.',
        notRemovableMsg : 'Sólo 2 ingredientes de la especialidad pueden quitarse.',
        crustStepImage : 'icon-tossing-green.png',
        crustStepImageActive : 'icon-tossing-white.png',
        sauceStepImage : 'icon-sauce-green.png',
        sauceStepImageActive : 'icon-sauce-white.png',
        toppingStepImage : 'icon-pizza-green.png',
        toppingStepImageActive : 'icon-pizza-white.png',
        numberOfConfiguredToppings : 3,
        thisFrozen : {
            "size": "1",
            "style": "1",
            "toppings": []
        },
        setIsOpen:false,
        nameProductoAgregado:"",
        selectedIndex:0,
        positionTabs:0,
    };


    handleSelectTabs = index => {
        this.setState({ selectedIndex: index });
    };
    
    async agregarToppingLocal(e,itemChildren){

        const resultado = await Animaciones.instance.agregarTopping(
            itemChildren,
            this.state.categoryId,
            this.state.data,
            this.state.thisFrozen,
            this.props.data.price,
            this.state.cantidad,
            this.state.toppingsFree,
            this.state.toppings,
            this.state.dataProducto.custom_type,
            this.state.dataProducto.id,
            null,
            this.state.prod_inc
            );

        if(typeof resultado.error === 'undefined'){
            this.setState(resultado)
        }else{
            if(resultado.mensaje != ""){
                this.setState({setIsOpenValidacion:true,mensajeAlerta:resultado.mensaje,positionTabs:resultado.position})
            }
        }


            

    }

    cerrarModalValidacion(){
        this.setState({setIsOpenValidacion:false})

        if(this.state.positionTabs != -1){
            setTimeout(() => {
                this.setState({selectedIndex:this.state.positionTabs})
            }, 500);
        }
    }

    async removerToppingLocal(e,index){
        console.log("resultado ===",this.state.toppingsFree,"===")


        const resultado =  await Animaciones.instance.removerTopping(
            index,
            this.state.toppings,
            this.state.toppingsFree,
            this.state.cantidad,
            this.props.data.price,
            this.state.dataProducto.id,
            this.state.categoryId,
            this.state.prod_inc
            )

        this.setState(resultado)

    }

    async cantidadLocal(e,accion){
        const resultado =  await Animaciones.instance.cantidad(
            accion,
            this.state.cantidad,
            this.state.toppings,
            this.props.data.price,
            this.state.toppingsFree
            )

        this.setState(resultado)

    }

    confirmarOrden(e){
        this.setState({setIsOpen:false})

        var  shadow_layer = $("#cd-shadow-layer"),
        lateral_cart = $("#cd-cart"),
        menu_navigation = $(".top-nav-list")

        menu_navigation.removeClass("speed-in")


        if(lateral_cart.hasClass("speed-in")){
            lateral_cart.removeClass("speed-in").one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                //$("body").removeClass("overflow-hidden")
            })
            shadow_layer.removeClass("is-visible")
            }else{
            lateral_cart.addClass("speed-in").one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                //$("body").addClass("overflow-hidden")
            })
            shadow_layer.addClass("is-visible")
        }
    }

    async agregarProducto(e){
        //console.log(this.props.data)
        var toppingsValidos = this.state.toppings.filter((item) => item.id == "").length
        if(toppingsValidos > 0){
            this.setState({setIsOpenValidacion:true,mensajeAlerta:"Ingrese todos los toppings incluidos"})
            return
        }else{

            for (var i = 0; i < this.state.dataProducto.custom_data.toppings.length; i+=1) {
                let itemToppingValidacion = this.state.dataProducto.custom_data.toppings[i]
                let itemValidacion = this.state.dataProducto.custom_data.req_topping_free_by_cats[itemToppingValidacion.id]

                if(typeof itemValidacion != 'undefined'){
                    if(itemValidacion != 0){
                        var findToppings = this.state.toppings.filter((item) => item.categoryId == itemToppingValidacion.id).length
                        if(itemValidacion > findToppings){
                            var nameCategoria = this.state.dataProducto.custom_data.toppings.find((item) => item.id == itemToppingValidacion.id)
                            this.setState({setIsOpenValidacion:true,mensajeAlerta:"Debe de seleccionar al menos "+itemValidacion+" topping(s) de "+nameCategoria.name})
                            return
                        }
                    }
                }

            }
        }


        
        var dataProductoSave = {}
        dataProductoSave.productId = this.state.dataProducto.id
        dataProductoSave.quantity = this.state.cantidad
        dataProductoSave.total = this.state.total
        dataProductoSave.precio_unitario = this.state.dataProducto.price
        dataProductoSave.currency = this.state.dataProducto.currency
        dataProductoSave.base_image = this.state.dataProducto.small_image
        dataProductoSave.desc = this.state.dataProducto.desc
        dataProductoSave.small_desc = this.state.dataProducto.small_desc
        dataProductoSave.name = this.state.dataProducto.name_online
        dataProductoSave.toppingsFree = this.state.toppingsFree
        dataProductoSave.isTopping = false
        dataProductoSave.promo = false
        dataProductoSave.orden = parseFloat(Moment().format("DDMMYYYYHHmmss"))

        
        var custom = []
        
        //console.log(this.state.toppings.length)
        //for(item in this.state.toppings){


        for (var i = 0; i < this.state.toppings.length; i+=1) {
            let item = this.state.toppings[i]

            var dataToppings = {}
            dataToppings.toppingId = item.id
            dataToppings.name = item.categoryId == 9 ? "Salsa "+item.name : item.name
            dataToppings.categoryId = item.categoryId
            dataToppings.quantity = 1
            dataToppings.price = item.price
            custom.push(dataToppings)

            //console.log("item",item)
        }


        var toppingsGratis = custom.filter((item) => item.price == 0)
        var toppingsNoGratis = custom.filter((item) => item.price !== 0)

        var customFinal = []

        customFinal.push.apply(customFinal, toppingsNoGratis);
        customFinal.push.apply(customFinal, toppingsGratis);
        
        dataProductoSave.custom = customFinal
        dataProductoSave.keyProduct =  `${dataProductoSave.productId}${(Math.floor(Math.random() * 100) + 1)}`

        console.log(dataProductoSave)

        const key = `carrito-${dataProductoSave.keyProduct}`
        var carrito = JSON.stringify(dataProductoSave)

        localStorage.setItem(key,carrito)

        this.props.actualizarCarrito()

        this.setState({setIsOpen:true,nameProductoAgregado:this.state.dataProducto.name_online})

        
    }
    


    closeModal() {
        this.setState({setIsOpen:!this.state.setIsOpen})
    }

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    } 

    async componentDidMount() {
        //console.log("aquiii",this.props.match.params)
        //var id = this.props.match.params.id;
        //console.log("idBuscar",this.props.data)

        if(this.props.data.active ==  false){
            window.location = "/";
            return
        }

        console.log("resProducto",this.props.data)
        this.setState({ dataProducto: this.props.data,categoryId: this.props.data.categoryId,precio:this.props.data.price,toppingsFree:this.props.data.custom_data.toppings_free,total:this.props.data.price,data:this.props.data.custom_config}, () => {
            this.setState({loadData:true})
            var toppingsFree = this.state.toppingsFree
            var prod_inc = []
            var arrayToppings = []
            for (var i = 0; i < this.state.dataProducto.custom_data.toppings_free; i++) {
                arrayToppings.push({
                    id: "",
                    name: "",
                    type: ""
                })
            }

            
            //producto gratis promocion
            if(typeof this.state.dataProducto.promo_info !== 'undefined'){
                prod_inc = this.state.dataProducto.promo_info.prod_inc
            }

            //console.log("toppingsFree",toppingsFree)

            this.setState({toppings: arrayToppings,prod_inc:prod_inc})

        });

       
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
      return (
        <Row style={{marginLeft:"10px"}}>
            {
                this.state.loadData == false ?
                    null
                :
                <Row className="contCategoriaMain" style={{paddingLeft:"20px"}}>
                    <Row className="contNameProducto">
                        <div className="nameProducto">
                            <span className="titleCant1">{this.state.dataProducto.name_online}</span>
                            <span className="descripcionCat" dangerouslySetInnerHTML={{__html: this.state.dataProducto.small_desc}}></span>

                        </div>
                    </Row>
                    <Row>
                        <Col xs={5} className={this.state.categoryId == 1 ? "contToppings tarrinaPanel" : this.state.categoryId == 4 ? "contToppings sensacionesPanel"  :"contToppings" }>
                            <span className="toppingsFree">
                                {
                                    this.state.dataProducto.custom_data.toppings_free == 1 ?
                                    "Selecciona tu topping"
                                    :
                                    "Selecciona tus "+this.state.dataProducto.custom_data.toppings_free+" toppings"
                                }
                            </span>

                            <Tabs  selectedIndex={this.state.selectedIndex} onSelect={this.handleSelectTabs}>
                                <TabList>
                                    {this.state.dataProducto.custom_data.toppings.map((item, index) => (

                                        <Tab tabFor={index}>{item.name}</Tab>
                                    ))}
                                </TabList>

                                {this.state.dataProducto.custom_data.toppings.map((item, index) => (
                                    <TabPanel tabId={index}>
                                        <Row className="rowRegistro">

                                            {item.items.map(itemChildren => (

                                                
                                                itemChildren.id != 35 ?
                                                    <Col xs={3} className="contMainItem" onClick={(e) => this.agregarToppingLocal(e,itemChildren)}>
                                                        <div className="circleTopping">
                                                            <div className="contentTopping">
                                                                <div className="masMenos">+</div>
                                                                <img className="imgTopping" src={itemChildren.icon_image} alt={itemChildren.name} />
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="nameTopping">{itemChildren.name}</div>
                                                    </Col>

                                                :
                                                null
                                                
                                                

                                                
                                            ))}


                                            {item.items.filter((item) => item.id == 35).map(itemChildren => (

                                                <div className='contMascotas'>
                                                    <div className='titleMascotas'>Mascotas</div>

                                                    <Col xs={3} className="contMainItem" onClick={(e) => this.agregarToppingLocal(e,itemChildren)}>
                                                        <div className="circleTopping">
                                                            <div className="contentTopping">
                                                                <div className="masMenos">+</div>
                                                                <img className="imgTopping" src={itemChildren.icon_image} alt={itemChildren.name} />
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="nameTopping">{itemChildren.name}</div>
                                                    </Col>
                                                </div>

                                            ))}

                                            

                                        </Row>
                                        
                                    </TabPanel>
                                ))}
                            </Tabs>
                        </Col>
                        <Col xs={4} className="contImageMain">
                            <div className="contInfoImageMain">
                                <div style={this.state.dataProducto.categoryId == 4 ? {marginTop:"70px"}: {}}>
                                    <div className="tarrina cssanimations">
                                        <div className="frozen-image">
                                            <div className="frozen-toppings"></div>
                                        </div>
                                    </div>

                                    <img className="imgToppingMain" src={this.state.dataProducto.base_image} alt={this.state.dataProducto.name_online} />
                                </div>

                            </div>
                        </Col>
                        <Col xs={3}>
                            <div className="contCarritoRight">
                                {
                                    typeof this.props.promotion_info !== 'undefined' ?
                                        <div className='contPrecioDelivery'>
                                            <img className="moto" src={this.props.promotion_info.promo_icon} alt="llaollao" />
                                            <div className='contTextoPromoDelivery'>
                                                <div className='tituloPromoDelivery'>{this.props.promotion_info.promo_name}</div>
                                                <div className='descripcionPromoDelivery'>{this.props.promotion_info.promo_desc}</div>
                                            </div>
                                        </div>
                                    : null
                                }
                                
                                <div className="contDetalleCarrito">
                                    <span className="titleCant1">{this.state.dataProducto.name_online}</span>

                                    {this.state.toppings.map((item, index) => (
                                        <div className="itemToppingSelect">
                                            <div className="itemToppingTitle">
                                                <span className="punto"></span>
                                                {
                                                    Constants.instance.onlyCatalog == false ?
                                                    <span className="numeroTopping">topping {index+1} {(parseInt(this.state.toppingsFree)) > index ? item.type :"extra ($"+parseFloat(item.price).toFixed(2)+")"}</span>
                                                    :
                                                    <span className="numeroTopping">topping {index+1} {(parseInt(this.state.toppingsFree)) > index ? item.type :""}</span>
                                                }
                                            </div>
                                            <div className="itemToppingTitle">
                                                {
                                                    item.name != "" ?
                                                    <div><span className={item.price == 0 ? "seleccionar gratis" : "seleccionar"}>{item.categoryId == 9 ? "Salsa ":""} {item.name} {item.price == 0 ? "(Gratis)": null}</span>
                                                    <img onClick={(e) => this.removerToppingLocal(e,index)} className="removeTopping" src="/images/icon_cerrar.png" /></div>
                                                    :
                                                    <span className="sinSeleccionar">Sin seleccionar</span>
                                                }
                                                
                                            </div>                                      
                                        </div>
                                    ))}
                                </div>
                                {
                                    Constants.instance.onlyCatalog == false ?
                                        <div className="contAddCarrito">
                                        <div className="contToppingExtra">
                                            <div className="contToppingExtraBorder">

                                                <span className="cantidadToppingExtra"></span>
                                            </div>
                                        </div>

                                        <div className="contAgregar">
                                            <div className="contAgregarBorder">
                                            <Row>
                                                {
                                                    Constants.instance.onlyCatalog() == false ?
                                                        <div className='d-flex justify-content-center'>
                                                            <div className="modificarCantidad"  onClick={(e) => this.cantidadLocal(e,false)}>-</div>
                                                            <div className="cantidad">{this.state.cantidad}</div>
                                                            <div className="modificarCantidad"  onClick={(e) => this.cantidadLocal(e,true)}>+</div>

                                                        </div>
                                                    : null
                                                }
                                                
                                                <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"15px"}}>
                                                    <div className="txTotal">Precio</div>
                                                    <div className="subTotal">${this.state.precio.toFixed(2)}</div>
                                                </div>
                                                {
                                                    this.state.toppingExtra != 0 ?
                                                    <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"5px"}}>
                                                        <div className="txTotal">topping extra(s)</div>
                                                        <div className="subTotal">${this.state.toppingExtra.toFixed(2)}</div>
                                                    </div>
                                                    : null
                                                }
                                                
                                                <hr style={{width:"80%",marginLeft:"7%",marginTop:"10px",marginBottom:"0px"}}/>
                                                <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"5px"}}>
                                                    <div className="txTotal" style={{marginTop:"10px"}}>TOTAL</div>
                                                    <div className="total">${this.state.total.toFixed(2)}</div>
                                                </div>

                                            </Row>
                                            {
                                                Constants.instance.onlyCatalog() == false ?
                                                    <Button onClick={(e) => this.agregarProducto(e)} style={{width:"90%"}} className="btn-agregar btnContinuarCrearCuenta">AGREGAR A CARRITO</Button>
                                                : null
                                            }

                                            </div>
                                        </div>


                                        </div>
                                    : null
                                }
                                
                            </div>
                        </Col>

                        <Col xs={5}></Col>
                        <Col xs={4}>
                                     
                            <div className="nutricionalInfo">
                                <div className="imagenesIlustrativa" style={{marginBottom:"20px"}}>* imágenes con fines ilustrativos</div>    

                                <span className="valores">VALORES NUTRICIONALES POR CADA 100 GRAMOS DE YOGURT HELADO</span>
                                <div className="clear"></div>
                                <Row>
                                {typeof this.state.dataProducto.custom_data.nutricional_info.cal !== 'undefined' ? <Col className="itemValorNutricional" xs={4}>Calorías: {this.state.dataProducto.custom_data.nutricional_info.cal}</Col> : null}
                                {typeof this.state.dataProducto.custom_data.nutricional_info.pro !== 'undefined' ? <Col className="itemValorNutricional" xs={4}>Proteínas - {this.state.dataProducto.custom_data.nutricional_info.pro}</Col> : null}
                                {typeof this.state.dataProducto.custom_data.nutricional_info.fat !== 'undefined' ? <Col className="itemValorNutricional" xs={4}>Grasas - {this.state.dataProducto.custom_data.nutricional_info.fat}</Col> : null}
                                {typeof this.state.dataProducto.custom_data.nutricional_info.clc !== 'undefined' ? <Col className="itemValorNutricional" xs={4}>Calcio - {this.state.dataProducto.custom_data.nutricional_info.clc}</Col> : null}
                                {typeof this.state.dataProducto.custom_data.nutricional_info.sal !== 'undefined' ? <Col className="itemValorNutricional" xs={4}>Sal - {this.state.dataProducto.custom_data.nutricional_info.sal}</Col> : null}
                                {typeof this.state.dataProducto.custom_data.nutricional_info.ftrans !== 'undefined' ? <Col className="itemValorNutricional" xs={4}>Grasas trans - {this.state.dataProducto.custom_data.nutricional_info.ftrans}</Col> : null}
                                {typeof this.state.dataProducto.custom_data.nutricional_info.azu !== 'undefined' ? <Col   className="itemValorNutricional"xs={4}>Azúcar - {this.state.dataProducto.custom_data.nutricional_info.azu}</Col> : null}
                                </Row>
                                <div className="clear"></div>


                            </div>

                        </Col>
                        <Col xs={3}></Col>

                        

                        
                    </Row>
                    
                    
                </Row>

                




                
            }


            <div style={{display:"none"}}>
                <Modal
                    closeTimeoutMS={500}
                    isOpen={this.state.setIsOpen}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <img onClick={this.closeModal} src="/images/cross.png" className="closeModal" />
                    <div className='productoAgregado'>Producto Agregado</div>
                    <div className='nombreProductoAgregado'>{this.state.nameProductoAgregado}</div>

                    <Row>
                        <Col xs={6}>
                            <Button style={{width:"100%"}} onClick={(e) => this.confirmarOrden(e) }  className="btn-agregar btnContinuarCrearCuenta">CONFIRMAR ORDEN</Button>
                        </Col>
                        <Col xs={6}>
                            <Button style={{width:"100%"}} onClick={(e) => this.setState({setIsOpen:false})}  className="btn-agregar btnContinuarCrearCuenta">CONTINUAR COMPRANDO</Button>
                        </Col>
                            
                    </Row>


                </Modal>
            </div>

            <Modal
                    closeTimeoutMS={500}
                    isOpen={this.state.setIsOpenValidacion}
                    style={customStyles}
                    contentLabel=""
                >
                    <img onClick={(e) => this.cerrarModalValidacion() } src="/images/cross.png" className="closeModal" />
                    <div className='productoAgregado'>{this.state.dataProducto.name_online}</div>
                    <div className='nombreProductoAgregado'>{this.state.mensajeAlerta}</div>
                    <Button onClick={(e) => this.cerrarModalValidacion() }  className="btn-agregar btnContinuarCrearCuenta">Aceptar</Button>

                </Modal>

        </Row>
      );
    }
}

export default DetalleProducto;