import React,{ useState,useEffect }  from 'react';
import { Row,Col,Button} from "react-bootstrap";
import Modal from 'react-modal';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { withRouter } from "react-router";
import 'react-tabs/style/react-tabs.css';
import Animaciones from '../../libs/animaciones'
import $ from 'jquery'; 
import SwipeableViews from 'react-swipeable-views';
import Moment from 'moment';
import 'moment/min/locales'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Constants from '../../libs/constants'


const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 0,
      width: "320px",
      bottom: 'auto',
      transform: 'translate(-50%,-50%)',
      //transform: 'translate(0%, -50%)',
    },
  };
  //const theme = useTheme();


class DetallePromocionMovil extends React.Component {
    
    state = {
        dataProducto:{},
        loadData:false,
        toppings: [],
        precio: 0.00,
        total: 0.00,
        categoryId: 0,
        cantidad: 1,
        toppingExtra:0,
        setIsOpenValidacion:false,
        subTotal:0,
        toppingsFree: 2,
        data:{},
        mensajeAlerta:"",
        noBaseImgMessage : '',
        noSauceImgMessage :'',
        noToppingImgMessage : '',
        totalToppingLimitMsg : 'Máximo 10 ingredientes por lado.',
        notRemovableMsg : 'Sólo 2 ingredientes de la especialidad pueden quitarse.',
        crustStepImage : 'icon-tossing-green.png',
        crustStepImageActive : 'icon-tossing-white.png',
        sauceStepImage : 'icon-sauce-green.png',
        sauceStepImageActive : 'icon-sauce-white.png',
        toppingStepImage : 'icon-pizza-green.png',
        toppingStepImageActive : 'icon-pizza-white.png',
        numberOfConfiguredToppings : 3,
        thisFrozen : {
            "size": "1",
            "style": "1",
            "toppings": []
        },
        setIsOpen:false,
        nameProductoAgregado:"",
        selectedIndex:0,
        selectedIndexDos:0,
        positionTabs:0,
        visibleDelivery:false,
        productoUno:{name:"tarrina 1",nameShow:"tarrina 1",toppings:[]},
        productoDos:{name:"tarrina 2",nameShow:"tarrina 2",toppings:[]},
        toppingsProductoUno:[],
        toppingsProductoDos:[],
        toppingsSegundo:[],
        value:0,
        totalNeto:0,
        netoPrecio:0,
    };


    handleSelectTabs = index => {
        this.setState({ selectedIndex: index });
    };

    handleSelectTabsDos = index => {
        this.setState({ selectedIndexDos: index });
    };
    

    async seleccionarProducto(e,tipo,item){
        if(tipo == "productoUno"){
            var dataProductoDosSizes = []
            var dataProducto = this.state.dataProducto
            var dataProductoDos = this.state.dataProductoDos

            console.log("aqui ===",this.state.toppingsSegundo)
            

            for (var i = 0; i < this.state.toppingsSegundo.length; i+=1) {
                const itemSize = this.state.toppingsSegundo[i]
                if(item.id >= itemSize.id ){
                    var itemSave = {}
                    itemSave.id = itemSize.id
                    itemSave.desc = itemSize.desc
                    itemSave.image = itemSize.image
                    itemSave.base_image = itemSize.base_image
                    itemSave.price = itemSize.price
                    itemSave.topping_free = itemSize.topping_free
                    itemSave.name = itemSize.name
                    
                    dataProductoDosSizes.push(itemSave)
                }
            }



            //buscar si se encuentra el tamanio actual de la segunda tarrina
            var itemSegundaTarrina = dataProductoDosSizes.find((item) => item.id == this.state.productoDos.id)
            console.log("itemSegundaTarrina",itemSegundaTarrina)
            if(typeof itemSegundaTarrina === 'undefined'){
                //si no encontramos el tamanio lo reseteamos
                this.setState({productoDos:{name:"tarrina 2",nameShow:"tarrina 2",toppings:[],base_image:""},selectedIndexDos:0})
                $("#frozenToppingsDos").html("")

            }
            dataProductoDos.sizes = dataProductoDosSizes
            var producto = item
            producto.precioExtra = 0
            producto.nameShow = "tarrina 1 ("+producto.name+") "

            if(this.state.productoUno.toppings.length == 0){
                var arrayToppings = []
                for (var i = 0; i < producto.topping_free; i++) {
                    arrayToppings.push({
                        id: "",
                        name: ""
                    })
                }
                producto.toppings = arrayToppings
            }else{
                //validar precio
                var arrayToppings = this.state.productoUno.toppings.filter((item) => item.id != "")
                var total = parseFloat(producto.price)
                var precioExtras = 0
                var toppingsFree = producto.topping_free

                //agregar topings faltantes
                var arrayToppingsAll = arrayToppings.filter((item) => item.price != 0)
                if(arrayToppingsAll.length < toppingsFree){
                    for (var i = 0; i < (toppingsFree - arrayToppingsAll.length); i++) {
                        arrayToppings.push({
                            id: "",
                            name: ""
                        })
                    } 
                }



                var toppAll = arrayToppings.filter((item) => item.price != 0)
                if(toppAll.length > toppingsFree){
                    for (var i = 0; i < (toppAll.length - toppingsFree); i++) {
                        total = total + toppAll[i].price
                        precioExtras  = precioExtras + toppAll[i].price
                    } 
                }
                
                total = total * 1
                producto.precioExtra = precioExtras
                producto.toppings = arrayToppings

            }

            producto.price = parseFloat(producto.price)
           

            

            //this.setState({productoUno:producto})
            this.setState({productoUno:producto,dataProductoDos:dataProductoDos},()=>{
                this.calcularTotalneto()
            })
        }else if(tipo == "productoDos"){

            var producto = item
            producto.precioExtra = 0
            producto.nameShow = "tarrina 2 ("+producto.name+")"

            if(this.state.productoDos.toppings.length == 0){
                var arrayToppings = []
                for (var i = 0; i < producto.topping_free; i++) {
                    arrayToppings.push({
                        id: "",
                        name: ""
                    })
                }
                producto.toppings = arrayToppings
            }else{
                //validar precio
                var arrayToppings = this.state.productoDos.toppings.filter((item) => item.id != "")
                var total = parseFloat(producto.price)
                var precioExtras = 0
                var toppingsFree = producto.topping_free


                //agregar topings faltantes
                var arrayToppingsAll = arrayToppings.filter((item) => item.price != 0)
                if(arrayToppingsAll.length < toppingsFree){
                    for (var i = 0; i < (toppingsFree - arrayToppingsAll.length); i++) {
                        arrayToppings.push({
                            id: "",
                            name: ""
                        })
                    } 
                }

                

                var toppAll = arrayToppings.filter((item) => item.price != 0)
                if(toppAll.length > toppingsFree){
                    for (var i = 0; i < (toppAll.length - toppingsFree); i++) {
                        total = total + toppAll[i].price
                        precioExtras  = precioExtras + toppAll[i].price
                    } 
                }

                
                
                total = total * 1
                producto.precioExtra = precioExtras
                producto.toppings = arrayToppings
            }

            producto.price = parseFloat(producto.price)

            console.log("producto",producto)
            
            this.setState({productoDos:producto},()=>{
                this.calcularTotalneto()
            })
        }
    }
    
    async agregarToppingLocalPromo(e,itemChildren,tipoProducto,div){
        //toppingsProductoDos
        var toppingsSelect = {}
        var categoryId = ""
        var data = ""
        var price = 0
        var toppingsFree = 0
        var custom_type = ""
        var id = ""

        if(tipoProducto == "toppingsProductoUno"){

            if(this.state.productoUno.name == "tarrina 1"){
                this.setState({setIsOpenValidacion:true,mensajeAlerta:"Selecciona la tarrina 1",value:0})
                this.setState({selectedIndex:0})
                window.scrollTo(0, 0);
                return
            }

            //for (var i = 0; i < this.state.dataProducto.custom_data.toppings.length; i+=1) {}
            toppingsSelect = this.state.productoUno.toppings
            categoryId = this.state.dataProducto.categoryId
            data = this.state.data
            price = this.state.productoUno.price
            toppingsFree = this.state.productoUno.topping_free
            custom_type = this.state.dataProducto.custom_type
            id = this.state.dataProducto.id
        }else{

            if(this.state.productoDos.name == "tarrina 2"){
                this.setState({setIsOpenValidacion:true,mensajeAlerta:"Selecciona la tarrina 2"})
                this.setState({selectedIndexDos:0,value:1})
                window.scrollTo(0, 0);
                return
            }

            toppingsSelect = this.state.productoDos.toppings
            categoryId = this.state.dataProductoDos.categoryId
            data = this.state.dataDos
            price = this.state.productoDos.price
            toppingsFree = this.state.productoDos.topping_free
            custom_type = this.state.dataProductoDos.custom_type
            id = this.state.dataProductoDos.id
        }
        
        const resultado = await Animaciones.instance.agregarTopping(
            itemChildren,
            categoryId,
            data,
            this.state.thisFrozen,
            price,
            this.state.cantidad,
            toppingsFree,
            toppingsSelect,
            custom_type,
            id,
            div
            );

        console.log("resultado",resultado)
        

        //dataProductoDos
        if(typeof resultado.error === 'undefined'){
            if(tipoProducto == "toppingsProductoUno"){
                var topping = this.state.productoUno
                topping.toppings = resultado.toppings
                topping.precioExtra = resultado.toppingExtra
                topping.total = resultado.total


                this.setState({productoUno:topping},()=>{
                    this.calcularTotalneto()
                })
            }else{
                var topping = this.state.productoDos
                topping.toppings = resultado.toppings
                topping.precioExtra = resultado.toppingExtra
                topping.total = resultado.total

                this.setState({productoDos:topping},()=>{
                    this.calcularTotalneto()
                })
            }


            this.setState(resultado)
            
        }else{
            if(resultado.mensaje != ""){
                this.setState({setIsOpenValidacion:true,mensajeAlerta:resultado.mensaje,positionTabs:resultado.position})
            }
        }


            

    }


    async calcularTotalneto(){
        var total = 0 
        if(typeof this.state.productoUno.price !== 'undefined'){
            total = total + parseFloat(this.state.productoUno.price)
        }
        //agregar extras
        if(typeof this.state.productoUno.precioExtra !== 'undefined'){
            total = total + this.state.productoUno.precioExtra
        }

        if(typeof this.state.productoDos.price !== 'undefined'){
            total = total + parseFloat(this.state.productoDos.price)
        }

        //agregar extras
        if(typeof this.state.productoDos.precioExtra !== 'undefined'){
            total = total + this.state.productoDos.precioExtra
        }

        console.log("total",total)

        this.setState({totalNeto: total*this.state.cantidad,totalShow:total*this.state.cantidad,netoPrecio:total})
    }

    cerrarModalValidacion(){
        this.setState({setIsOpenValidacion:false})

        if(this.state.positionTabs != -1){
            setTimeout(() => {
                this.setState({selectedIndex:this.state.positionTabs})
            }, 500);
        }
    }

    async removerToppingLocal(e,index,tipoProducto,div){

        var toppingsSelect = {}
        var categoryId = ""
        var data = ""
        var price = 0
        var toppingsFree = 0
        var custom_type = ""
        var id = ""

        if(tipoProducto == "toppingsProductoUno"){

            //for (var i = 0; i < this.state.dataProducto.custom_data.toppings.length; i+=1) {}
            toppingsSelect = this.state.productoUno.toppings
            categoryId = this.state.dataProducto.categoryId
            data = this.state.data
            price = this.state.productoUno.price
            toppingsFree = this.state.productoUno.topping_free
            custom_type = this.state.dataProducto.custom_type
            id = this.state.dataProducto.id
        }else{
            toppingsSelect = this.state.productoDos.toppings
            categoryId = this.state.dataProductoDos.categoryId
            data = this.state.dataDos
            price = this.state.productoDos.price
            toppingsFree = this.state.productoDos.topping_free
            custom_type = this.state.dataProductoDos.custom_type
            id = this.state.dataProductoDos.id
        }


        const resultado =  await Animaciones.instance.removerTopping(
            index,
            toppingsSelect,
            toppingsFree,
            this.state.cantidad,
            price,
            id,
            categoryId,
            undefined
            )

        this.setState(resultado)

        if(tipoProducto == "toppingsProductoUno"){
            var topping = this.state.productoUno
            topping.toppings = resultado.toppings
            topping.precioExtra = resultado.toppingExtra
            topping.total = resultado.total


            this.setState({productoUno:topping},()=>{
                this.calcularTotalneto()
            })
        }else{
            var topping = this.state.productoDos
            topping.toppings = resultado.toppings
            topping.precioExtra = resultado.toppingExtra
            topping.total = resultado.total

            this.setState({productoDos:topping},()=>{
                this.calcularTotalneto()
            })
        }

    }

    async cantidadLocal(e,accion){
        var cantidad = this.state.cantidad

        if(accion){
            cantidad = cantidad + 1
        }else{
            if(cantidad != 1){
                cantidad = cantidad - 1
            }
        }

        this.setState({cantidad:cantidad},()=>{
            this.calcularTotalneto()
        })
    }

    confirmarOrden(e){
        this.setState({setIsOpen:false})

        var  shadow_layer = $("#cd-shadow-layer"),
        lateral_cart = $("#cd-cart"),
        menu_navigation = $(".top-nav-list")

        menu_navigation.removeClass("speed-in")


        if(lateral_cart.hasClass("speed-in")){
            lateral_cart.removeClass("speed-in").one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                //$("body").removeClass("overflow-hidden")
            })
            shadow_layer.removeClass("is-visible")
            }else{
            lateral_cart.addClass("speed-in").one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                //$("body").addClass("overflow-hidden")
            })
            shadow_layer.addClass("is-visible")
        }
    }

    async agregarProducto(e){
        
        var toppingsProductoUno = this.state.productoUno.toppings.filter((item) => item.id == "").length
        var toppingsProductoDos = this.state.productoDos.toppings.filter((item) => item.id == "").length
        var toppingsValidos = toppingsProductoUno + toppingsProductoDos

        if(this.state.productoUno.toppings.length == 0){
            this.setState({setIsOpenValidacion:true,mensajeAlerta:"Selecciona la tarrina 1",value:0})
            window.scrollTo(0, 0);
            return
        }else if(this.state.productoDos.toppings.length == 0){
            this.setState({setIsOpenValidacion:true,mensajeAlerta:"Selecciona la tarrina 2"})
            this.setState({selectedIndexDos:0,value:1})
            window.scrollTo(0, 0);
            return
        }else if(toppingsValidos > 0){
            this.setState({setIsOpenValidacion:true,mensajeAlerta:"Ingrese todos los toppings incluidos"})
            return
        }else{

            for (var i = 0; i < this.state.dataProducto.custom_data.toppings.length; i+=1) {
                let itemToppingValidacion = this.state.dataProducto.custom_data.toppings[i]
                let itemValidacion = this.state.dataProducto.custom_data.req_topping_free_by_cats[itemToppingValidacion.id]

                if(typeof itemValidacion != 'undefined'){
                    if(itemValidacion != 0){
                        var findToppings = this.state.toppings.filter((item) => item.categoryId == itemToppingValidacion.id).length
                        if(itemValidacion > findToppings){
                            var nameCategoria = this.state.dataProducto.custom_data.toppings.find((item) => item.id == itemToppingValidacion.id)
                            this.setState({setIsOpenValidacion:true,mensajeAlerta:"Debe de seleccionar al menos "+itemValidacion+" topping(s) de "+nameCategoria.name})
                            return
                        }
                    }
                }

            }
        }


        
        var dataProductoSave = {}
        dataProductoSave.promo = true
        dataProductoSave.name =  this.state.dataMain.promo_name
        dataProductoSave.promo_id = this.state.dataMain.promo_id
        dataProductoSave.quantity = this.state.cantidad
        dataProductoSave.total = this.state.totalNeto


        //producto 1
        dataProductoSave.productoUno = {}
        dataProductoSave.productoUno.productId = this.state.productoUno.id
        dataProductoSave.productoUno.precio_unitario = this.state.productoUno.price
        dataProductoSave.productoUno.currency = this.state.dataProducto.currency
        dataProductoSave.productoUno.base_image = this.state.dataProducto.small_image
        dataProductoSave.productoUno.desc = this.state.productoUno.desc
        dataProductoSave.productoUno.small_desc = this.state.productoUno.desc
        dataProductoSave.productoUno.name = this.state.productoUno.nameShow
        dataProductoSave.productoUno.toppingsFree = this.state.productoUno.topping_free

        var customProductoUno = []
        

        for (var i = 0; i < this.state.productoUno.toppings.length; i+=1) {
            let item = this.state.productoUno.toppings[i]

            var dataToppings = {}
            dataToppings.toppingId = item.id
            dataToppings.name = item.categoryId == 9 ? "Salsa "+item.name : item.name
            dataToppings.categoryId = item.categoryId
            dataToppings.quantity = 1
            dataToppings.price = item.price
            customProductoUno.push(dataToppings)
        }

        var toppingsProductoUnoGratis = customProductoUno.filter((item) => item.price == 0)
        var toppingsProductoUnoNoGratis = customProductoUno.filter((item) => item.price !== 0)

        var customProductoUnoFinal = []

        customProductoUnoFinal.push.apply(customProductoUnoFinal, toppingsProductoUnoNoGratis);
        customProductoUnoFinal.push.apply(customProductoUnoFinal, toppingsProductoUnoGratis);

        dataProductoSave.productoUno.custom = customProductoUnoFinal

        //producto 2
        dataProductoSave.productoDos = {}
        dataProductoSave.productoDos.productId = this.state.productoDos.id
        dataProductoSave.productoDos.precio_unitario = this.state.productoDos.price
        dataProductoSave.productoDos.currency = this.state.dataProducto.currency
        dataProductoSave.productoDos.base_image = this.state.dataProducto.small_image
        dataProductoSave.productoDos.desc = this.state.productoDos.desc
        dataProductoSave.productoDos.small_desc = this.state.productoDos.desc
        dataProductoSave.productoDos.name = this.state.productoDos.nameShow
        dataProductoSave.productoDos.toppingsFree = this.state.productoDos.topping_free

        var customProductoDos = []
        

        for (var i = 0; i < this.state.productoDos.toppings.length; i+=1) {
            let item = this.state.productoDos.toppings[i]

            var dataToppings = {}
            dataToppings.toppingId = item.id
            dataToppings.name = item.categoryId == 9 ? "Salsa "+item.name : item.name
            dataToppings.categoryId = item.categoryId
            dataToppings.quantity = 1
            dataToppings.price = item.price
            customProductoDos.push(dataToppings)
        }

        var toppingsProductoDosGratis = customProductoDos.filter((item) => item.price == 0)
        var toppingsProductoDosNoGratis = customProductoDos.filter((item) => item.price !== 0)

        var customProductoDosFinal = []

        customProductoDosFinal.push.apply(customProductoDosFinal, toppingsProductoDosNoGratis);
        customProductoDosFinal.push.apply(customProductoDosFinal, toppingsProductoDosGratis);

        dataProductoSave.productoDos.custom = customProductoDosFinal


        dataProductoSave.keyProduct =  `${dataProductoSave.promo_id}${(Math.floor(Math.random() * 100) + 1)}`
        dataProductoSave.orden = parseFloat(Moment().format("DDMMYYYYHHmmss"))
        console.log("dataProductoSave",dataProductoSave)
        //return
        const key = `carrito-${dataProductoSave.keyProduct}`
        var carrito = JSON.stringify(dataProductoSave)

        localStorage.setItem(key,carrito)

        this.props.actualizarCarrito()


        this.setState({setIsOpen:true,nameProductoAgregado:this.state.dataMain.promo_name})

        
    }
    


    closeModal() {
        this.setState({setIsOpen:!this.state.setIsOpen})
    }

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
    } 

    async componentDidMount() {
        //console.log("aquiii",this.props.match.params)
        //var id = this.props.match.params.id;
        console.log("idBuscar",this.props.theme)

        if(this.props.data.active ==  false){
            window.location = "/";
            return
        }

        this.setState({ 
            dataMain: this.props.data,
            dataProducto: this.props.data.promo_config.prod_1,
            categoryId: this.props.data.promo_config.prod_1.categoryId,
            data:this.props.data.promo_config.prod_1.custom_config,
            dataProductoDos: this.props.data.promo_config.prod_2,
            categoryIdDos: this.props.data.promo_config.prod_2.categoryId,
            dataDos:this.props.data.promo_config.prod_2.custom_config,
            toppingsSegundo: this.props.data.promo_config.prod_2.sizes
            }, () => {
            this.setState({loadData:true})
            var arrayToppings = []
            for (var i = 0; i < this.state.dataProducto.custom_data.toppings_free; i++) {
                arrayToppings.push({
                    id: "",
                    name: ""
                })
            }
            this.setState({toppings: arrayToppings})

        });

       
    }

    handleChange(index) {
        console.log("valor",index)
        this.setState({value:index})
    }

    async handleChangeIndex(index) {
        this.setState({value:index})
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
      return (
        <Row className="nopadding">
            {
                this.state.loadData == false ?
                    null
                :
                <Row className="nopadding">
                    <Row className="contNameProducto">
                        <div className="nameProducto">
                            <span className='textPromocionMovil'>PROMOCIÓN</span>
                            <span className="titleCant1Movil" style={{marginTop:"0px"}}>{this.state.dataMain.promo_name}</span>
                            <span className="descripcionPromo" dangerouslySetInnerHTML={{__html: this.state.dataMain.promo_desc}}></span>

                        </div>
                    </Row>
                    <Row className="nopadding">
                       

                        <Col xs={12} className="nopadding">
                            <Tabs className="tabsPromociones" selectedIndex={this.state.value} onSelect={this.handleChange}>
                                <TabList>
                                    <Tab tabFor={100}>tarrina 1</Tab>
                                    <Tab tabFor={101}>tarrina 2</Tab>
                                </TabList>
                                <SwipeableViews
                                    disabled={true}
                                    axis={this.props.theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={this.state.value}
                                    disableDragging={'left'|'right'|true|false}
                                    onChangeIndex={this.handleChangeIndex}
                                >
                                    <div index={0} dir={this.props.theme.direction} tabId={100}>
                                        <Row className="nopadding">

                                            <Col xs={12} style={{minHeight:"auto"}} className={this.state.categoryId == 1 ? "contToppings tarrinaPanel" : this.state.categoryId == 4 ? "contToppings sensacionesPanel"  :"contToppings" }>
                                                

                                                <Tabs className="tabsPromoMovil" selectedIndex={this.state.selectedIndex} onSelect={this.handleSelectTabs}>
                                                    <TabList>
                                                        <Tab tabFor={10000}>Tamaño</Tab>
                                                        {this.state.dataProducto.custom_data.toppings.map((item, index) => (

                                                            <Tab tabFor={index}>{item.name}</Tab>
                                                        ))}
                                                    </TabList>

                                                    <TabPanel tabId={10000}>
                                                        <Row className="rowRegistro">
                                                            <Row className='contTarrinasPromo' style={{marginTop:"0px",marginLeft:"0px"}}>
                                                                {this.state.dataProducto.sizes.map((item, index)  => (
                                                                    <Col xs={3} style={{position:"relative"}}  onClick={(e) => this.seleccionarProducto(e,"productoUno",item)} className={this.state.productoUno.id == item.id ? "itemTarrinaPromo itemSelect" : "itemTarrinaPromo"}>
                                                                        <img src={item.image} className="imgCant2" style={{marginBottom:"5px",width:"85%",marginTop:"0px"}} />
                                                                        <span className="sizeCat2">{item.name}</span>
                                                                        
                                                                    
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </Row>
                                                    </TabPanel>

                                                    {this.state.dataProducto.custom_data.toppings.map((item, index) => (
                                                        <TabPanel tabId={index}>
                                                            <div className="rowRegistro" style={{marginTop:"10px",zIndex:999999}}>

                                                                {item.items.map(itemChildren => (

                                                                    
                                                                    itemChildren.id != 35 ?
                                                                        <Col xs={3} className="contMainItem" onClick={(e) => this.agregarToppingLocalPromo(e,itemChildren,"toppingsProductoUno","#frozenToppingsUno")}>
                                                                            <div className="circleTopping" style={{marginTop:"0px"}}>
                                                                                <div className="contentTopping">
                                                                                    <div className="masMenos">+</div>
                                                                                    <img className="imgTopping" src={itemChildren.icon_image} alt={itemChildren.name} />
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            <div className="nameToppingD">{itemChildren.name}</div>
                                                                        </Col>

                                                                    :
                                                                    null
                                                                    
                                                                    

                                                                    
                                                                ))}


                                                                {item.items.filter((item) => item.id == 35).map(itemChildren => (

                                                                    <Col xs={3} className="contMainItem contMascotaMovil" onClick={(e) => this.agregarToppingLocalPromo(e,itemChildren,"toppingsProductoUno","#frozenToppingsUno")}>
                                                                        <div className="circleTopping" style={{marginTop:"0px"}}>
                                                                            <div className="contentTopping">
                                                                                <div className="masMenos">+</div>
                                                                                <img className="imgTopping" src={itemChildren.icon_image} alt={itemChildren.name} />
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <div className="nameToppingD" style={{paddingBottom:"15px"}}>{itemChildren.name}</div>
                                                                        <div className="titleMascotaMovil">Mascotas</div>
                                                                    </Col>

                                                                ))}

                                                                

                                                            </div>
                                                            
                                                        </TabPanel>
                                                    ))}
                                                </Tabs>
                                            </Col>

                                            <Row style={{backgroundColor:"#F3F3F3",padding:"10px 5px 0px 5px",margin:"10px 0px 0px 0px"}}>
                                                <Col xs={5} className="contImageMain">
                                                    <div className="contInfoImageMain">
                                                        <div style={this.state.dataProducto.categoryId == 4 ? {marginTop:"70px"}: {}}>
                                                            <div className="tarrina cssanimations">
                                                                <div className="frozen-image">
                                                                    <div className="frozen-toppings" id="frozenToppingsUno"></div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.productoUno.base_image != '' ?
                                                                    <img className="imgToppingMain" style={{marginLeft:"-2%"}} src={this.state.productoUno.base_image} />
                                                                : null
                                                            }
                                                        </div>

                                                    </div>
                                                </Col>
                                                <Col xs={7}>
                                                    <div className="contCarritoRight movilcontCarritoRight">
                                                        {
                                                            this.state.visibleDelivery ?
                                                            <div className='contPrecioDelivery'>
                                                                <img className="moto" src="/images/moto.png" alt="llaollao" />
                                                                <div className='contTextoPromoDelivery'>
                                                                    <div className='tituloPromoDelivery'>delivery a $0.99</div>
                                                                    <div className='descripcionPromoDelivery'>Promoción del día</div>
                                                                </div>
                                                            </div>
                                                            : null
                                                        }
                                                    
                                                        <div className="contDetalleCarrito">
                                                            <span className="titleCant1" style={{marginTop:"30px"}}>{this.state.productoUno.nameShow}
                                                            {
                                                                this.state.productoUno.name != "tarrina 1" ?
                                                                    "\n\n"+this.state.dataProducto.currency + ""  + this.state.productoUno.price  + " (c/u)"
                                                                : null
                                                            }
                                                            </span>


                                                            {this.state.productoUno.toppings.map((item, index) => (
                                                                <div className="itemToppingSelect">
                                                                    <div className="itemToppingTitle">
                                                                        <span className="punto"></span>
                                                                        {
                                                                            Constants.instance.onlyCatalog == false ?
                                                                            <span className="numeroTopping">topping {index+1} {(parseInt(this.state.productoUno.topping_free)) > index ? "":"extra ($"+parseFloat(item.price).toFixed(2)+")"}</span>
                                                                            :
                                                                            <span className="numeroTopping">topping {index+1} {(parseInt(this.state.productoUno.topping_free)) > index ? "":""}</span>
                                                                        }
                                                                    </div>
                                                                    <div className="itemToppingTitle">
                                                                        {
                                                                            item.name != "" ?
                                                                            <div><span className={item.price == 0 ? "seleccionar gratis" : "seleccionar"}>{item.categoryId == 9 ? "Salsa ":""} {item.name} {item.price == 0 ? "(Gratis)": null}</span>
                                                                            <img onClick={(e) => this.removerToppingLocal(e,index,"toppingsProductoUno","#frozenToppingsUno")} className="removeTopping" src="/images/icon_cerrar.png" /></div>
                                                                            :
                                                                            <span className="sinSeleccionar">Sin seleccionar</span>
                                                                        }
                                                                        
                                                                    </div>                                      
                                                                </div>
                                                            ))}

                                                            {
                                                                typeof this.state.productoUno.price !== 'undefined' ?
                                                                <div>
                                                                    
                                                                    {
                                                                        this.state.productoUno.precioExtra != 0 ?
                                                                        <div>
                                                                            <div style={{display:"flex",paddingLeft:"20px"}}>
                                                                                <div className="txTotal">Precio</div>
                                                                                <div className="subTotalCarrito" style={{textAlign:"right"}}>{this.state.dataProducto.currency}{this.state.productoUno.price.toFixed(2)}</div>
                                                                            </div>
                                                                            <div style={{display:"flex",paddingLeft:"20px",marginTop:"5px"}}>
                                                                                <div className="txTotal">Extras</div>
                                                                                <div className="subTotalCarrito" style={{textAlign:"right"}}>{this.state.dataProducto.currency}{this.state.productoUno.precioExtra.toFixed(2)}</div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        : null
                                                                    }
                                                                

                                                                    <div style={{display:"flex",paddingLeft:"20px"}}>
                                                                        <div className="txTotal">Precio unitario</div>
                                                                        <div className="subTotalCarrito" style={{textAlign:"right"}}>{this.state.dataProducto.currency}{parseFloat(this.state.productoUno.precioExtra + this.state.productoUno.price).toFixed(2)}</div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }

                                                            <span className="titleCant1" style={{marginTop:"30px"}}>{this.state.productoDos.nameShow}
                                                            {
                                                                this.state.productoDos.name != "tarrina 2" ?
                                                                    "\n\n"+this.state.dataProducto.currency + ""  + this.state.productoDos.price  + " (c/u)"
                                                                : null
                                                            }</span>
                                                            {this.state.productoDos.toppings.map((item, index) => (
                                                                <div className="itemToppingSelect">
                                                                    <div className="itemToppingTitle">
                                                                        <span className="punto"></span>
                                                                        {
                                                                            Constants.instance.onlyCatalog == false ?
                                                                            <span className="numeroTopping">topping {index+1} {(parseInt(this.state.productoDos.topping_free)) > index ? "":"extra ($"+parseFloat(item.price).toFixed(2)+")"}</span>
                                                                            :
                                                                            <span className="numeroTopping">topping {index+1} {(parseInt(this.state.productoDos.topping_free)) > index ? "":""}</span>
                                                                        }
                                                                    </div>
                                                                    <div className="itemToppingTitle">
                                                                        {
                                                                            item.name != "" ?
                                                                            <div><span className={item.price == 0 ? "seleccionar gratis" : "seleccionar"}>{item.categoryId == 9 ? "Salsa ":""} {item.name} {item.price == 0 ? "(Gratis)": null}</span>
                                                                            <img onClick={(e) => this.removerToppingLocal(e,index,"toppingsProductoDos","#frozenToppingsDos")} className="removeTopping" src="/images/icon_cerrar.png" /></div>
                                                                            :
                                                                            <span className="sinSeleccionar">Sin seleccionar</span>
                                                                        }
                                                                        
                                                                    </div>                                      
                                                                </div>
                                                            ))}

                                                            {
                                                                typeof this.state.productoDos.price !== 'undefined' ?
                                                                <div>
                                                                    
                                                                    {
                                                                        this.state.productoDos.precioExtra != 0 ?
                                                                        <div>
                                                                            <div style={{display:"flex",paddingLeft:"20px"}}>
                                                                                <div className="txTotal">Precio</div>
                                                                                <div className="subTotalCarrito" style={{textAlign:"right"}}>{this.state.dataProducto.currency}{this.state.productoDos.price.toFixed(2)}</div>
                                                                            </div>
                                                                            <div style={{display:"flex",paddingLeft:"20px",marginTop:"5px"}}>
                                                                                <div className="txTotal">Extras</div>
                                                                                <div className="subTotalCarrito" style={{textAlign:"right"}}>{this.state.dataProducto.currency}{this.state.productoDos.precioExtra.toFixed(2)}</div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        : null
                                                                    }
                                                                

                                                                    <div style={{display:"flex",paddingLeft:"20px"}}>
                                                                        <div className="txTotal">Precio unitario</div>
                                                                        <div className="subTotalCarrito" style={{textAlign:"right"}}>{this.state.dataProducto.currency}{parseFloat(this.state.productoDos.precioExtra + this.state.productoDos.price).toFixed(2)}</div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }

                                                        

                                                            

                                                        </div>
                                                        <div className="contAddCarrito" style={{height:"180px"}}>
                                                            <div className="contToppingExtra">
                                                                <div className="contToppingExtraBorder">
                                                                    <span className="cantidadToppingExtra"></span>
                                                                </div>
                                                            </div>

                                                            <div className="contAgregar">
                                                                <div className="contAgregarBorder">
                                                                <Row>
                                                                    {
                                                                        Constants.instance.onlyCatalog() == false ?
                                                                            <div className='d-flex justify-content-center'>
                                                                                    <div className="modificarCantidad"  onClick={(e) => this.cantidadLocal(e,false)}>-</div>
                                                                                    <div className="cantidad">{this.state.cantidad}</div>
                                                                                    <div className="modificarCantidad"  onClick={(e) => this.cantidadLocal(e,true)}>+</div>

                                                                            </div>
                                                                        : null
                                                                    }
                                                                    <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"15px"}}>
                                                                        <div className="txTotal">Precio</div>
                                                                        <div className="subTotal">${this.state.netoPrecio.toFixed(2)}</div>
                                                                    </div>
                                                                    
                                                                    <hr style={{width:"80%",marginLeft:"7%",marginTop:"10px",marginBottom:"0px"}}/>
                                                                    <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"5px"}}>
                                                                        <div className="txTotal" style={{marginTop:"10px"}}>TOTAL</div>
                                                                        <div className="total">${this.state.totalNeto.toFixed(2)}</div>
                                                                    </div>

                                                                </Row>

                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            

                                        </Row>
                                        

                                    </div>
                                    {/* comienzo tarrina dos */}
                                    <div index={1} dir={this.props.theme.direction} tabId={101}>
                                        <Row className="nopadding">

                                            <Col xs={12} style={{minHeight:"auto"}} className={this.state.categoryId == 1 ? "contToppings tarrinaPanel" : this.state.categoryId == 4 ? "contToppings sensacionesPanel"  :"contToppings" }>
                                                <Tabs className="tabsPromoMovil" selectedIndex={this.state.selectedIndexDos} onSelect={this.handleSelectTabsDos}>
                                                    <TabList className="contToppingTabs react-tabs__tab-list">
                                                        <Tab tabFor={100010}>Tamaño</Tab>
                                                        {this.state.dataProductoDos.custom_data.toppings.map((item, index) => (

                                                            <Tab tabFor={index+100010}>{item.name}</Tab>
                                                        ))}
                                                    </TabList>


                                                    <TabPanel tabId={100010}>
                                                        <Row className="rowRegistro">
                                                            
                                                            <Row className='contTarrinasPromo' style={{marginTop:"0px",marginLeft:"0px"}}>
                                                                {this.state.dataProductoDos.sizes.map((item, index)  => (
                                                                    <Col xs={3} style={{position:"relative"}}  onClick={(e) => this.seleccionarProducto(e,"productoDos",item)} className={this.state.productoDos.id == item.id ? "itemTarrinaPromo itemSelect" : "itemTarrinaPromo"}>
                                                                        <img src={item.image} className="imgCant2" style={{marginBottom:"5px",width:"85%",marginTop:"7px"}} />
                                                                        <span className="sizeCat2">{item.name}</span>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </Row>
                                                    </TabPanel>


                                                    {this.state.dataProductoDos.custom_data.toppings.map((item, index) => (
                                                        <TabPanel tabId={index+100010}>
                                                            <div className="rowRegistro" style={{marginTop:"10px"}}>

                                                                {item.items.map(itemChildren => (

                                                                    
                                                                    itemChildren.id != 35 ?
                                                                        <Col xs={3} className="contMainItem" onClick={(e) => this.agregarToppingLocalPromo(e,itemChildren,"toppingsProductoDos","#frozenToppingsDos")}>
                                                                            <div className="circleTopping">
                                                                                <div className="contentTopping">
                                                                                    <div className="masMenos">+</div>
                                                                                    <img className="imgTopping" src={itemChildren.icon_image} alt={itemChildren.name} />
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            <div className="nameToppingD">{itemChildren.name}</div>
                                                                        </Col>

                                                                    :
                                                                    null
                                                                    
                                                                    

                                                                    
                                                                ))}


                                                                {item.items.filter((item) => item.id == 35).map(itemChildren => (

                                                                    <Col xs={3} className="contMainItem contMascotaMovil" onClick={(e) => this.agregarToppingLocalPromo(e,itemChildren,"toppingsProductoDos","#frozenToppingsDos")}>
                                                                        <div className="circleTopping" style={{marginTop:"0px"}}>
                                                                            <div className="contentTopping">
                                                                                <div className="masMenos">+</div>
                                                                                <img className="imgTopping" src={itemChildren.icon_image} alt={itemChildren.name} />
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <div className="nameToppingD" style={{paddingBottom:"15px"}}>{itemChildren.name}</div>
                                                                        <div className="titleMascotaMovil">Mascotas</div>
                                                                    </Col>

                                                                ))}

                                                                

                                                                

                                                            </div>
                                                            
                                                        </TabPanel>
                                                    ))}
                                                </Tabs>

                                                
                                            </Col>

                                            <Row style={{backgroundColor:"#F3F3F3",padding:"10px 5px 0px 5px",margin:"10px 0px 0px 0px"}}>
                                                <Col xs={5} className="contImageMain">
                                                    <div className="contInfoImageMain">
                                                        <div style={this.state.dataProductoDos.categoryId == 4 ? {marginTop:"70px"}: {}}>
                                                            <div className="tarrina cssanimations">
                                                                <div className="frozen-image">
                                                                    <div className="frozen-toppings" id="frozenToppingsDos"></div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.productoDos.base_image != '' ?
                                                                    <img className="imgToppingMain" style={{marginLeft:"-2%"}} src={this.state.productoDos.base_image} />
                                                                : null
                                                            }
                                                        </div>

                                                    </div>
                                                </Col>

                                                <Col xs={7}>
                                                    <div className="contCarritoRight movilcontCarritoRight">
                                                        {
                                                            this.state.visibleDelivery ?
                                                            <div className='contPrecioDelivery'>
                                                                <img className="moto" src="/images/moto.png" alt="llaollao" />
                                                                <div className='contTextoPromoDelivery'>
                                                                    <div className='tituloPromoDelivery'>delivery a $0.99</div>
                                                                    <div className='descripcionPromoDelivery'>Promoción del día</div>
                                                                </div>
                                                            </div>
                                                            : null
                                                        }
                                                    
                                                        <div className="contDetalleCarrito">
                                                            <span className="titleCant1">{this.state.productoUno.nameShow}
                                                            {
                                                                this.state.productoUno.name != "tarrina 1" ?
                                                                    "\n\n"+this.state.dataProducto.currency + ""  + this.state.productoUno.price  + " (c/u)"
                                                                : null
                                                            }
                                                            </span>


                                                            {this.state.productoUno.toppings.map((item, index) => (
                                                                <div className="itemToppingSelect">
                                                                    <div className="itemToppingTitle">
                                                                        <span className="punto"></span>
                                                                        {
                                                                            Constants.instance.onlyCatalog == false ?
                                                                            <span className="numeroTopping">topping {index+1} {(parseInt(this.state.productoUno.topping_free)) > index ? "":"extra ($"+parseFloat(item.price).toFixed(2)+")"}</span>
                                                                            :
                                                                            <span className="numeroTopping">topping {index+1} {(parseInt(this.state.productoUno.topping_free)) > index ? "":""}</span>
                                                                        }
                                                                    </div>
                                                                    <div className="itemToppingTitle">
                                                                        {
                                                                            item.name != "" ?
                                                                            <div><span className={item.price == 0 ? "seleccionar gratis" : "seleccionar"}>{item.categoryId == 9 ? "Salsa ":""} {item.name} {item.price == 0 ? "(Gratis)": null}</span>
                                                                            <img onClick={(e) => this.removerToppingLocal(e,index,"toppingsProductoUno","#frozenToppingsUno")} className="removeTopping" src="/images/icon_cerrar.png" /></div>
                                                                            :
                                                                            <span className="sinSeleccionar">Sin seleccionar</span>
                                                                        }
                                                                        
                                                                    </div>                                      
                                                                </div>
                                                            ))}

                                                            {
                                                                typeof this.state.productoUno.price !== 'undefined' ?
                                                                <div>
                                                                    
                                                                    {
                                                                        this.state.productoUno.precioExtra != 0 ?
                                                                        <div>
                                                                            <div style={{display:"flex",paddingLeft:"20px",marginTop:"5px"}}>
                                                                                <div className="txTotal">Extras</div>
                                                                                <div className="subTotalCarrito" style={{textAlign:"right"}}>{this.state.dataProducto.currency}{this.state.productoUno.precioExtra.toFixed(2)}</div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        : null
                                                                    }
                                                                

                                                                    <div style={{display:"flex",paddingLeft:"20px"}}>
                                                                        <div className="txTotal">SubTotal</div>
                                                                        <div className="subTotalCarrito" style={{textAlign:"right"}}>{this.state.dataProducto.currency}{parseFloat(this.state.productoUno.precioExtra + this.state.productoUno.price).toFixed(2)}</div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }

                                                            <span className="titleCant1" style={{marginTop:"30px"}}>{this.state.productoDos.nameShow}
                                                            {
                                                                this.state.productoDos.name != "tarrina 2" ?
                                                                    "\n\n"+this.state.dataProducto.currency + ""  + this.state.productoDos.price  + " (c/u)"
                                                                : null
                                                            }
                                                            </span>
                                                            {this.state.productoDos.toppings.map((item, index) => (
                                                                <div className="itemToppingSelect">
                                                                    <div className="itemToppingTitle">
                                                                        <span className="punto"></span>
                                                                        {
                                                                            Constants.instance.onlyCatalog == false ?
                                                                            <span className="numeroTopping">topping {index+1} {(parseInt(this.state.productoDos.topping_free)) > index ? "":"extra ($"+parseFloat(item.price).toFixed(2)+")"}</span>
                                                                            :
                                                                            <span className="numeroTopping">topping {index+1} {(parseInt(this.state.productoDos.topping_free)) > index ? "":""}</span>
                                                                        }
                                                                    </div>
                                                                    <div className="itemToppingTitle">
                                                                        {
                                                                            item.name != "" ?
                                                                            <div><span className={item.price == 0 ? "seleccionar gratis" : "seleccionar"}>{item.categoryId == 9 ? "Salsa ":""} {item.name} {item.price == 0 ? "(Gratis)": null}</span>
                                                                            <img onClick={(e) => this.removerToppingLocal(e,index,"toppingsProductoDos","#frozenToppingsDos")} className="removeTopping" src="/images/icon_cerrar.png" /></div>
                                                                            :
                                                                            <span className="sinSeleccionar">Sin seleccionar</span>
                                                                        }
                                                                        
                                                                    </div>                                      
                                                                </div>
                                                            ))}

                                                            {
                                                                typeof this.state.productoDos.price !== 'undefined' ?
                                                                <div>
                                                                    
                                                                    {
                                                                        this.state.productoDos.precioExtra != 0 ?
                                                                        <div>
                                                                            <div style={{display:"flex",paddingLeft:"20px",marginTop:"5px"}}>
                                                                                <div className="txTotal">Extras</div>
                                                                                <div className="subTotalCarrito" style={{textAlign:"right"}}>{this.state.dataProducto.currency}{this.state.productoDos.precioExtra.toFixed(2)}</div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        : null
                                                                    }
                                                                

                                                                    <div style={{display:"flex",paddingLeft:"20px"}}>
                                                                        <div className="txTotal">SubTotal</div>
                                                                        <div className="subTotalCarrito" style={{textAlign:"right"}}>{this.state.dataProducto.currency}{parseFloat(this.state.productoDos.precioExtra + this.state.productoDos.price).toFixed(2)}</div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }

                                                        

                                                            

                                                        </div>
                                                        <div className="contAddCarrito" style={{height:"180px"}}>
                                                            <div className="contToppingExtra">
                                                                <div className="contToppingExtraBorder">
                                                                    <span className="cantidadToppingExtra"></span>
                                                                </div>
                                                            </div>

                                                            <div className="contAgregar">
                                                                <div className="contAgregarBorder">
                                                                <Row>
                                                                    <div className='d-flex justify-content-center'>
                                                                            <div className="modificarCantidad"  onClick={(e) => this.cantidadLocal(e,false)}>-</div>
                                                                            <div className="cantidad">{this.state.cantidad}</div>
                                                                            <div className="modificarCantidad"  onClick={(e) => this.cantidadLocal(e,true)}>+</div>

                                                                    </div>
                                                                    <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"15px"}}>
                                                                        <div className="txTotal">Precio</div>
                                                                        <div className="subTotal">${this.state.netoPrecio.toFixed(2)}</div>
                                                                    </div>
                                                                    
                                                                    <hr style={{width:"80%",marginLeft:"7%",marginTop:"10px",marginBottom:"0px"}}/>
                                                                    <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginTop:"5px"}}>
                                                                        <div className="txTotal" style={{marginTop:"10px"}}>TOTAL</div>
                                                                        <div className="total">${this.state.totalNeto.toFixed(2)}</div>
                                                                    </div>

                                                                </Row>

                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Row>
                                    </div>
                                </SwipeableViews>
                            
                            </Tabs>
                        </Col>
                        
                       
                        {
                            Constants.instance.onlyCatalog() == false ?                                    
                                <Button onClick={(e) => this.agregarProducto(e)} style={{width:"90%",marginBottom:"15px",marginTop:"10px"}} className="btn-agregar btnContinuarCrearCuenta">AGREGAR A CARRITO</Button>
                            : null
                            }

                        <div className="imagenesIlustrativa" style={{marginBottom:"20px"}}>* imágenes con fines ilustrativos</div>             

                        <div className="nutricionalInfo">
                            <span className="valores">VALORES NUTRICIONALES POR CADA 100 GRAMOS DE YOGURT HELADO</span>
                            <div className="clear"></div>
            
                            {typeof this.state.dataProducto.custom_data.nutricional_info.cal !== 'undefined' ? <Col className="itemValorNutricional" xs={4}>Calorías: {this.state.dataProducto.custom_data.nutricional_info.cal}</Col> : null}
                            {typeof this.state.dataProducto.custom_data.nutricional_info.pro !== 'undefined' ? <Col className="itemValorNutricional" xs={4}>Proteínas: {this.state.dataProducto.custom_data.nutricional_info.pro}</Col> : null}
                            {typeof this.state.dataProducto.custom_data.nutricional_info.fat !== 'undefined' ? <Col className="itemValorNutricional" xs={4}>Grasas: {this.state.dataProducto.custom_data.nutricional_info.fat}</Col> : null}
                            {typeof this.state.dataProducto.custom_data.nutricional_info.clc !== 'undefined' ? <Col className="itemValorNutricional" xs={4}>Calcio: {this.state.dataProducto.custom_data.nutricional_info.clc}</Col> : null}
                            {typeof this.state.dataProducto.custom_data.nutricional_info.sal !== 'undefined' ? <Col className="itemValorNutricional" xs={4}>Sal: {this.state.dataProducto.custom_data.nutricional_info.sal}</Col> : null}
                            {typeof this.state.dataProducto.custom_data.nutricional_info.ftrans !== 'undefined' ? <Col className="itemValorNutricional" xs={4}>Grasas trans: {this.state.dataProducto.custom_data.nutricional_info.ftrans}</Col> : null}
                            {typeof this.state.dataProducto.custom_data.nutricional_info.azu !== 'undefined' ? <Col   className="itemValorNutricional"xs={4}>Azúcar: {this.state.dataProducto.custom_data.nutricional_info.azu}</Col> : null}
                            
                        </div>

                        

                        
                    </Row>
                    
                    
                </Row>
                
            }


            <div style={{display:"none"}}>
                <Modal
                    closeTimeoutMS={500}
                    isOpen={this.state.setIsOpen}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <img onClick={this.closeModal} src="/images/cross.png" className="closeModal" />
                    <div className='productoAgregado'>Producto Agregado</div>
                    <div className='nombreProductoAgregado'>{this.state.nameProductoAgregado}</div>

                    <Row>
                        <Col xs={6}>
                            <Button style={{width:"100%"}} onClick={(e) => this.confirmarOrden(e) }  className="btn-agregar btnContinuarCrearCuenta">CONFIRMAR ORDEN</Button>
                        </Col>
                        <Col xs={6}>
                            <Button style={{width:"100%"}} onClick={(e) => this.setState({setIsOpen:false})}  className="btn-agregar btnContinuarCrearCuenta">CONTINUAR COMPRANDO</Button>
                        </Col>
                            
                    </Row>


                </Modal>
            </div>

            <Modal
                    closeTimeoutMS={500}
                    isOpen={this.state.setIsOpenValidacion}
                    style={customStyles}
                    contentLabel=""
                >
                    <img onClick={(e) => this.cerrarModalValidacion() } src="/images/cross.png" className="closeModal" />
                    <div className='nombreProductoAgregado'>{this.state.mensajeAlerta}</div>
                    <Button onClick={(e) => this.cerrarModalValidacion() }  className="btn-agregar btnContinuarCrearCuenta">Aceptar</Button>

                </Modal>

        </Row>
      );
    }
}

export default DetallePromocionMovil;