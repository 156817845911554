import { useState, useEffect, useRef,React} from "react";
import { Row,Col,Button} from "react-bootstrap";
import Container from 'react-bootstrap/Container'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";
import { useMediaQuery } from 'react-responsive'


import HomeMain from './components/HomeMain'
import HomeMainMovil from './components/HomeMainMovil'
import CrearCuenta from './components/CrearCuenta/CrearCuenta'
import CrearCuentaMovil from "./components/CrearCuenta/CrearCuentaMovil";
import { useTheme } from '@mui/material/styles';

function App() {


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

const isMobileSend = useMediaQuery({ maxWidth: 767 })
const theme = useTheme();
  return (
    
    <Router>
      <div id="fb-root"></div>


      <div id="fb-customer-chat"
       attribution="biz_inbox"
       page_id="361181347380578"
        class="fb-customerchat">
      </div>

      <Container fluid="xl">

        <Switch>
            <Route exact path="/crearcuenta">
              <Default>
                <CrearCuenta/>
              </Default>
              <Mobile>
                <CrearCuentaMovil/>
              </Mobile>
              
            </Route>
            <Route path="/">
              <Desktop>
                <HomeMain isMobileSend={isMobileSend}  theme={theme} />
              </Desktop>
              <Tablet>
                <HomeMain />
              </Tablet>
              <Mobile>
                <HomeMainMovil isMobileSend={isMobileSend}  theme={theme} />
              </Mobile>
            </Route>
        </Switch>
      </Container>
    </Router>
    
   
  );
}


export default App;
