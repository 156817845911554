import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { withRouter } from 'react-router-dom';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import {
    FacebookShareCount,
    PinterestShareCount,
    VKShareCount,
    OKShareCount,
    RedditShareCount,
    TumblrShareCount,
    HatenaShareCount,
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    LineShareButton,
    WeiboShareButton,
    PocketShareButton,
    InstapaperShareButton,
    HatenaShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    VKIcon,
    OKIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    MailruIcon,
    EmailIcon,
    LivejournalIcon,
    ViberIcon,
    WorkplaceIcon,
    LineIcon,
    PocketIcon,
    InstapaperIcon,
    WeiboIcon,
    HatenaIcon,
  } from "react-share";
import { Whatsapp } from 'react-bootstrap-icons';
import { HeaderBotones } from '../../header/header_botones'


class BlogDetalle extends React.Component {

    state = {
        loadingBlog: false,
        detalleBlog: {},
        isMobileSend: true,
        shareUrl: window.location.href,
        titleUrl:"llaollao"
    };

    constructor(props) {
        super(props);
        this.handlePageClick = this.handlePageClick.bind(this);

    }

    handlePageClick(event) {
        const newOffset = (event.selected * this.state.itemsPerPage) % this.state.dataBlog.length;

        console.log("resss",newOffset)

        const endOffset = newOffset + this.state.itemsPerPage;

        this.setState({dataBlogActual:this.state.dataBlog.slice(newOffset, endOffset)})

    }

    async componentDidMount() {
        this.setState({isMobileSend:this.props.isMobileSend})

        const res =  await Http.instance.get(`rest/pages/blog/${this.props.match.params.id}`);
        console.log("res",res)
        this.setState({loadingBlog:true,detalleBlog:res})
    }

    render() {
        const { detalleBlog } = this.state;

        return (
            <Row className={this.state.isMobileSend ? "movil noPadding":""}>
                <Col xs={12} className={this.state.isMobileSend ? "noPadding":"" }>
                    {
                        this.state.isMobileSend ?
                        null:
                        <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                    }
                    <div className="clear"></div>
                    <h1 className={this.state.isMobileSend == false ? 'tituloNuestrasTiendas' : 'tituloNuestrasTiendasMovil'}>Blog</h1>
                    <div className="clear"></div>

                    {
                        this.state.loadingBlog == false ?
                            <div className="contSpinner"><Spinner /></div>
                        :
                        <Row className={this.state.isMobileSend ? "altoMinimoMovil itemCant1 blog blogDetalle noPadding":"itemCant1 blog blogDetalle"}>
                            <div style={{position:"relative"}}>
                                <img src={detalleBlog.image} className="imgBanner" />
                                <div className="redesShare"> <span>Compartir artículo</span> 

                                    <FacebookShareButton
                                        url={this.state.shareUrl}
                                        quote={this.state.titleUrl}
                                        className="Demo__some-network__share-button"
                                    >
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>


                                    <TwitterShareButton
                                        url={this.state.shareUrl}
                                        quote={this.state.titleUrl}
                                        className="Demo__some-network__share-button"
                                    >
                                        <TwitterIcon size={32} round />
                                    </TwitterShareButton>

                                    <WhatsappShareButton
                                        url={this.state.shareUrl}
                                        quote={this.state.titleUrl}
                                        className="Demo__some-network__share-button"
                                    >
                                        <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>


                                    <EmailShareButton
                                        url={this.state.shareUrl}
                                        quote={this.state.titleUrl}
                                        className="Demo__some-network__share-button"
                                    >
                                        <EmailIcon size={32} round />
                                    </EmailShareButton>
                            
                                </div>
                            </div>
                            

                            


                            <div className="categoria">{detalleBlog.category.name}</div>
                            <div className={this.state.isMobileSend == false ? 'titulo' : 'tituloMovil'}>{detalleBlog.title}</div>

                            <div className="detalle" style={{marginTop:"40px",textAlign:"left"}} dangerouslySetInnerHTML={{__html: detalleBlog.content}}></div>
                            

                            <Row  className="conFooterRedes nopadding movil">


                                {this.state.isMobileSend == false ? <Col sm={3}></Col> : null}
                                
                                <Col xs={this.state.isMobileSend == false ? 2 : 12}>
                                    <TwitterShareButton
                                                url={this.state.shareUrl}
                                                quote={this.state.titleUrl}
                                                className="Demo__some-network__share-button btTwitter"
                                            >
                                                <TwitterIcon size={35} round /> <span>Tweet</span>
                                    </TwitterShareButton>
                                </Col>

                                <Col xs={this.state.isMobileSend == false ? 2 : 12}>
                                    <FacebookShareButton
                                                url={this.state.shareUrl}
                                                quote={this.state.titleUrl}
                                                className="Demo__some-network__share-button btFacebook"
                                            >
                                                <FacebookIcon size={32}  /> <span>Compartir</span>
                                    </FacebookShareButton>

                                </Col>


                                <Col xs={this.state.isMobileSend == false ? 2 : 12}>
                                    <EmailShareButton
                                            url={this.state.shareUrl}
                                            quote={this.state.titleUrl}
                                            className="Demo__some-network__share-button btEmail"
                                        >
                                            <EmailIcon size={32} round /> <span>Email</span>
                                    </EmailShareButton>

                                </Col>
                                {this.state.isMobileSend == false ? <Col sm={3}></Col> : null}
                            </Row>
                            
                            

                        </Row>
                    }

                    

                    
                </Col>

            </Row>
        );
    }
}

export default BlogDetalle;