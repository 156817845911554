import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { withRouter } from 'react-router-dom';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import {NavItem} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Constants from '../../libs/constants'

class HomeMovil extends React.Component {

    state = {
        loadingCategorias: true,
        dataCategoriasMenu:[],
    };

    handleClickAdd() {
        var data = this.state.dataCat1;
        data.push({id:100})
        console.log(data)
        this.setState({dataCat1:data})
    }

    constructor(props) {
        super(props);
        this.handleClickAdd = this.handleClickAdd.bind(this);

       

    } 

    async componentDidMount() {

        var idUser = 0

        //sesion iniciada
        const customer = localStorage.getItem("customer");
        const initialCustomer = JSON.parse(customer);
        if(initialCustomer != null){
            idUser = initialCustomer.id
        }

        var dataUser = {}
        dataUser.customer = idUser
        dataUser.location = Constants.instance.idDireccion()

        const res =  await Http.instance.post("rest/main/menu-categories",JSON.stringify(dataUser));
        this.setState({dataCategoriasMenu:res,loadingCategorias:false})
        console.log("menu",res)

        //console.log("resss",this.props.match.params)
        
    }

    render() {
      return (
        <Row className="contHomeMovil noPadding">
            
            <img src="/images/banner_movil.png" className="bannerMovil noPadding" />
            <div className="titleLlaollao">llaollao te cuida</div>
            <div className="subTitleLlaollao">Un alimento completo y saludable.</div>

            <div className="noPadding" style={{position:"relative"}}>
                <div className="contBorderVerde">

                    
                </div>

                <div className="contAgregar">
                    <div className="contCategoriasMovil">
                    <Row className="contMenu">
                        {
                            this.state.loadingCategorias == true ?
                                <div className="contSpinner"><Spinner /></div>
                            : null
                        }

                        {this.state.dataCategoriasMenu.map(item => (
                           

                            <Col xs={6}>
                                <NavItem>
                                    <NavLink className="navlink" to={"/categoria/"+item.slug} activeClassName="activeMenuLateral" title={item.name}>
                                        <div className="itemMenu">
                                            <img src={item.image} className="imgMenuMovil" />
                                            <div className="tituloMenuMovil">{item.name}</div>
                                        </div>
                                    </NavLink>
                                </NavItem>
                            </Col>

                        ))}
                    </Row>


                    <div className="mobile-stores-menu visible-mobile above">
                        <ul className="menu-icons">
                            <li>
                                <NavLink className="navlink" to={"/"} activeClassName="active off" title="Menú">
                                    <i className="iconbot iconf-menu"></i>MENÚ
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="navlink" to={"/promociones"} activeClassName="off" title="Promociones">
                                    <i className="iconbot iconf-promotions"></i>PROMOCIONES
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="navlink" to={"/tiendas"} activeClassName="off" title="Restaurantes">
                                <i className="iconbot iconf-rest"></i>TIENDAS
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    </div>
                </div>

            </div>

        </Row>
      );
    }
}

export default HomeMovil;