import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import Modal from 'react-modal';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { NavLink } from "react-router-dom";
import { HeaderBotones } from '../../header/header_botones'


class Acerca extends React.Component {

    state = {
        loadingTiendas: false,
        title:"",
        content:"",
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({isMobileSend:this.props.isMobileSend}, () => {
            
            
            
        });

        if(this.props.isMobileSend == false){
            const res =  await Http.instance.get("rest/pages/about-us");
            this.setState({title:res.title,content:res.content,loadingTiendas:true})     
        }else{
            this.setState({loadingTiendas:true})     
        }

           
    }

    render() {
      return (
        <Row className={this.state.isMobileSend ? "movil noPadding":""}>
            <Col xs={12} className={this.state.isMobileSend ? "noPadding":"" }>
                {
                    this.state.isMobileSend ?
                    null:
                    <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                }
                <div className="clear"></div>
                <h1 className={this.state.isMobileSend == false ? 'tituloNuestrasTiendas' : 'tituloNuestrasTiendasMovil'}>Nosotros</h1>

                <div className="clear"></div>

                {
                    this.state.loadingTiendas == false ?
                        <div className="contSpinner"><Spinner /></div>
                    :
                    this.state.isMobileSend == false ?
                    <Row dangerouslySetInnerHTML={{__html: this.state.content}} className={this.state.isMobileSend ? "altoMinimoMovil contAcercaDe noPadding":"contAcercaDe"}>
                        
                    </Row>
                    :
                    <div style={{padding:"16px"}} className={this.state.isMobileSend ? "altoMinimoMovil contMainMovil":"contAcercaDe"}>
                        <div className='tituloAcercaDe'>llaollao es una franquicia española de yogurt helado que actualmente opera en más de 30 países. </div>
                        <img className="logo-image" src="images/barista.png" alt="llaollao" style={this.state.isMobileSend ? {width:"100%",borderRadius:"8px",padding:"0px",margin:"0px",marginBottom:"20px"} : {width:"70%",marginLeft:"15%"}} />
                        <div className='textoAcercaDe'>La primera franquicia de llaollao en Centro y Norteamérica abrió en El Salvador en junio de 2015. A la fecha hay 10 llaollao en El Salvador, a través de los cuales nos hemos consolidado como la marca más grande de yogurt helado en El Salvador.</div>
                        
                        
                        <div className='teGustaria' onClick={(e) =>  window.location = "/contactenos/franquicias"}> ¿Te gustaría ser parte de nuestra franquicia? </div>

                        <div className="overlap-fondovasitoMovil">
                            <div className='d-flex justify-content-center'>
                                <div className="numerolMovil bree-regular-normal-sheen-green-64pxMovil">6</div>
                                <div className="anios">años</div>
                            </div>
                            
                            <p className="ya-son-6-aos">desde que llaollao dio a conocer el arte de hacer de Yogurt Helado.</p>
                        </div>

                        <div className="overlap-fondovasitoMovil">
                            <div className='d-flex justify-content-center'>
                                <div className="numerolMovil bree-regular-normal-sheen-green-64pxMovil">+60</div>
                                <div className="empleadosMovil">empleados</div>
                            </div>
                            
                            <p className="ya-son-6-aos">Contamos con el apoyo de más de 60 colaboradores</p>
                        </div>

                        <div className="overlap-fondovasitoMovil">
                            <div className='d-flex justify-content-center'>
                                <div className="numerolMovil bree-regular-normal-sheen-green-64pxMovil">10</div>
                                <div className="empleadosMovil">tiendas</div>
                            </div>
                            
                            <p className="ya-son-6-aos">Estamos en las mejores ubicaciones del país.</p>
                        </div>

                        <img className="logo-image" src="/images/pedio_realizado.png" alt="llaollao" style={this.state.isMobileSend ? {width:"100%",borderRadius:"8px",padding:"0px",margin:"0px",marginBottom:"20px"} : {width:"70%",marginLeft:"15%"}} />
                        <h2 className="subTituloAcercade">Nuestro yogurt helado</h2>
                        <div className='textoAcercaDe'>llaollao es Frozen Yogurt o Yogurt Helado, que son lo mismo. Y como es natural, contiene probióticos, proteínas y calcio y es bajo en grasa; por eso convierte en uno de los alimentos más sanos y recomendados en el mundo gracias a sus bondades y a su alto valor nutricional.                        <br /><br />
                        Nuestro Frozen Yogurt es elaborado a diario con ingredientes de la más alta calidad, y combinado con toppings de primera calidad importados desde Europa, y frutas de temporada cortadas a diario, divertidos crunchs y deliciosas salsas, lo convierten en un producto aún más rico y saludable.
                        </div>

                        <h2 className="subTituloNuestro">Porque no es lo mismo helado de yogurt que yogurt helado…</h2>
                        <img className="logo-image" src="https://llaollao-assets.sfo3.digitaloceanspaces.com/common-files/page/acerca-de/acerca-01-movil.jpg" alt="llaollao" style={this.state.isMobileSend ? {width:"100%",borderRadius:"8px",padding:"0px",margin:"0px",marginBottom:"20px"} : {width:"70%",marginLeft:"15%"}} />
                        <h2 className="subTituloAcercade">llaollao te cuida</h2>
                        <div className='textoAcercaDe'>Está recomendado para los grupos más sensibles como son los niños, y personas mayores por ser un alimento completo y saludable.
                        </div>


                        <img className="logo-image" src="images/llaollao_nutre.png" alt="llaollao" style={this.state.isMobileSend ? {width:"100%",borderRadius:"8px",padding:"0px",margin:"0px",marginBottom:"20px",marginTop:"50px"} : {width:"70%",marginLeft:"15%"}} />
                        <h2 className="subTituloAcercade">llaollao te nutre</h2>
                        <div className='textoAcercaDe'>Nuestro Yogurt Helado contiene probióticos, proteínas, calcio, es bajo en azúcar y gluten free.
                        </div>


                        <img className="logo-image" src="https://llaollao-assets.sfo3.digitaloceanspaces.com/common-files/page/acerca-de/acerca-2.jpg" alt="llaollao" style={this.state.isMobileSend ? {width:"100%",borderRadius:"8px",padding:"0px",margin:"0px",marginBottom:"20px",marginTop:"50px"} : {width:"70%",marginLeft:"15%"}} />
                        <h2 className="subTituloAcercade">llaollao te alegra</h2>
                        <div className='textoAcercaDe'>Gracias a la calidad y el sabor de nuestros productos conseguimos que disfrutes de un momento agradable y de una forma totalmente personalizada.
                        </div>
                    </div>
                }

                

                
            </Col>

        </Row>
      );

    }
}

export default Acerca;