
import React, { useState,useEffect} from "react";
import Modal from 'react-modal';
import { Row,Col,Button} from "react-bootstrap";
import { Spinner } from "react-activity";
import $ from 'jquery'; 
import Http from '../libs/http'
import Select from 'react-select'
import validator from 'validator'

const customStyles = {
  overlay: {
      backgroundColor: "rgba(0,0,0,.4)",
  },
  content: {
    top: '50%',
    left: '50%',
    right: 0,
    width: "500px",
    bottom: 'auto',
    transform: 'translate(-50%,-50%)',
    //transform: 'translate(0%, -50%)',
  },
};

const customStylesModal = {
  overlay: {
      backgroundColor: "rgba(0,0,0,.4)",
  },
  content: {
    top: '50%',
    left: '50%',
    right: 0,
    width: "80%",
    maxHeight: "85%",
    bottom: 'auto',
    transform: 'translate(-50%,-50%)',
    //transform: 'translate(0%, -50%)',
  },
};

export const HeaderBotones = ({status,activeTrack,movil}) => {

    const [openModalDD, setOpenModalDD] = useState(false);
    const [departamento, setDepartamento] = useState("");
    const [errorDepartamento, setErrorDepartamento] = useState(false);
    const [errorMunicipio, setErrorMunicipio] = useState(false);
    const [errorColonia, setErrorColonia] = useState(false);
    const [departamentos, setDepartamentos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [municipio, setMunicipio] = useState("");
    const [colonias, setColonias] = useState([]);
    const [colonia, setColonia] = useState("");
    const [coloniaNombre, setColoniaNombre] = useState("");
    const [options, setOptions] = useState([]);
    const [loadMunicipio, setLoadMunicipio] = useState(false);
    const [loadingDataSend, setLoadingDataSend] = useState(false);
    const [errorMensaje, setErrorMensaje] = useState("");
    const [direccionShow, setDireccionShow] = useState("");
    const [showModalInfoDireccion, setShowModalInfoDireccion] = useState(false);
    const [direccion, setDireccion] = useState("");
    const [showModalListDireccion, setModalListDireccion] = useState(false);
    const [loadingInfoDirecciones, setLoadingInfoDirecciones] = useState(false);
    const [listadoDirecciones, setListadoDirecciones] = useState([]);


    
    const cargarInfo = async () => {
        if(direccionShow == "" || direccionShow == "Tu dirección de entrega"){
            cargarMunicipio()
        }else{
            cargarDatosDireccion()
        }
    }

    const cargarDatosDireccion = async () => {
        setShowModalInfoDireccion(true)
    }
    

    const cargarMunicipio = async () => {
        setLoadMunicipio(true)
        setOpenModalDD(true)
        const res =  await Http.instance.get("rest/main/departments");
        setDepartamentos(res)
        setLoadMunicipio(false)
    }

    const validar = async () => {
        var validado = true

        if(departamento == ""){
            setErrorDepartamento(true)
            validado = false
        }

        if(municipio == ""){
            setErrorMunicipio(true)
            validado = false
        }

        if(colonia == ""){
            setErrorColonia(true)
            validado = false
        }

        if(validado == true){
            var dataUserDireccion = {}
            dataUserDireccion.departamento = parseInt(departamento)
            dataUserDireccion.municipio = parseInt(municipio)
            dataUserDireccion.zona = parseInt(colonia)

            setLoadingDataSend(true)
            setErrorMensaje("")

            const res = await Http.instance.post("rest/account/set-address",JSON.stringify(dataUserDireccion));
            if(typeof res.errorHttp === 'undefined'){
                if(res.status == 200){
                    setLoadingDataSend(false)
                    setErrorMensaje("")
                    console.log(res)
                    var direccion = res.address
                    localStorage.setItem("direccionSelect", JSON.stringify(direccion));
                    window.location.reload();
                }else{
                    var  mensaje = res.message
                    setLoadingDataSend(false)
                    setErrorMensaje(mensaje)
                }
            }else{
                setLoadingDataSend(false)
                setErrorMensaje("Ha ocurrido un error")
            }
        }

    }

    const seleccionarDireccion = async (itemSelect) => {
        var listaDirecciones = listadoDirecciones
        for (var j=0; j < listaDirecciones.length; j++) {
            listaDirecciones[j].select = "false"
        }

        var itemDireccion = listaDirecciones.find((item) => item.id == itemSelect.id)
        
        if(typeof itemDireccion !== 'undefined'){
            listaDirecciones[listaDirecciones.indexOf(itemDireccion)].select = "true"
        }

        console.log("itemSelect",itemSelect)
        setListadoDirecciones(listaDirecciones)
        setTimeout(() => {
            setListadoDirecciones([])
            setListadoDirecciones(listaDirecciones)
            console.log("entroooo")
        }, 100);
    }

    const direccionEnvio = async () => {
        setLoadingDataSend(true)
        var itemDireccion = listadoDirecciones.find((item) => item.select == "true")
        console.log("itemDireccion",itemDireccion)
        localStorage.setItem("direccionSelect", JSON.stringify(itemDireccion));
        window.location.reload();
    }

    const infoGeneralUser = async () => {
        //setDireccionShow("      ")
        const direccionSelect = localStorage.getItem("direccionSelect");
        const initialDireccionSelect = JSON.parse(direccionSelect);
        console.log("initialDireccionSelect",initialDireccionSelect)

        if(initialDireccionSelect != null){
            
            var nombreDireccion = initialDireccionSelect.locality.departmentName + " / " + initialDireccionSelect.locality.name
            setDireccionShow(nombreDireccion)
            setDireccion(initialDireccionSelect)

        }else{
            //no hay direccion seleccionada
            const customer = localStorage.getItem("customer");

            const initialCustomer = JSON.parse(customer);
            if(initialCustomer != null){
                var dataUser = {}
                dataUser.customer = initialCustomer.id

                const res = await Http.instance.post("rest/account",JSON.stringify(dataUser));
                if(typeof res.errorHttp === 'undefined'){
                    //console.log("itemDireccion !!!",res)
                    if(res.Status == true){
                        var itemDireccion = res.addresses[0]
                        console.log("itemDireccion !!!",itemDireccion)
                        var nombreDireccion = itemDireccion.locality.departmentName + " / " + itemDireccion.locality.name
                        setDireccionShow(nombreDireccion)
                        setDireccion(itemDireccion)

                    }else{
                        setDireccionShow("Tu dirección de entrega")
                    }
                }else{
                    setDireccionShow("Tu dirección de entrega")
                }
            }else{
                setDireccionShow("Tu dirección de entrega")
            }
        }
    }


    const mostrarDirecciones = async () => {
        setShowModalInfoDireccion(false)

        const customer = localStorage.getItem("customer");

        const initialCustomer = JSON.parse(customer);
        if(initialCustomer != null){

            setLoadingInfoDirecciones(true)
            setModalListDireccion(true)

            var dataUser = {}
            dataUser.customer = initialCustomer.id

            const res = await Http.instance.post("rest/account",JSON.stringify(dataUser));
            if(typeof res.errorHttp === 'undefined'){
                if(res.Status == true){
                    var direcciones = res.addresses

                    const direccionSelect = localStorage.getItem("direccionSelect");

                    const initialDireccionSelect = JSON.parse(direccionSelect);
                    if(initialDireccionSelect != null){
                        var itemDireccion = direcciones.find((item) => item.id == initialDireccionSelect.id)
                        direcciones[direcciones.indexOf(itemDireccion)].select = "true"
                    }else{
                        //no hay direccion seleccionada
                        direcciones[0].select = "true"
                    }

                    setListadoDirecciones(direcciones)
                    setLoadingInfoDirecciones(false)
                }
            }
        }else{
            cargarMunicipio()
        }

    }

    //showModalInfoDireccion
    
    useEffect(() => {
        infoGeneralUser()
    }, [direccionShow]);


  return (
    <div className='d-flex justify-content-end' style={{paddingRight:movil == true? "10px" : "0px"}}>

        {/*
            direccionShow != "" ?
            <div className="linkDirecciones" style={{width:movil == true ? activeTrack == true ? "33%" : "auto" : ""}} onClick={(e) => cargarInfo()} title="tu cuenta">
                {
                    direccionShow != "Tu dirección de entrega" && movil != true?
                        <i className="glyphicon glyphicon-map-marker" style={{marginRight:"10px"}}></i>
                    : null
                }
                
                <span className="direccionShow">
                {direccionShow}
                </span>
                
                <i className="glyphicon glyphicon-chevron-down linkMore"></i>
            </div>
            : null
        */}


        

        {
            status == "active" ? <div className="tiendaAbierta">Tienda abierta</div> : <div className="tiendaCerrada">Tienda cerrada</div>
        }

        {
            activeTrack == true ? <div onClick={(e) => window.location = "/rastreopedido" } className="rastreoPedido">Rastrea tu orden</div> : null
        }

        <Modal
          isOpen={openModalDD}
          style={movil == true ? customStylesModal : customStyles}
          ariaHideApp={false}
          contentLabel=""
          >
            <div  className="rowRegistro">
                <img onClick={(e) => setOpenModalDD(false)} src="/images/cross.png" className="closeModal" />
                <div className='productoAgregado' style={{textAlign:"left",marginTop:movil == true ? "20px" : "0px"}}>Selecciona tu dirección de entrega</div>
                <div className="detalleProductoModal" style={{paddingLeft:"0px",fontSize:"14px"}}>Nos permitirá confirmar la disponibilidad de entrega y las tarifas de envío</div>
                {
                    loadMunicipio ?
                    <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                    :
                    <div>
                        <div style={{marginTop:"20px"}}>
                            <div className="tituloCampo">DEPARTAMENTO
                            </div>
                            <select 
                            onChange={(text) => {
                                console.log("aqui",text.target.value)
                                var listMunicipios = departamentos.filter((item) => item.id == text.target.value)
                                setDepartamento(text.target.value)
                                setErrorDepartamento(false)
                                setMunicipios(listMunicipios[0].items)
                                setColoniaNombre(null)
                                setColonia("")
                                setMunicipio("")

                                $("#municipio").prop("selectedIndex", 0);
                                
                            }}
                            value={departamento}
                            className={errorDepartamento ? "error" : ""} 
                            id="departamento" name="departamento">
                                <option value="">Seleccionar</option>

                                {departamentos.map(item => (
                                    <option value={item.id}>{item.label}</option>
                                ))}

                            </select>
                            {
                                errorDepartamento == true ?
                                    <label className="error">campo obligatorio.</label>
                                :null
                            }
                        </div>


                        <div>
                            <div className="tituloCampo">MUNICIPIO
                            </div>
                            <select 
                                onChange={async (text) => {
                                    setMunicipio(text.target.value)
                                    setErrorMunicipio(false)
                                    //this.setState({municipio:text.target.value,errorMunicipio:false})

                                    const resColonias =  await Http.instance.get(`rest/main/zones/${text.target.value}`);
                                    console.log("resColonias",resColonias)
                                    var options = []
                                    resColonias.map(item => (
                                        options.push({value:item.id,label:item.name_online})
                                    ));

                                    setColonias(resColonias)
                                    setOptions(options)
                                    setColoniaNombre(null)
                                    setColonia("")
                                                                                        
                                    //this.setState({colonias:resColonias,options:options,coloniaNombre:null,colonia:""})

                                }}
                                value={municipio}
                                className={errorMunicipio ? "error" : ""} 
                                id="municipio" name="municipio">
                                <option value="">Seleccionar</option>
                                {municipios.map(item => (
                                    <option value={item.id}>{item.name}</option>
                                ))}
                            </select>
                            {
                                errorMunicipio == true ?
                                    <label className="error">campo obligatorio.</label>
                                :null
                            }
                        </div>


                        <div className='noInput'>
                                <div className="tituloCampo">COLONIA
                                </div>
                                <Select  
                                    onChange={(text) => {
                                        //console.log(text.label)
                                        setColonia(text.value)
                                        setColoniaNombre(text)
                                        setErrorColonia(false)
                                        //this.setState({colonia:text.value,coloniaNombre:text,errorColonia:false})
                                    }}
                                    placeholder="Seleccionar"
                                    value={coloniaNombre}
                                    className={errorColonia ? "errorSelect" : ""} 
                                    options={options}
                                    id="colonia" name="colonia">
                                
                                </Select >
                                {
                                    errorColonia == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                        </div>
                    </div>
                }
               

                <Row className="rowRegistro">
                    <Button onClick={(e) => validar()} className="btn-agregar btnContinuarCrearCuenta">
                    {
                        loadingDataSend == true ?
                            <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                        : 
                        "CONTINUAR"
                    }
                    </Button>
                </Row>
                
            </div>
        </Modal>


        <Modal
          isOpen={showModalInfoDireccion}
          style={movil == true ? customStylesModal : customStyles}
          ariaHideApp={false}
          contentLabel=""
          >
            <div  className="rowRegistro">
                <img onClick={(e) => setShowModalInfoDireccion(false)} src="/images/cross.png" className="closeModal" />
                <div className='productoAgregado' style={{marginTop:movil == true ? "20px" : "0px"}} >Tu dirección de entrega</div>
                {
                    direccion != "" ?
                    <div>
                        <Row className="contInfoDireccion">
                            <Col xs={6}>
                                <div className="tituloItemDireccion">DEPARTAMENTO</div>
                                <div className="itemDireccion">{direccion.locality.departmentName}</div>
                            </Col>
                            <Col xs={6}>
                                <div className="tituloItemDireccion">MUNICIPIO</div>
                                <div className="itemDireccion">{direccion.locality.name}</div>
                            </Col>
                        </Row>
                        <Row className="contInfoDireccion">
                            <Col xs={12}>
                                <div className="tituloItemDireccion">CIUDAD</div>
                                <div className="itemDireccion">{direccion.zone.name}</div>
                            </Col>
                        </Row>
                        <hr/>
                        <Row className="contInfoDireccion">
                            <Col xs={12}>
                                <div className="tituloItemDireccion">TIENDA QUE DESPACHARA TUS PRODUCTOS:</div>
                                <div className="itemDireccionTienda">{direccion.zone.location.name}</div>
                            </Col>
                        </Row>
                        <hr/>
                    </div>
                    : null
                }
                
               

                <Row className="rowRegistro">
                    <Button onClick={(e) => mostrarDirecciones()} className="btn-agregar btnContinuarCrearCuenta" style={{width:"230px"}}>
                    {
                        loadingDataSend == true ?
                            <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                        : 
                        "CAMBIAR DIRECCIÓN DE ENTREGA"
                    }
                    </Button>
                </Row>
                
            </div>
        </Modal>

        <Modal
          isOpen={showModalListDireccion}
          style={movil == true ? customStylesModal : customStyles}
          ariaHideApp={false}
          contentLabel=""
          >
            <div  className="rowRegistro" style={{maxHeight:"80%"}}>
                <img onClick={(e) => setModalListDireccion(false)} src="/images/cross.png" className="closeModal" />
                <div className='productoAgregado'>Dirección de envío</div>
                <div>Selecciona la dirección de envío</div>
                <div className="listadoDirecciones">

                    {
                        loadingInfoDirecciones == true ?
                            <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                        : 
                        listadoDirecciones.map((itemChildren, index) => (

                                                                
                            <div className='contDireccion' onClick={(e) => seleccionarDireccion(itemChildren)}>
                                <input defaultChecked={itemChildren.select == "true" ? true : false} class="form-check-input" className="radioDireccion" type="radio" name="flexRadioDefault" />
                                <div className='contDireccionInfo'>
                                    <div className='contTitleDir'>{itemChildren.type_name}</div>
                                    <div>{itemChildren.address}, {itemChildren.locality.departmentName}</div>
                                </div>
                            </div>

                        ))
                    }
                        
                </div>
                
               

                <Row className="rowRegistro">
                    <Button onClick={(e) => direccionEnvio()} className="btn-agregar btnContinuarCrearCuenta" style={{width:"230px"}}>
                    {
                        loadingDataSend == true ?
                            <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                        : 
                        "SELECCIONAR DIRECCIÓN"
                    }
                    </Button>
                </Row>
                
            </div>
        </Modal>

    </div>
  );
}

//export default HeaderBotones;
