import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import Modal from 'react-modal';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { HeaderBotones } from '../../header/header_botones'

const AnyReactComponent = ({ text }) => 
    <div className="float-end linkLocationTienda">
        <img onClick={(e) => {
            window.open("https://www.google.com/maps?ll="+text.lat+","+text.lng+"&z=17", '_blank').focus();
        }}  
        src="/images/location_pin.png" className="imgLocation" />
    </div>;

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      top: '50%',
      left: '20%',
      right: '20%',
      bottom: 'auto',
      marginRight: '0%',
      transform: 'translate(0%, -50%)',
    },
  };


class Tiendas extends React.Component {

    state = {
        loadingTiendas: false,
        setIsOpen: false,
        dataTienda:[],
        itemTienda:{},
        center: {
            lat: 13.679645000000011,
            lng: -89.2522751651123
        }
    };

    closeModal() {
        this.setState({setIsOpen:!this.state.setIsOpen})
    }

    openCoordenates(e,item) {
       console.log(item)
    }


    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);

    }

    verMapa(e,item) {
        console.log(item)
        var coords = item.coords.split(', ');
        var center = {
            lat: parseFloat(coords[1]),
            lng: parseFloat(coords[0])
        }
        console.log("center ",center)
        this.setState({itemTienda:item,center:center,setIsOpen:!this.state.setIsOpen})
    }
    

    async componentDidMount() {

        const res =  await Http.instance.get("rest/main/locations");
        this.setState({dataTienda:res,loadingTiendas:true})

        console.log("resss",res)
        
    }

    render() {
      return (
        <Row>
            <Col xs={12}>
                <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                <div className="clear"></div>
                <h1 className="tituloNuestrasTiendas">Nuestras tiendas</h1>
                <div style={{display:"none"}}>
                    <a href="#" className="float-end linkLocationTienda">San Miguel</a>
                    <a href="#" className="float-end linkLocationTienda">La Libertad</a>
                    <a href="#" className="float-end linkLocationTienda">San Salvador</a>
                </div>
                <div className="clear"></div>

                {
                    this.state.loadingTiendas == false ?
                        <div className="contSpinner"><Spinner /></div>
                    :
                    <Row className="itemCant1">
                        {this.state.dataTienda.map(item => (
                            <Col sm={4}>
                                <div className="itemTienda">
                                    <img src={item.image} className="imgTienda" />
                                    <Row style={{marginTop:"20px"}}>
                                        <Col sm={3}><img src={"/images/tienda_llaollao.png"} className="imgTienda" /></Col>
                                        <Col sm={9}>
                                            <h2 className="tituloTienda">{item.name}</h2>
                                            {
                                                item.open == 1 ?
                                                <div className="localAbierto">ABIERTO</div>
                                                :
                                                <div className="localCerrado">CERRADO</div>
                                            }
                                            


                                            <div className="descripcionTienda" style={{display:"none"}}>Consumo en el lugar / Para llevar</div>
                                            <div className="descripcionTienda" style={{fontSize:"13px",display:"none"}}>+503 2104-5444</div>
        
                                        </Col>
                                    </Row>
        
                                    <div className="bloqueDetalleTienda">
                                        <img src="/images/direccion.png" className="imgTiendaIconDireccion float-start" />
                                        <div className="descripcionTiendaChild">{item.direction}</div>
                                    </div>
        
                                    <div className="bloqueDetalleTienda">
                                        <img src="/images/horario.png" className="imgTiendaIconHorario float-start" />
                                        <div className="descripcionTiendaChild">
                                            <span className="tituloHorario">HORARIOS DE ATENCIÓN</span>
                                            <span className="descripcionTiendaChild removePaddingLeft" dangerouslySetInnerHTML={{__html: item.text_hours}}></span>
                                        </div>
                                    </div>
        
                                    <div href="#" onClick={(e) => this.verMapa(e,item)} className="verMapa">VER MAPA</div>
        
                                    <div className="clear"></div>
        
                                </div>
                            </Col>
                        ))}                
                    </Row>
                }

                

                
            </Col>


            

            <div style={{display:"none"}}>
                <button onClick={this.closeModal}>Open Modal</button>
                <Modal
                    closeTimeoutMS={500}
                    isOpen={this.state.setIsOpen}
                    //onAfterOpen={afterOpenModal}
                    //onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <img onClick={this.closeModal} src="/images/cross.png" className="closeModal" />
                    <h2 className="tituloTienda">{this.state.itemTienda.name}</h2>
                    <div className="descripcionTiendaChild" style={{paddingLeft:"0px"}}>{this.state.itemTienda.direction}</div>

                    <div style={{ height: '50vh', width: '100%', display:"block"}}>
                        <GoogleMapReact
                        defaultCenter={{
                            lat: 59.95,
                            lng: 30.33
                        }}
                        center={this.state.center}
                        bootstrapURLKeys={{ key: "AIzaSyD1BDqiPv2uoe68yzbhubBm5hV5IHIYYKk" }}
                        defaultZoom={15}
                        //onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps,this.state.center,this.state.itemTienda)}
                        >

                            <AnyReactComponent
                                lat={this.state.center.lat}
                                lng={this.state.center.lng}
                                text={this.state.center}
                                />
                        </GoogleMapReact>
                    </div>
                </Modal>
            </div>

        </Row>
      );
    }
}

export default Tiendas;