import React,{ useState,useEffect }  from 'react';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { Route, Redirect } from 'react-router'
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InputMask from 'react-input-mask';
import validator from 'validator'
import { withRouter } from 'react-router-dom';
import $ from 'jquery'; 
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { NavLink } from "react-router-dom";
import { HeaderBotones } from '../../header/header_botones'

class OrdenFinalizada extends React.Component {
    state = {
        loadSendData:false
    };

    obtenerCuenta = async (e) => {
        var dataUser = {}
        dataUser.customer = this.state.customer.id

        const res = await Http.instance.post("rest/account",JSON.stringify(dataUser));
        var direccion = res.addresses
        direccion[0].selected = true
        var direccionSeleccionada = direccion[0]

    }

    constructor(props) {
        super(props);
    }



    async componentDidMount() {
        this.setState({isMobileSend:this.props.isMobileSend})

         //sesion iniciada
        const customer = localStorage.getItem("customer");
        const initialCustomer = JSON.parse(customer);
        if(initialCustomer != null){
            this.setState({customer:initialCustomer}, () => {
                this.obtenerCuenta()
                
            });
        }
    }

    render() {

      return (
        <Row className={this.state.isMobileSend ? "movil noPadding":""}>
            <Col xs={12} className={this.state.isMobileSend ? "noPadding":"" }>
                {
                    this.state.isMobileSend == false ?
                    <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                    : null
                }
                    
                <div className="clear"></div>
                <h1 className={this.state.isMobileSend == false ? 'tituloNuestrasTiendas' : 'tituloNuestrasTiendasMovil'}>orden finalizada</h1>

                <div className="clear"></div>

                
                <Row className={this.state.isMobileSend ? "itemCant1 contContactenos contCuenta noPadding":"itemCant1 contContactenos contCuenta"}>
                   
                    <Row className={this.state.isMobileSend == false ? 'rowRegistro' : 'rowRegistro noPadding'}>
                        <Col xs={12}>

                            <div className="contFormularioContactenos contCarritoProducto" style={this.state.isMobileSend ? {marginLeft:"16px",marginRight:"16px",padding:"16px",textAlign:"center",alignContent:"center",alignItems:"center"} :{marginLeft:"50px",marginRight:"50px",padding:"25px",textAlign:"center",alignContent:"center",alignItems:"center"}}>
                                
                                <div className="txTuOrden">Tu orden ha finalizado con éxito</div>
                                <div className="txHemos">Hemos enviado un correo<br/>con la confirmación de tú orden</div>
                                <img className="logo-image" src="/images/pedio_realizado.png" alt="llaollao" style={this.state.isMobileSend ? {width:"100%"} : {width:"70%",marginLeft:"15%"}} />

                                <div>

                                    <NavLink  style={{float:"none"}} className="btn-agregar btnConfirmar btnLink" to={"/rastreopedido"}  title="RASTREO DE ORDEN">
                                        <div>
                                            <i className='glyphicon glyphicon-map-marker' style={{color:"#fff",fontSize:"13px"}}></i> &nbsp;&nbsp; RASTREA TU ORDEN
                                        </div>
                                    </NavLink>


                                </div>

                            </div>
                        
                        </Col>
                    </Row>     
                </Row>
                

                

                
            </Col>
        </Row>
      );
    }
}

export default OrdenFinalizada;