import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import Modal from 'react-modal';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import validator from 'validator'
import InputMask from 'react-input-mask';
import { NavLink } from "react-router-dom";
import $ from 'jquery'; 
import Select from 'react-select'
import Moment from 'moment';
import { HeaderBotones } from '../../header/header_botones'
import Constants from '../../libs/constants'

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 0,
      width: "500px",
      bottom: 'auto',
      transform: 'translate(-50%,-50%)',
      //transform: 'translate(0%, -50%)',
    },
  };


const customStylesModal = {
    overlay: {
        backgroundColor: "rgba(0,0,0,.4)",
    },
    content: {
      top: '50%',
      left: '50%',
      right: 0,
      width: "500px",
      bottom: 'auto',
      transform: 'translate(-50%,-50%)',
      //transform: 'translate(0%, -50%)',
    },
  };

class MiCuenta extends React.Component {

    state = {
        nombre:"",
        nombreFranquicia:"",
        email:"",
        emailFranquicia:"",
        duda:"",
        selectedIndex: 0,
        errorEmailText:"campo obligatorio.",
        errorEmailFranquiciaText:"campo obligatorio.",
        loadingServicioCliente: false,
        loadingFranquicia: false,
        country:[],
        pais:"",
        telefono:"",
        terminos: false,
        comunicaciones: false,
        data:{},
        loadData:true,
        dui:"",
        errorDui:false,
        loadingPuntos:false,
        setIsOpenCarrito:false,
        setIsOpenPuntos:false,
        setIsOpenRegistro:false,
        setIsOpenmensaje:false,
        resPuntos:{points:{activo:0},prizes:[]},
        nombre:"",
        apellido:"",
        telefono:"",
        duiRegistro:"",
        email:"",
        loadingRegistroLeal:false,
        errorRegistro:"",
        setIsOpenActualizarDatos:false,
        nombreUser:"",
        apellidoUser:"",
        telefonoUser:"",
        emailUser:"",
        diaUser:"",
        mesUser:"",
        yearUser:"",
        dia:"",
        mes:"",
        year:"",
        loadingDataSend:false,
        titulo:"",
        mensaje:"",
        setIsOpenActualizarPass:false,
        passUser:"",
        passUserNew:"",
        passUserNewDos:"",
        setIsOpenDireccion:false,
        departamentos:[],
        municipios:[],
        colonias:[],
        options:  [],
        direccionSelect: null,
        setIsOpenDelete:false,
        coloniaNombre:null,
        isMobileSend: true,
        tipoDocumento:"",
        duiRegistro:"",
        genero:"",

    };

    async handleReOrden(e,item){

        if(item.can_reorder){
            console.log("itemProducto",item.order_details)


            for (var i = 0; i < item.order_details.length; i+=1) {
                let itemProducto = item.order_details[i]
                console.log("itemProducto",itemProducto)

                var dataProductoSave = {}

                
                if(itemProducto.isPromo == true){
                   
                    dataProductoSave.promo = true
                    dataProductoSave.isTopping = false
                    dataProductoSave.name =  itemProducto.title
                    dataProductoSave.promo_id = itemProducto.promoId
                    dataProductoSave.quantity = itemProducto.quantity
                    dataProductoSave.total = itemProducto.subtotal

                    //producto 1
                    dataProductoSave.productoUno = {}
                    dataProductoSave.productoUno.productId = itemProducto.instructions.prod_1.productId
                    dataProductoSave.productoUno.precio_unitario = itemProducto.instructions.prod_1.precio_unitario
                    dataProductoSave.productoUno.currency = item.currency
                    dataProductoSave.productoUno.base_image = itemProducto.instructions.prod_1.small_image
                    dataProductoSave.productoUno.desc = ""
                    dataProductoSave.productoUno.small_desc = ""
                    dataProductoSave.productoUno.name = itemProducto.instructions.prod_1.name
                    dataProductoSave.productoUno.toppingsFree = itemProducto.instructions.prod_1.topping_free

                    var customProductoUno = []
                    
                    
                    for (var j = 0; j < itemProducto.instructions.prod_1.custom.length; j+=1) {
                        let itemC = itemProducto.instructions.prod_1.custom[j]

                        var dataToppings = {}
                        dataToppings.toppingId = itemC.toppingId
                        dataToppings.name = itemC.name
                        dataToppings.categoryId = itemC.categoryId
                        dataToppings.quantity = 1
                        dataToppings.price = itemC.price
                        customProductoUno.push(dataToppings)
                    }

                    var toppingsProductoUnoGratis = customProductoUno.filter((item) => item.price == 0)
                    var toppingsProductoUnoNoGratis = customProductoUno.filter((item) => item.price !== 0)

                    var customProductoUnoFinal = []

                    customProductoUnoFinal.push.apply(customProductoUnoFinal, toppingsProductoUnoGratis);
                    customProductoUnoFinal.push.apply(customProductoUnoFinal, toppingsProductoUnoNoGratis);


                    //producto uno
                    //reordenar los topings para que los gratis aparezcan de ultimo
                    var toppingsProductoUnoGratis = itemProducto.instructions.prod_1.custom.filter((item) => item.price == 0)
                    var toppingsProductoUnoNoGratis = itemProducto.instructions.prod_1.custom.filter((item) => item.price !== 0)
            
                    var customProductoUnoFinal = []
                    customProductoUnoFinal.push.apply(customProductoUnoFinal, toppingsProductoUnoNoGratis);
                    customProductoUnoFinal.push.apply(customProductoUnoFinal, toppingsProductoUnoGratis);
                    itemProducto.instructions.prod_1.custom = customProductoUnoFinal

                    var precioExtraProductoUno = 0
                    var toppingsValidos = itemProducto.instructions.prod_1.custom.filter((item) => item.price !== 0)
                    if(itemProducto.instructions.prod_1.topping_free < toppingsValidos.length){
                        for (var k=itemProducto.instructions.prod_1.topping_free; k < toppingsValidos.length; k++) {
                            precioExtraProductoUno += toppingsValidos[k].price
                        } 
                    }

                    dataProductoSave.productoUno.precio_unitario = itemProducto.instructions.prod_1.precio_unitario - precioExtraProductoUno

                    dataProductoSave.productoUno.custom = customProductoUnoFinal

                     //producto 2
                     //reordenar los topings para que los gratis aparezcan de ultimo
                    var toppingsProductoUnoGratis = itemProducto.instructions.prod_2.custom.filter((item) => item.price == 0)
                    var toppingsProductoUnoNoGratis = itemProducto.instructions.prod_2.custom.filter((item) => item.price !== 0)
            
                    var customProductoUnoFinal = []
                    customProductoUnoFinal.push.apply(customProductoUnoFinal, toppingsProductoUnoNoGratis);
                    customProductoUnoFinal.push.apply(customProductoUnoFinal, toppingsProductoUnoGratis);
                    itemProducto.instructions.prod_2.custom = customProductoUnoFinal



                    dataProductoSave.productoDos = {}
                    dataProductoSave.productoDos.productId = itemProducto.instructions.prod_2.productId
                    dataProductoSave.productoDos.precio_unitario = itemProducto.instructions.prod_2.precio_unitario
                    dataProductoSave.productoDos.currency = item.currency
                    dataProductoSave.productoDos.base_image = itemProducto.instructions.prod_2.small_image
                    dataProductoSave.productoDos.desc = ""
                    dataProductoSave.productoDos.small_desc = ""
                    dataProductoSave.productoDos.name = itemProducto.instructions.prod_2.name
                    dataProductoSave.productoDos.toppingsFree = itemProducto.instructions.prod_2.topping_free

                    var customProductoDos = []
                    
                    
                    for (var j = 0; j < itemProducto.instructions.prod_2.custom.length; j+=1) {
                        let itemC = itemProducto.instructions.prod_2.custom[j]

                        var dataToppings = {}
                        dataToppings.toppingId = itemC.id
                        dataToppings.name = itemC.name
                        dataToppings.categoryId = itemC.categoryId
                        dataToppings.quantity = 1
                        dataToppings.price = itemC.price
                        customProductoDos.push(dataToppings)
                    }

                    var toppingsProductoDosGratis = customProductoDos.filter((item) => item.price == 0)
                    var toppingsProductoDosNoGratis = customProductoDos.filter((item) => item.price !== 0)

                    var customProductoDosFinal = []

                    customProductoDosFinal.push.apply(customProductoDosFinal, toppingsProductoDosGratis);
                    customProductoDosFinal.push.apply(customProductoDosFinal, toppingsProductoDosNoGratis);


                    //producto uno
                    var precioExtraProductoDos = 0
                    var toppingsValidos = itemProducto.instructions.prod_2.custom.filter((item) => item.price !== 0)
                    if(itemProducto.instructions.prod_2.topping_free < toppingsValidos.length){
                        for (var k=itemProducto.instructions.prod_2.topping_free; k < toppingsValidos.length; k++) {
                            precioExtraProductoDos += toppingsValidos[k].price
                        } 
                    }

                    dataProductoSave.productoDos.precio_unitario = itemProducto.instructions.prod_2.precio_unitario - precioExtraProductoDos


                    dataProductoSave.productoDos.custom = customProductoDosFinal
                    dataProductoSave.keyProduct =  `${dataProductoSave.promo_id}${(Math.floor(Math.random() * 100) + 1)}`
                    
                }else{

                    dataProductoSave.productId = itemProducto.productId
                    dataProductoSave.quantity =itemProducto.quantity
                    dataProductoSave.total = itemProducto.subtotal
                    dataProductoSave.precio_unitario = itemProducto.price
                    dataProductoSave.currency = item.currency
                    dataProductoSave.base_image = itemProducto.product.small_image
                    dataProductoSave.desc = itemProducto.product.desc
                    dataProductoSave.small_desc = itemProducto.product.small_desc
                    dataProductoSave.name = itemProducto.product.name_online
                    dataProductoSave.toppingsFree = itemProducto.product.custom_data.toppings_free
                    dataProductoSave.isTopping = itemProducto.isTopping
                    dataProductoSave.promo = false

                    var custom = []
            
                    for (var j = 0; j < itemProducto.instructions.length; j+=1) {
                        let itemTopping = itemProducto.instructions[j]
            
                        var dataToppings = {}
                        dataToppings.toppingId = itemTopping.toppingId
                        dataToppings.name = itemTopping.name
                        dataToppings.categoryId = itemTopping.categoryId
                        dataToppings.quantity = 1
                        dataToppings.price = itemTopping.price
                        custom.push(dataToppings)
            
                        //console.log("item",item)
                    }
                    
                    dataProductoSave.custom = custom
                    dataProductoSave.keyProduct =  `${dataProductoSave.productId}${(Math.floor(Math.random() * 100) + 1)}`

                }

                
                
                
                dataProductoSave.orden = parseFloat(Moment().format("DDMMYYYYHHmmss"))

                const key = `carrito-${dataProductoSave.keyProduct}`
                var carrito = JSON.stringify(dataProductoSave)
                localStorage.setItem(key,carrito)

                //console.log("item",itemProducto)
            }

            this.props.actualizarCarrito()

            this.setState({setIsOpenCarrito:true,nameProductoAgregado:""})

            setTimeout(() => {
                this.setState({setIsOpenCarrito:false})
            }, 5000);

        }
    }

    handleSelectTabs = index => {
        this.setState({ selectedIndex: index });
    };

    cerrarModal() {
        this.setState({setIsOpenActualizarDatos:!this.state.setIsOpenActualizarDatos})
        this.resetaerValores()
    }

    openCloseModalPass() {
        this.setState({setIsOpenActualizarPass:!this.state.setIsOpenActualizarPass,passUser:"",passUserNew:"",passUserNewDos:""})
        //this.resetaerValores()
    }

    async openCloseModalDireccion(tipo,itemDireccion) {

        if(tipo){
            if(itemDireccion != null){

                
                var listMunicipios = this.state.departamentos.filter((item) => item.id == itemDireccion.locality.department)

                this.setState({coloniaNombre:null,options:options,municipios:listMunicipios[0].items,direccionSelect:itemDireccion,setIsOpenDireccion:tipo,departamento:itemDireccion.locality.department,municipio:itemDireccion.locality.id,colonia:itemDireccion.zone.id,tipoDireccion:itemDireccion.type,direccion:itemDireccion.address})
            
            
                const resColonias =  await Http.instance.get(`rest/main/zones/${itemDireccion.locality.id}`);
                console.log(resColonias)
                
                var options = []
                resColonias.map(item => (
                   options.push({value:item.id,label:item.name_online})
                ));

                var coloniaNombre = options.filter((item) => item.value == itemDireccion.zone.id)
                this.setState({colonias:resColonias,options:options,coloniaNombre:coloniaNombre})

            }else{
                this.setState({direccionSelect:itemDireccion,setIsOpenDireccion:tipo,departamento:"",municipio:"",colonia:"",tipoDireccion:"",direccion:""})
            }
        }else{
            this.setState({setIsOpenDireccion:tipo,departamento:"",municipio:"",colonia:"",tipoDireccion:"",direccion:""})
        }
    }

    openCloseModalDeleteDireccion(tipo,itemDireccion) {

        if(tipo){
            this.setState({direccionSelect:itemDireccion,setIsOpenDelete:tipo})
        }else{
            this.setState({setIsOpenDelete:tipo})
        }
    }

    constructor(props) {
        super(props);
    }

    openConsultaPunto(e) {
        this.setState({setIsOpen:true})
    }

    openRegistroLeal(e) {
        this.setState({setIsOpenRegistro:true})
    }

    confirmarOrden(e){
        this.setState({setIsOpenCarrito:false})

        var  shadow_layer = $("#cd-shadow-layer"),
        lateral_cart = $("#cd-cart"),
        menu_navigation = $(".top-nav-list")

        menu_navigation.removeClass("speed-in")


        if(lateral_cart.hasClass("speed-in")){
            lateral_cart.removeClass("speed-in").one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                //$("body").removeClass("overflow-hidden")
            })
            shadow_layer.removeClass("is-visible")
            }else{
            lateral_cart.addClass("speed-in").one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                //$("body").addClass("overflow-hidden")
            })
            shadow_layer.addClass("is-visible")
        }
    }

    async comprobarPuntos(e){
        if(this.state.dui == ""){
            this.setState({errorDui:true})
            return
        }else if(this.state.dui.length < 8){
            this.setState({errorDui:true})
            return
        }

        this.setState({loadingPuntos:true})

        var dataUserSend = {}
        dataUserSend.identity = this.state.dui
        dataUserSend.customer = this.state.customer.id
        dataUserSend.user = this.state.customer.userId

        const res = await Http.instance.post("rest/main/llaoclub-points",JSON.stringify(dataUserSend));

        console.log("res!!!",res)
        console.log(dataUserSend)
        if(typeof res.errorHttp === 'undefined'){
            this.setState({loadingPuntos:false,resPuntos:res,setIsOpen:false,setIsOpenPuntos:true})
        }else{
            this.setState({loadingPuntos:false})
        }

    }
    
    async registrarLeal(e){
        if(this.state.nombre == ""){
            this.setState({errorNombre:true})
            return
        }

        if(this.state.apellido == ""){
            this.setState({errorApellido:true})
            return
        }

        if(this.state.telefono == ""){
            this.setState({errorTelefono:true})
            return
        }else if(this.state.telefono.length != 8){
            this.setState({errorTelefono:true})
            return
        }

        if(this.state.email == ""){
            this.setState({errorEmail:true,errorEmailText:"campo obligatorio"})
            return
        }else if(validator.isEmail(this.state.email) == false){
            this.setState({errorEmail:true,errorEmailText:"Email no valido"})
            return
        }

        const date = this.state.year+'/'+this.state.mes+'/'+this.state.dia;

        if (validator.isDate(date)) {            
            this.setState({errorFecha:false,errorFechaTexto:""})
        } else {
            this.setState({errorFecha:true,errorFechaTexto:"Fecha no valida"})
            return
        }

        if(this.state.tipoDocumento == ""){
            this.setState({errorTipoDocumento:true})
            return
        }

        if(this.state.duiRegistro == ""){
            this.setState({errorDuiRegistro:true})
            return
        }

        if(this.state.genero == ""){
            this.setState({errorGenero:true})
            return
        }

        
        this.setState({loadingRegistroLeal:true})

        var dataUserSend = {}
        dataUserSend.identity = this.state.duiRegistro
        dataUserSend.gender = this.state.genero
        dataUserSend.typeIdentity = this.state.tipoDocumento
        dataUserSend.birthday =  `${this.state.dia}/${this.state.mes}/${this.state.year}`
        dataUserSend.email = this.state.email
        dataUserSend.phone = this.state.telefono
        dataUserSend.customer = this.state.customer.id
        dataUserSend.user = this.state.customer.userId
        dataUserSend.firstname = this.state.nombre
        dataUserSend.lastname = this.state.apellido
        console.log(dataUserSend)

        
        const res = await Http.instance.post("rest/main/llaoclub-register",JSON.stringify(dataUserSend));

        console.log(res)


        if(typeof res.errorHttp === 'undefined'){
            if(res.message != ""){
                this.setState({loadingRegistroLeal:false,errorRegistro:res.message})
            }else{
                this.setState({loadingRegistroLeal:false,setIsOpenRegistro:false,setIsOpenmensaje:true,titulo:"Registro agregado",mensaje:"Ahora ya puedes acumular y consultar tus puntos"})
                this.setState({duiRegistro:"",genero:"",tipoDocumento:"",email:"",telefono:"",nombre:"",apellido:""})
            }
        }else{
            this.setState({loadingRegistroLeal:false,errorRegistro:"Hubo un error en el regisro"})
        }
    }

    obtenerCuenta = async (e) => {
        var dataUser = {}
        dataUser.customer = this.state.customer.id

        const res = await Http.instance.post("rest/account",JSON.stringify(dataUser));

        console.log("res ===",res)
        console.log("res ===",dataUser)
        var birthday = ""
        if(res.birthday != null){
            birthday = res.birthday.split("/")
        }

        var resultado = res

        for (var i = 0; i < resultado.orders.length; i+=1) {
            console.log("=====",resultado.orders[i])
            //order_details
            for (var j = 0; j < resultado.orders[i].order_details.length; j+=1) {
                if(resultado.orders[i].order_details[j].isPromo){
                    resultado.orders[i].order_details[j].promo =  true
                    resultado.orders[i].order_details[j].isTopping =  false
                }else if(resultado.orders[i].order_details[j].isTopping){
                    resultado.orders[i].order_details[j].promo =  false
                }else if(resultado.orders[i].order_details[j].isTopping == false){
                    resultado.orders[i].order_details[j].promo =  false
                }
            }
            
        } 


        console.log("res",res)
        this.setState({data:resultado,
            loadData:false,
            nombreUser:res.name,
            apellidoUser:res.lastname,
            telefonoUser:res.phone,
            emailUser:res.email,
            diaUser:birthday[0],
            mesUser:birthday[1],
            yearUser:birthday[2]
        }, () => {

        });
        

    }

    resetaerValores(){

        var birthday = ""
        if(this.state.data.birthday != null){
            birthday = this.state.data.birthday.split("/")
        }


        this.setState({
            nombreUser:this.state.data.name,
            apellidoUser:this.state.data.lastname,
            telefonoUser:this.state.data.phone,
            emailUser:this.state.data.email,
            diaUser:birthday[0],
            mesUser:birthday[1],
            yearUser:birthday[2]
        }, () => {

        });
    }

    guardarDatos = async (e) => {
        var validado = true

        if(this.state.nombreUser == ""){
            this.setState({errorNombreUser:true})
            validado = false
        }

        if(this.state.apellidoUser == ""){
            this.setState({errorApellidoUser:true})
            validado = false
        }

        if(this.state.telefonoUser == ""){
            this.setState({errorTelefonoUser:true})
            validado = false
        }else if(this.state.telefonoUser.length != 8){
            this.setState({errorTelefonoUser:true})
            validado = false
        }

        if(this.state.emailUser == ""){
            this.setState({errorEmailUser:true,errorEmailUserText:"campo obligatorio"})
            validado = false
        }else if(validator.isEmail(this.state.emailUser) == false){
            this.setState({errorEmailUser:true,errorEmailUserText:"Email no valido"})
            validado = false
        }

        const date = this.state.yearUser+'/'+this.state.mesUser+'/'+this.state.diaUser;

        if (validator.isDate(date)) {            
            this.setState({errorFechaUser:false,errorFechaTexto:""})
        } else {
            this.setState({errorFechaUser:true,errorFechaTexto:"Fecha no valida"})
            validado = false
        }

        if(validado){

            this.setState({loadingDataSend:true,loadData:true})

            var dataUserSend = {}
            dataUserSend.nombre = this.state.nombreUser
            dataUserSend.apellido = this.state.apellidoUser
            dataUserSend.telefono = this.state.telefonoUser
            dataUserSend.email = this.state.emailUser
            dataUserSend.cumple = `${this.state.diaUser}/${this.state.mesUser}/${this.state.yearUser}`
            dataUserSend.customer = this.state.customer.id


            const res = await Http.instance.post("rest/account/update",JSON.stringify(dataUserSend));

            this.setState({loadingDataSend:false})
            console.log(res)
            console.log(dataUserSend)

            if(typeof res.errorHttp === 'undefined'){
                if(res.status == 200){
                    localStorage.setItem("customer", JSON.stringify(res.customer));

                    var customer = res.customer
                    customer.addresses = this.state.data.addresses

                    this.setState({data:customer,loadingDataSend:false,loadData:false,setIsOpenActualizarDatos:false,setIsOpenmensaje:true,titulo:"Datos Actualizados",mensaje:"Los datos de tu cuenta han sido actualizados"})

                    this.resetaerValores()
                }else{
                    var  mensaje = "Ha ocurrido un error al crear la cuenta"
                    this.setState({loadingDataSend:false,loadData:false,errorMensaje:mensaje})
                }
            }else{
                this.setState({loadingDataSend:false,errorMensaje:"Ha ocurrido un error"})
            }

        }

        
    }


    actualizarPass = async (e) => {
        var validado = true

        if(this.state.passUser.length < 8){
            this.setState({errorPassUser:true,errorPassText:"ingrese al menos 8 caracteres"})
            validado = false
        }


        if(this.state.passUserNew.length < 8){
            this.setState({errorPassNew:true,errorPassTextNew:"ingrese al menos 8 caracteres"})
            validado = false
        }else if(this.state.passUserNew != this.state.passUserNewDos){
            this.setState({errorPassNewDos:true,errorPassTextNewDos:"las contraseñas no coinciden"})
            validado = false
        }

        if(validado){

            this.setState({loadingDataPass:true})

            var dataUserSend = {}
            dataUserSend.newPass = this.state.passUserNew
            dataUserSend.oldPass = this.state.passUser
            dataUserSend.customer = this.state.customer.id
            dataUserSend.user = this.state.customer.userId


            const res = await Http.instance.post("rest/account/change-password",JSON.stringify(dataUserSend));

            this.setState({loadingDataPass:false})

            console.log(res)
            console.log(dataUserSend)
            if(typeof res.errorHttp === 'undefined'){
                if(res.status == "success"){
                    this.setState({loadingDataSend:false,setIsOpenActualizarPass:false,titulo:res.message,mensaje:"",setIsOpenmensaje:true})
                }else{
                    this.setState({loadingDataSend:false,errorActualizarPass:res.message})
                }
            }else{
                this.setState({loadingDataSend:false,errorActualizarPass:"Ha ocurrido un error"})
            }

        }
        
    }

    deleteDireccion= async (e) => {
        console.log(this.state.direccionSelect)

        var dataUserDireccion = {}
        dataUserDireccion.id = this.state.direccionSelect.id
        dataUserDireccion.customer = this.state.customer.id

        this.setState({loadingDeleteDireccion:true})

        const res = await Http.instance.post("rest/account/delete-address",JSON.stringify(dataUserDireccion));
        console.log(res)
        console.log(dataUserDireccion)

        if(typeof res.errorHttp === 'undefined'){
            if(res.status == 200){
                this.setState({loadingDeleteDireccion:false,setIsOpenDelete:false,titulo:res.message,mensaje:"",setIsOpenmensaje:true})
                this.obtenerCuenta()
            }else{
                this.setState({loadingDeleteDireccion:false,errorDeleteDireccion:res.message})
            }
        }else{
            this.setState({loadingDeleteDireccion:false,errorDeleteDireccion:"Ha ocurrido un error"})
        }

    }

    guardarActualizarDireccion = async (e) => {
        
        
        var validado = true
        if(this.state.departamento == ""){
            this.setState({errorDepartamento:true})
            validado = false
        }

        if(this.state.municipio == ""){
            this.setState({errorMunicipio:true})
            validado = false
        }

        if(this.state.colonia == ""){
            this.setState({errorColonia:true})
            validado = false
        }

        if(this.state.tipoDireccion == ""){
            this.setState({errorTipoDireccion:true})
            validado = false
        }

        if(this.state.direccion == ""){
            this.setState({errorDireccion:true})
            validado = false
        }


        if(validado){

            if(this.state.direccionSelect == null){
                var dataUserDireccion = {}
                dataUserDireccion.alias = "Dirección"
                dataUserDireccion.departamento = parseInt(this.state.departamento)
                dataUserDireccion.municipio = parseInt(this.state.municipio)
                dataUserDireccion.zona = parseInt(this.state.colonia)
                dataUserDireccion.tipo = parseInt(this.state.tipoDireccion)
                dataUserDireccion.direccion = this.state.direccion
                dataUserDireccion.customer = this.state.customer.id
                dataUserDireccion.principal = false
    
                
                this.setState({loadingSaveDireccion:true})
    
                const res = await Http.instance.post("rest/account/new-address",JSON.stringify(dataUserDireccion));
                console.log(res)
                console.log(dataUserDireccion)
    
                if(typeof res.errorHttp === 'undefined'){
                    if(res.status == 200){
                        this.setState({loadingSaveDireccion:false,setIsOpenDireccion:false,titulo:"dirección guardada exitosamente",mensaje:"",setIsOpenmensaje:true,errorSaveDireccion:""})
                        this.obtenerCuenta()
                    }else{
                        this.setState({loadingSaveDireccion:false,errorSaveDireccion:res.message})
                    }
                }else{
                    this.setState({loadingSaveDireccion:false,errorSaveDireccion:"Ha ocurrido un error"})
                }
            }else{
                //actualizar contraseña

                var dataUserDireccion = {}
                dataUserDireccion.principal = this.state.direccionSelect.primary_addr
                dataUserDireccion.alias = this.state.direccionSelect.alias
                dataUserDireccion.departamento = parseInt(this.state.departamento)
                dataUserDireccion.municipio = parseInt(this.state.municipio)
                dataUserDireccion.zona = parseInt(this.state.colonia)
                dataUserDireccion.tipo = parseInt(this.state.tipoDireccion)
                dataUserDireccion.direccion = this.state.direccion
                dataUserDireccion.customer = this.state.customer.id
                dataUserDireccion.id = this.state.direccionSelect.id


                this.setState({loadingSaveDireccion:true})
    
                const res = await Http.instance.post("rest/account/save-address",JSON.stringify(dataUserDireccion));
                console.log(res)
                console.log(dataUserDireccion)
    
                if(typeof res.errorHttp === 'undefined'){
                    if(res.status == 200){
                        this.setState({loadingSaveDireccion:false,setIsOpenDireccion:false,titulo:"dirección guardada exitosamente",mensaje:"",setIsOpenmensaje:true,errorSaveDireccion:""})
                        this.obtenerCuenta()
                    }else{
                        this.setState({loadingSaveDireccion:false,errorSaveDireccion:res.message})
                    }
                }else{
                    this.setState({loadingSaveDireccion:false,errorSaveDireccion:"Ha ocurrido un error"})
                }
                

            }
            

        }
      
        
    }

    cerraSesion  = async (e) => {
        var i;
        for (i in localStorage) {
            if (localStorage.hasOwnProperty(i)) {
                localStorage.removeItem(i)
            }
        }

        window.location.replace("/");
    }

    async componentDidMount() {

        this.setState({isMobileSend:this.props.isMobileSend}, () => {
            
            this.state.isMobileSend ?  customStyles.content.width = "80%" : customStyles.content.width = "500px"
            this.state.isMobileSend ?  customStylesModal.content.width = "80%" : customStylesModal.content.width = "500px"
            this.state.isMobileSend ?  customStylesModal.content.height = "85%" : customStylesModal.content.height = "96%"

        });
        

  

        //sesion iniciada
        const customer = localStorage.getItem("customer");
        const initialCustomer = JSON.parse(customer);
        if(initialCustomer != null){
            this.setState({customer:initialCustomer,dui:initialCustomer.identity}, () => {
                this.obtenerCuenta()
                
            });
        }else{
            window.location = "/";
        }

        const res =  await Http.instance.get("rest/main/departments");
        this.setState({departamentos:res})

        if(typeof this.props.match.params.tab != 'undefined'){
            if(this.props.match.params.tab == "historialordenes"){
                this.setState({selectedIndex:1})
            }else if(this.props.match.params.tab == "direcciones"){
                this.setState({selectedIndex:1})
            }else if(this.props.match.params.tab == "datospersonales"){
                this.setState({selectedIndex:0})
            }
        }
    }

    async componentDidUpdate(prevProps) {
      
        if (prevProps.match.params.tab !== this.props.match.params.tab) {
            
            if(typeof this.props.match.params.tab != 'undefined'){
                window.scrollTo(0, 0);
                if(this.props.match.params.tab == "historialordenes"){
                    this.setState({selectedIndex:1})
                }else if(this.props.match.params.tab == "direcciones"){
                    this.setState({selectedIndex:1})
                }else if(this.props.match.params.tab == "datospersonales"){
                    this.setState({selectedIndex:0})
                }
            }

            this.setState({dataCategoria:{},loadingCategorias:true})
        }
      }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
      return (
        <Row className={this.state.isMobileSend ? "movil noPadding":""}>
            <Col xs={12} className="nopadding">
                {
                    this.state.isMobileSend ?
                    null:
                    <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                }
                <div className="clear"></div>
                <h1 className={this.state.isMobileSend == false ? 'tituloNuestrasTiendas' : 'tituloNuestrasTiendasMovil'}>Mi cuenta</h1>
                
                <div className="clear"></div>

                
                <Row className={this.state.isMobileSend == false ? 'itemCant1 contContactenos contCuenta"' : 'contMainMovil contContactenos contCuenta nopadding'}>
                    <form  className={this.state.isMobileSend == false ? '' : 'altoMinimoM'}>
                        <Tabs  selectedIndex={this.state.selectedIndex} onSelect={this.handleSelectTabs}>
                            
                                {
                                    this.state.isMobileSend ?
                                    <TabList className="movilTabs react-tabs__tab-list">
                                        <Tab tabFor="0">Mi datos</Tab>
                                        {
                                            Constants.instance.onlyCatalog() == false ?
                                                <Tab tabFor="1">Mis direcciones</Tab>
                                            : null
                                        }
                                        
                                        <Tab tabFor="2">Órdenes</Tab>
                                    </TabList>
                                    :
                                    <TabList>
                                        <Tab tabFor="0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Datos personales&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Tab>
                                        {
                                            Constants.instance.onlyCatalog() == false ?
                                                <Tab tabFor="1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mis direcciones&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Tab>
                                            : null
                                        }
                                        <Tab tabFor="2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Historial de Órdenes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Tab>
                                    </TabList>
                                }
                           

                            <TabPanel tabId="0">
                                {
                                    this.state.loadData ?
                                        <div className="contSpinner"><Spinner /></div>
                                    :

                                    <Row className="rowRegistro">
                                        <Col sm={7} style={{paddingLeft:"20px",paddingRight:"20px"}}>
                                        <div className='contNameUser'>
                                                {this.state.data.name}  {this.state.data.lastname}
                                                <div className='contEdit'  onClick={() => this.setState({setIsOpenActualizarDatos:!this.state.setIsOpenActualizarDatos})} >
                                                    <i className="glyphicon glyphicon-edit"></i> &nbsp;Editar
                                                </div>
                                        </div>
                                        <hr/>
                                        <ul>
                                                <li>Email: {this.state.data.email}</li>
                                                <li>Fecha de nacimiento: {this.state.data.birthday}</li>
                                                <li>Telefóno: {this.state.data.phone}</li>
                                        </ul>
                                        <hr/>
                                            <div className='btCambiarPass' onClick={() => this.openCloseModalPass()}>
                                                <i className="glyphicon glyphicon-edit"></i> &nbsp;Cambiar contraseña
                                            </div>
                                            <hr/>
                                            <div className='btCambiarPass' onClick={(e) => this.cerraSesion(e)}>
                                                <i className="glyphicon glyphicon-lock"></i> &nbsp;Cerrar Sesión
                                            </div>
                                        <hr/>
                                        </Col>
                                        <Col sm={5}>
                                            <div className="contFormularioContactenos" style={{marginLeft:"50px",marginRight:"50px"}}>
                                                
                                                
                                                <img src="/images/club.png" alt="llaollao"  style={{marginLeft:"10%",width:"80%"}}/>
                                            

                                                <Button onClick={(e) => this.openConsultaPunto(e)} className="btn-agregar btConsulta">Consulta tus puntos</Button>
                                                <Button onClick={(e) => this.openRegistroLeal(e)} className="btn-agregar btRegistrateClub">Regístrate</Button>
                                                <NavLink className="btConoceBeneficios" to={"/llaollaoclub"} title="Conoce sus beneficios aquí">
                                                    Conoce sus beneficios aquí
                                                </NavLink>
                                            </div>
                                        </Col> 
                                    </Row>
                                }


                            </TabPanel>
                            {
                                Constants.instance.onlyCatalog() == false ?
                                    <TabPanel tabId="1">

                                        {
                                            this.state.loadData ?
                                                <div className="contSpinner"><Spinner /></div>
                                            :
                                            <Row className="rowRegistro">

                                        {
                                            this.state.isMobileSend ?
                                            <div>

                                                <Col sm={8}>
                                                    <div className='contDireccionMain'>

                                                    {this.state.data.addresses.map((itemChildren, index) => (

                                                                    
                                                        <div className='contDireccion'>
                                                            <div> 
                                                                <div className='contTitleDireccion'><i className="glyphicon glyphicon-map-marker"></i> &nbsp;{itemChildren.type_name}</div>

                                                                <div onClick={(e) => this.openCloseModalDireccion(true,itemChildren)} className='contEditAddress' style={{marginLeft:"20px"}}>
                                                                    <i className="glyphicon glyphicon-edit"></i> &nbsp;Editar
                                                                </div>
                                                                {
                                                                    index != 0 ?
                                                                    <div onClick={(e) => this.openCloseModalDeleteDireccion(true,itemChildren)} className='contEditAddress'>
                                                                        <i className="glyphicon glyphicon-trash"></i> &nbsp;Eliminar
                                                                    </div>
                                                                    : null
                                                                }
                                                                

                                                            </div>
                                                            <div className='clear'></div>

                                                            <div className='contDireccionText'>{itemChildren.address}, {itemChildren.locality.departmentName}</div>
                                                        </div>

                                                    ))}
                                                    </div>
                                                    
                                                </Col> 
                                                <Col sm={4}>
                                                    <Button onClick={(e) => this.openCloseModalDireccion(true,null)} className="btn-agregar btConsultaMovil" style={{marginTop:"30px"}}>Ingresar nueva dirección</Button>

                                                </Col>
                                                
                                            </div>
                                            :

                                            <div>
                                                <Row>
                                                    <Col sm={4}>
                                                        <Button onClick={(e) => this.openCloseModalDireccion(true,null)} className="btn-agregar btConsulta" style={{marginTop:"50px"}}>Ingresar nueva dirección</Button>

                                                    </Col>
                                                    <Col sm={8}>
                                                        <div className='contDireccionMain'>

                                                        {this.state.data.addresses.map((itemChildren, index) => (

                                                                        
                                                            <div className='contDireccion'>
                                                                <div> 
                                                                    <div className='contTitleDireccion'><i className="glyphicon glyphicon-map-marker"></i> &nbsp;{itemChildren.type_name}</div>

                                                                    <div onClick={(e) => this.openCloseModalDireccion(true,itemChildren)} className='contEditAddress' style={{marginLeft:"20px"}}>
                                                                        <i className="glyphicon glyphicon-edit"></i> &nbsp;Editar
                                                                    </div>
                                                                    {
                                                                        index != 0 ?
                                                                        <div onClick={(e) => this.openCloseModalDeleteDireccion(true,itemChildren)} className='contEditAddress'>
                                                                            <i className="glyphicon glyphicon-trash"></i> &nbsp;Eliminar
                                                                        </div>
                                                                        : null
                                                                    }
                                                                    

                                                                </div>
                                                                <div className='clear'></div>

                                                                <div className='contDireccionText'>{itemChildren.address}, {itemChildren.locality.departmentName}</div>
                                                            </div>

                                                        ))}
                                                        </div>
                                                        
                                                    </Col>
                                                </Row>
                                            </div>

                                        }
                                            
                                                
                                            </Row>
                                        }
                                    </TabPanel>
                                : null
                            }
                            <TabPanel tabId="2">
                                <Row className="rowRegistro">
                                    <Col sm={12}>
                                    {
                                        this.state.loadData ?
                                            <div className="contSpinner"><Spinner /></div>
                                        :

                                        this.state.data.orders.map((itemChildren, index) => (
                                            <div className='contHistorialItem '>
                                                <div className='headerHistorial'  style={ this.state.isMobileSend ? {marginTop:"40px"} : {}}>
                                                    {
                                                        this.state.isMobileSend == false ?
                                                        <div className='lineaHeaderLeft'></div>
                                                        : null
                                                    }
                                                    
                                                    Compra realizada el {itemChildren.date_created}
                                                    {
                                                        this.state.isMobileSend == false ?
                                                        <div className='lineaHeaderRight'></div>
                                                        : null
                                                    }
                                                </div>

                                                <div className="contFormularioContactenos contHistorial"  style={  this.state.isMobileSend ? {padding:"20px 15px 5px 15px",marginTop:"15px"} : {padding:"20px 15px 5px 15px"}}>
                                                    
                                                    <div className='ordenNo'>Orden No. {itemChildren.cod_order}</div>
                                                    {itemChildren.order_details.map((itemToppping, index) => (
                                                        <Row className="rowRegistro">
                                                            <Col sm={5} className='contCarritoProducto' style={{padding:"0px",paddingBottom:"20px",display:"flex"}}>
                                                                {
                                                                    itemToppping.isTopping == true ?
                                                                        <div style={{marginLeft:"30px",width:"100px",marginRight:"16px"}}>
                                                                            <div className="circleTopping" style={{height:"auto"}}>
                                                                                <div className="contentTopping">
                                                                                    <img className="imgTopping" src={itemToppping.product.small_image} />
                                                                                </div>
                                                                                
                                                                            </div>

                                                                        </div>
                                                                        
                                                                    :
                                                                        null
                                                                }

                                                                {
                                                                     itemToppping.promo == false && itemToppping.isTopping == false  ?
                                                                        <img src={itemToppping.product.small_image} className="imgProducto" style={{marginLeft:"5px"}} />
                                                                    :
                                                                        null
                                                                }

                                                                {
                                                                     itemToppping.promo == true  ?
                                                                        <img src={itemToppping.instructions.image} className="imgProducto" style={{marginLeft:"5px"}} />
                                                                    :
                                                                        null
                                                                }


                                                                <div>
                                                                    <div className="nameProducto">{itemToppping.instructions.isPromo == true ? itemToppping.title : itemToppping.product.name_online} ({itemToppping.quantity})</div>
                                                                    <div className="fechaCompra">Fecha de compra: {itemChildren.date_created}</div>
                                                                </div>
                                                                {
                                                                    this.state.isMobileSend == false ?
                                                                    <div className='lineaHistorialFull'></div>
                                                                    : null
                                                                }

                                                            </Col>
                                                            <Col sm={4} style={{position:"relative"}}>
                                                                {
                                                                    itemToppping.instructions.isPromo == true ?
                                                                    <div>
                                                                        <div class="toppings"><span>{itemToppping.instructions.prod_1.name}</span></div>
                                                                        {
                                                                            itemToppping.instructions.prod_1.custom.length != 0 ?
                                                                            <div>
                                                                                <div class="toppings"><span>toppings</span></div>
                                                                                <div class="toppingsItems">
                                                                                    {itemToppping.instructions.prod_1.custom.map((itemTo, indexTopping) => (
                                                                                            
                                                                                                indexTopping < (itemToppping.instructions.prod_1.custom.length - 1) ?
                                                                                                <span>{itemTo.name}, </span>
                                                                                                :
                                                                                                <span>{itemTo.name}</span>
                                                                                            
                                                                                            
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        : null 
                                                                        }

                                                                    <div className="toppings" style={{marginTop:"30px"}}><span>{itemToppping.instructions.prod_2.name}</span></div>

                                                                        {
                                                                            itemToppping.instructions.prod_2.custom.length != 0 ?
                                                                            <div>
                                                                                <div class="toppings"><span>toppings</span></div>
                                                                                <div class="toppingsItems">
                                                                                    {itemToppping.instructions.prod_2.custom.map((itemTo, indexTopping) => (
                                                                                            
                                                                                                indexTopping < (itemToppping.instructions.prod_2.custom.length - 1) ?
                                                                                                <span>{itemTo.name}, </span>
                                                                                                :
                                                                                                <span>{itemTo.name}</span>
                                                                                            
                                                                                            
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        : null 
                                                                        }
                                                                    </div>
                                                                    :
                                                                    itemToppping.instructions.length != 0 ?
                                                                        <div>
                                                                            <div class="toppings"><span>toppings</span></div>
                                                                            <div class="toppingsItems">
                                                                                {itemToppping.instructions.map((itemTo, indexTopping) => (
                                                                                        
                                                                                            indexTopping < (itemToppping.instructions.length - 1) ?
                                                                                            <span>{itemTo.name}, </span>
                                                                                            :
                                                                                            <span>{itemTo.name}</span>
                                                                                        
                                                                                        
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    : null 
                                                                }
                                                                
                                                                {
                                                                    this.state.isMobileSend == false ?
                                                                    <div className='lineaHistorialFull'></div>
                                                                    : null
                                                                }
                                                            </Col>
                                                            <Col sm={3}>

                                                            <div style={{display:"flex",paddingLeft:"20px",paddingRight:"10px",marginBottom:"15px"}}>
                                                                <div className="txTotal">Precio</div>
                                                                <div className="subTotalCarrito">{itemChildren.currency} {itemToppping.subtotal.toFixed(2)}</div>
                                                            </div>
                                                            </Col>
                                                        </Row>
                                                    ))}

                                                    <div>
                                                    <div class="contEnvioCosto"><span class="txEnvioDomicilio">ENVÍO A DOMICILIO:</span><span class="txCostoEnvio">{itemChildren.currency} {itemChildren.delivery_fee.toFixed(2)}</span></div>
                                                    {
                                                        itemChildren.driver_tip != 0 ?
                                                            <div class="contEnvioCosto" style={{marginTop:"15px"}}><span class="txEnvioDomicilio">PROPINA PARA REPARTIDOR:</span><span class="txCostoEnvio">{itemChildren.currency} {itemChildren.driver_tip.toFixed(2)}</span></div>
                                                        : null
                                                    }

                                                    <div class="conTotalCarrito">TOTAL <span>{itemChildren.currency} {itemChildren.total.toFixed(2)}</span></div>
                                                    {
                                                        Constants.instance.onlyCatalog() == false ?
                                                            <Button style={{width:"200px",margin:"0px auto",marginBottom:"20px"}} onClick={(e) => this.handleReOrden(e,itemChildren)}  className={itemChildren.can_reorder ? "btn-agregar btnContinuarCrearCuenta": "btn-agregar btnContinuarCrearCuenta btnContinuarDesactivado"}>
                                                                RE-ORDENAR
                                                            </Button>
                                                        : null
                                                    }
                                                    <div className='clear'></div>
                                                    </div>
                                                    
                                                </div>

                                            </div>
                                        ))
                                    }

                                    
                                    </Col> 
                                    
                                </Row>
                            </TabPanel>
                        </Tabs>
                    </form>        
                </Row>
                

                

                
            </Col>


                <Modal
                    isOpen={this.state.setIsOpen}
                    style={customStyles}
                    contentLabel=""
                >
                    <div>
                        <img onClick={() => this.setState({setIsOpen:!this.state.setIsOpen})} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left"}}>Consulta de Puntos</div>
                        <div className="detalleProductoModal" style={{paddingLeft:"0px",fontSize:"14px"}}>Puedes consultar únicamente tu Documento personal.</div>

                        <Row className="rowRegistro">
                            <div style={{marginTop:"30px",paddingRight:"10%",paddingLeft:"10%"}}>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-file"></i> &nbsp;&nbsp;DOCUMENTO
                                </div>
                                <input 
                                readonly
                                value={this.state.dui}
                                className={this.state.errorDui ? "error" : ""} 
                                id="duiNumber" name="phoneNumber" placeholder="0000000-0" type="tel" required="true" aria-required="true" aria-invalid="false" />
                                {
                                    this.state.errorDui == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                            </div>

                            <Button onClick={(e) => this.comprobarPuntos(e,false)} style={{width:"80%",marginLeft:"10%"}} className="btn-agregar btnContinuarCrearCuenta">
                                {
                                    this.state.loadingPuntos == true ?
                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                    : 
                                    "CONSULTAR PUNTOS"
                                }

                                
                            </Button>

                        </Row>
                        
                    </div>
                    
                </Modal>

                <Modal
                    isOpen={this.state.setIsOpenPuntos}
                    style={customStyles}
                    contentLabel=""
                >
                    <div>
                        <img onClick={() => this.setState({setIsOpenPuntos:!this.state.setIsOpenPuntos})} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left"}}>Consulta de puntos llaollao</div>
                        <div className="detalleProductoModal" style={{paddingLeft:"0px",fontSize:"14px"}}>El cálculo de tus puntos acumulados es:</div>

                        <Row className="rowRegistro" style={{paddingRight:"7%",paddingLeft:"7%"}}>
                            <Col sm={4}></Col>
                            <Col sm={4}>
                                <div style={{position:"relative"}}>
                                    <div className='tienes'>tienes</div>
                                    <div className='cantidadPuntosLeal'>{this.state.resPuntos.points.activos}</div>
                                    <div className='puntosLeal'>puntos</div>
                                    <img src="/images/silueta_llaollao.png" style={this.state.isMobileSend ? {width:"80%",marginLeft:"10%"} : {}} className="imgTienda" />
                                </div>
                            </Col>
                        </Row>
                        <div className='contTusPuntos' style={{textAlign:"center",fontSize:"13px"}}>Canjeables únicamente en nuestras tiendas</div>
                    </div>
                    
                </Modal>


                <Modal
                    isOpen={this.state.setIsOpenRegistro}
                    style={customStylesModal}
                    contentLabel=""
                >


                    <div>
                        <img onClick={() => this.setState({setIsOpenRegistro:!this.state.setIsOpenRegistro})} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left"}}>Regístrate en llaollao club</div>
                        <div className="detalleProductoModal" style={{paddingLeft:"0px",fontSize:"14px"}}>Comienza acumular puntos con las compras que realices en llaollao</div>

                        <Row className="rowRegistro" style={{paddingRight:"7%",paddingLeft:"7%"}}>
                            <div style={{marginTop:"30px"}}>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-user"></i> &nbsp;&nbsp;NOMBRES
                                </div>
                                <input 
                                onChange={(text) => {
                                    this.setState({nombre:text.target.value,errorNombre:false})
                                }}
                                value={this.state.nombre}
                                className={this.state.errorNombre ? "error" : ""} 
                                id="firstName"
                                maxchar="1"
                                name="firstName" type="text" maxlength="30" />
                                {
                                    this.state.errorNombre == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                                

                            </div>

                            <div>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-user"></i> &nbsp;&nbsp;APELLIDOS
                                </div>
                                <input 
                                onChange={(text) => {
                                    this.setState({apellido:text.target.value,errorApellido:false})
                                }}
                                value={this.state.apellido}
                                className={this.state.errorApellido ? "error" : ""} 
                                id="firstName"
                                maxchar="1"
                                name="firstName" type="text" maxlength="30" />
                                {
                                    this.state.errorApellido == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                                

                            </div>

                            <div>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-phone"></i> &nbsp;&nbsp;TELÉFONO
                                </div>
                                <InputMask 
                                onChange={(text) => {
                                    const valor = text.target.value.replace(/\D/g, '')
                                    this.setState({telefono:valor,errorTelefono:false})
                                }}
                                value={this.state.telefono}
                                className={this.state.errorTelefono ? "error" : ""} 
                                id="phoneNumber" mask="9999-9999" name="phoneNumber" placeholder="0000-0000"  inputmode="tel" required="true" type="tel" aria-required="true" aria-invalid="false" />
                                {
                                    this.state.errorTelefono == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                            </div>


                            <div>
                                <div className="tituloCampo">
                                <i className="bi bi-envelope"></i>

                                    <i className="glyphicon glyphicon-envelope"></i> &nbsp;&nbsp;EMAIL
                                </div>
                                <input 
                                onChange={(text) => {
                                    this.setState({email:text.target.value,errorEmail:false,errorEmailText:""})
                                }}
                                value={this.state.email}
                                className={this.state.errorEmail ? "error" : ""} 
                                type="email" id="emailAddress" name="emailAddress" required="" aria-required="true"  aria-invalid="true" />
                                {
                                    this.state.errorEmail == true ?
                                        <label className="error">{this.state.errorEmailText}.</label>
                                    :null
                                }
                            </div>

                            <div>

                                                
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-gift"></i> &nbsp;&nbsp;CUMPLEAÑOS
                                </div>
                                <div>
                                    <select 
                                    onChange={(text) => {
                                        this.setState({dia:text.target.value,errorFecha:false,errorFechaTexto:""})
                                    }}
                                    value={this.state.dia}
                                    className={this.state.errorFecha ? "error inputSelectBirthDay birthDay" : "inputSelectBirthDay birthDay"} 
                                    id="birthDay" name="birthDay">
                                        <option value="">Día</option>
                                        <option value="01">1</option>
                                        <option value="02">2</option>
                                        <option value="03">3</option>
                                        <option value="04">4</option>
                                        <option value="05">5</option>
                                        <option value="06">6</option>
                                        <option value="07">7</option>
                                        <option value="08">8</option>
                                        <option value="09">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                </select>

                                <select 
                                    onChange={(text) => {
                                        this.setState({mes:text.target.value,errorFecha:false,errorFechaTexto:""})
                                    }}
                                    value={this.state.mes}
                                    className={this.state.errorFecha ? "error inputSelectBirthDay birthMonth" : "inputSelectBirthDay birthMonth"} 
                                    id="birthMonth" name="birthMonth" aria-invalid="false">
                                    <option value="">Mes</option>
                                        <option value="01">Enero</option>
                                        <option value="02">Febrero</option>
                                        <option value="03">Marzo</option>
                                        <option value="04">Abril</option>
                                        <option value="05">Mayo</option>
                                        <option value="06">Junio</option>
                                        <option value="07">Julio</option>
                                        <option value="08">Agosto</option>
                                        <option value="09">Septiembre</option>
                                        <option value="10">Octubre</option>
                                        <option value="11">Noviembre</option>
                                        <option value="12">Diciembre</option>
                                </select>

                                    <select 
                                        onChange={(text) => {
                                            this.setState({year:text.target.value,errorFecha:false,errorFechaTexto:""})
                                        }}
                                        value={this.state.year}
                                        className={this.state.errorFecha ? "error inputSelectBirthDay birthYear" : "inputSelectBirthDay birthYear"} 
                                        id="birthYear" name="birthYear" aria-invalid="false">
                                        <option value="">Año</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option><option value="2002">2002</option><option value="2001">2001</option><option value="2000">2000</option><option value="1999">1999</option><option value="1998">1998</option><option value="1997">1997</option><option value="1996">1996</option><option value="1995">1995</option><option value="1994">1994</option><option value="1993">1993</option><option value="1992">1992</option><option value="1991">1991</option><option value="1990">1990</option><option value="1989">1989</option><option value="1988">1988</option><option value="1987">1987</option><option value="1986">1986</option><option value="1985">1985</option><option value="1984">1984</option><option value="1983">1983</option><option value="1982">1982</option><option value="1981">1981</option><option value="1980">1980</option><option value="1979">1979</option><option value="1978">1978</option><option value="1977">1977</option><option value="1976">1976</option><option value="1975">1975</option><option value="1974">1974</option><option value="1973">1973</option><option value="1972">1972</option><option value="1971">1971</option><option value="1970">1970</option><option value="1969">1969</option><option value="1968">1968</option><option value="1967">1967</option><option value="1966">1966</option><option value="1965">1965</option><option value="1964">1964</option><option value="1963">1963</option><option value="1962">1962</option><option value="1961">1961</option><option value="1960">1960</option><option value="1959">1959</option><option value="1958">1958</option><option value="1957">1957</option><option value="1956">1956</option><option value="1955">1955</option><option value="1954">1954</option><option value="1953">1953</option><option value="1952">1952</option><option value="1951">1951</option><option value="1950">1950</option>
                                    </select>
                                </div>

                                {
                                    this.state.errorFecha == true ?
                                        <label className="error">{this.state.errorFechaTexto}.</label>
                                    :null
                                }

                            </div>

                            <div>
                                <div>

                                
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-file"></i> &nbsp;&nbsp;TIPO DOCUMENTO
                                </div>
                                <div>
                                    <select 
                                    onChange={(text) => {
                                        this.setState({tipoDocumento:text.target.value,errorTipoDocumento:false})
                                    }}
                                    style={{width:"100%"}}
                                    value={this.state.tipoDocumento}
                                    className={this.state.errorTipoDocumento ? "error inputSelectBirthDay birthDay" : "inputSelectBirthDay birthDay"} 
                                    id="birthDay" name="birthDay">
                                        <option value="">Seleccionar</option>
                                        <option value="7">Documento Único Identidad</option>
                                        <option value="8">Pasaporte</option>
                                        <option value="12">Carné de Residencia</option>
                                    </select>
                                </div>

                                {
                                    this.state.errorTipoDocumento == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }

                                </div>
                            </div>


                            <div>
                                <div>
                                    <div className="tituloCampo">
                                        <i className="glyphicon glyphicon-file"></i> &nbsp;&nbsp;DOCUMENTO
                                    </div>
                                    <input 
                                    onChange={(text) => {
                                        const valor = text.target.value.replaceAll("_", "");
                                        console.log(valor)
                                        this.setState({duiRegistro:valor,errorDuiRegistro:false})
                                    }}
                                    value={this.state.duiRegistro}
                                    className={this.state.errorDuiRegistro ? "error" : ""} 
                                    id="duiNumber" name="phoneNumber"  type="text" />
                                    {
                                        this.state.errorDuiRegistro == true ?
                                            <label className="error">campo obligatorio.</label>
                                        :null
                                    }
                                </div>
                            </div>
                                          
                            <div>
                                <div>

                                
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-file"></i> &nbsp;&nbsp;GENERO
                                </div>
                                <div>
                                    <select 
                                    onChange={(text) => {
                                        this.setState({genero:text.target.value,errorGenero:false})
                                    }}
                                    style={{width:"100%"}}
                                    value={this.state.genero}
                                    className={this.state.errorGenero ? "error inputSelectBirthDay birthDay" : "inputSelectBirthDay birthDay"} 
                                    id="genero" name="genero">
                                        <option value="">Seleccionar</option>
                                        <option value="male">Hombre</option>
                                        <option value="female">Mujer</option>
                                    </select>
                                </div>

                                {
                                    this.state.errorGenero == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }

                                </div>
                            </div>

                            {
                                this.state.errorRegistro != "" ?
                                    <label className="error" style={{display:"block",textAlign:"center"}}>{this.state.errorRegistro}</label>
                                :null
                            }

                            <Button onClick={(e) => this.registrarLeal(e)} style={{width:"97%",marginLeft:"calc(var(--bs-gutter-x) * .5)"}} className="btn-agregar btnContinuarCrearCuenta">
                                {
                                    this.state.loadingRegistroLeal== true ?
                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                    : 
                                    "REGISTRARSE"
                                }

                                
                            </Button>

                        </Row>
                        <div className='contTusPuntos' style={{textAlign:"center",fontSize:"13px"}}>Si tienes consultas:<br/>escríbenos en el chat de la página</div>
                    </div>
                    
                </Modal>


                <Modal
                    isOpen={this.state.setIsOpenActualizarDatos}
                    style={customStyles}
                    contentLabel=""
                >
                    <div>
                        <img onClick={() => this.cerrarModal()} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left"}}>Actualizar datos</div>
                        <div className="detalleProductoModal" style={{paddingLeft:"0px",fontSize:"14px"}}>Actualiza los datos de tu cuenta</div>

                        <Row className="rowRegistro" style={{paddingRight:"7%",paddingLeft:"7%"}}>
                            

                            <div style={{marginTop:"30px"}}>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-user"></i> &nbsp;&nbsp;NOMBRES
                                </div>
                                <input 
                                onChange={(text) => {
                                    this.setState({nombreUser:text.target.value,errorNombreUser:false})
                                }}
                                value={this.state.nombreUser}
                                className={this.state.errorNombreUser ? "error" : ""} 
                                id="firstName"
                                maxchar="1"
                                name="firstName" type="text" maxlength="30" />
                                {
                                    this.state.errorNombreUser == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                                

                            </div>

                            <div style={{marginTop:"0px"}}>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-user"></i> &nbsp;&nbsp;APELLIDOS
                                </div>
                                <input 
                                onChange={(text) => {
                                    this.setState({apellidoUser:text.target.value,errorApellidoUser:false})
                                }}
                                maxchar="2"
                                value={this.state.apellidoUser}
                                className={this.state.errorApellidoUser ? "error" : ""} 
                                required="true" type="text" maxlength="30" aria-required="true" aria-invalid="false" />
                                {
                                    this.state.errorApellidoUser == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                            </div>

                            <div>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-phone"></i> &nbsp;&nbsp;TELÉFONO
                                </div>
                                <InputMask 
                                onChange={(text) => {
                                    const valor = text.target.value.replace(/\D/g, '')
                                    this.setState({telefonoUser:valor,errorTelefonoUser:false})
                                }}
                                value={this.state.telefonoUser}
                                className={this.state.errorTelefonoUser ? "error" : ""} 
                                id="phoneNumber" mask="9999-9999" name="phoneNumber" placeholder="0000-0000"  inputmode="tel" required="true" type="tel" aria-required="true" aria-invalid="false" />
                                {
                                    this.state.errorTelefonoUser == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                            </div>

                            <div>
                                <div className="tituloCampo">
                                <i className="bi bi-envelope"></i>

                                    <i className="glyphicon glyphicon-envelope"></i> &nbsp;&nbsp;EMAIL
                                </div>
                                <input 
                                onChange={(text) => {
                                    this.setState({emailUser:text.target.value,errorEmailUser:false,errorEmailUserText:""})
                                }}
                                value={this.state.emailUser}
                                className={this.state.errorEmailUser ? "error" : ""} 
                                type="email" id="emailAddress" name="emailAddress" required="" aria-required="true"  aria-invalid="true" />
                                {
                                    this.state.errorEmailUser == true ?
                                        <label className="error">{this.state.errorEmailUserText}.</label>
                                    :null
                                }
                            </div>

                            <div>

                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-gift"></i> &nbsp;&nbsp;CUMPLEAÑOS
                                </div>
                                <div>
                                    <select 
                                    onChange={(text) => {
                                        this.setState({diaUser:text.target.value,errorFechaUserser:false,errorFechaTexto:""})
                                    }}
                                    value={this.state.diaUser}
                                    className={this.state.errorFechaUser ? "error inputSelectBirthDay birthDay" : "inputSelectBirthDay birthDay"} 
                                    id="birthDay" name="birthDay">
                                        <option value="">Día</option>
                                        <option value="01">1</option>
                                        <option value="02">2</option>
                                        <option value="03">3</option>
                                        <option value="04">4</option>
                                        <option value="05">5</option>
                                        <option value="06">6</option>
                                        <option value="07">7</option>
                                        <option value="08">8</option>
                                        <option value="09">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                </select>

                                <select 
                                    onChange={(text) => {
                                        this.setState({mesUser:text.target.value,errorFechaUser:false,errorFechaTexto:""})
                                    }}
                                    value={this.state.mesUser}
                                    className={this.state.errorFechaUser ? "error inputSelectBirthDay birthMonth" : "inputSelectBirthDay birthMonth"} 
                                    id="birthMonth" name="birthMonth" aria-invalid="false">
                                    <option value="">Mes</option>
                                        <option value="01">Enero</option>
                                        <option value="02">Febrero</option>
                                        <option value="03">Marzo</option>
                                        <option value="04">Abril</option>
                                        <option value="05">Mayo</option>
                                        <option value="06">Junio</option>
                                        <option value="07">Julio</option>
                                        <option value="08">Agosto</option>
                                        <option value="09">Septiembre</option>
                                        <option value="10">Octubre</option>
                                        <option value="11">Noviembre</option>
                                        <option value="12">Diciembre</option>
                                </select>

                                    <select 
                                        onChange={(text) => {
                                            this.setState({yearUser:text.target.value,errorFechaUser:false,errorFechaTexto:""})
                                        }}
                                        value={this.state.yearUser}
                                        className={this.state.errorFechaUser ? "error inputSelectBirthDay birthYear" : "inputSelectBirthDay birthYear"} 
                                        id="birthYear" name="birthYear" aria-invalid="false">
                                        <option value="">Año</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option><option value="2002">2002</option><option value="2001">2001</option><option value="2000">2000</option><option value="1999">1999</option><option value="1998">1998</option><option value="1997">1997</option><option value="1996">1996</option><option value="1995">1995</option><option value="1994">1994</option><option value="1993">1993</option><option value="1992">1992</option><option value="1991">1991</option><option value="1990">1990</option><option value="1989">1989</option><option value="1988">1988</option><option value="1987">1987</option><option value="1986">1986</option><option value="1985">1985</option><option value="1984">1984</option><option value="1983">1983</option><option value="1982">1982</option><option value="1981">1981</option><option value="1980">1980</option><option value="1979">1979</option><option value="1978">1978</option><option value="1977">1977</option><option value="1976">1976</option><option value="1975">1975</option><option value="1974">1974</option><option value="1973">1973</option><option value="1972">1972</option><option value="1971">1971</option><option value="1970">1970</option><option value="1969">1969</option><option value="1968">1968</option><option value="1967">1967</option><option value="1966">1966</option><option value="1965">1965</option><option value="1964">1964</option><option value="1963">1963</option><option value="1962">1962</option><option value="1961">1961</option><option value="1960">1960</option><option value="1959">1959</option><option value="1958">1958</option><option value="1957">1957</option><option value="1956">1956</option><option value="1955">1955</option><option value="1954">1954</option><option value="1953">1953</option><option value="1952">1952</option><option value="1951">1951</option><option value="1950">1950</option>
                                    </select>
                                </div>

                                {
                                    this.state.errorFechaUser == true ?
                                        <label className="error">{this.state.errorFechaTexto}.</label>
                                    :null
                                }

                            </div>



                            
                            <Button onClick={(e) => this.guardarDatos(e)} style={{width:"97%",marginLeft:"calc(var(--bs-gutter-x) * .5)"}} className="btn-agregar btnContinuarCrearCuenta">
                                {
                                    this.state.loadingDataSend== true ?
                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                    : 
                                    "GUARDAR CAMBIOS"
                                }

                                
                            </Button>

                        </Row>
                    </div>
                    
                </Modal>

                <Modal
                    isOpen={this.state.setIsOpenActualizarPass}
                    style={customStyles}
                    contentLabel=""
                >
                    <div>
                        <img onClick={() => this.openCloseModalPass()} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left"}}>Actualizar Contraseña</div>
                        <div className="detalleProductoModal" style={{paddingLeft:"0px",fontSize:"14px"}}>Si deseas cambiar tu contraseña, ingresa tu contraseña actual y la nueva contraseña.</div>

                        <Row className="rowRegistro" style={{paddingRight:"7%",paddingLeft:"7%"}}>
                            

                            <div style={{marginTop:"30px"}}>
                                <div className="tituloCampo">
                                    CONTRASEÑA ACTUAL 
                                </div>
                                <input 
                                onChange={(text) => {
                                    this.setState({passUser:text.target.value,errorPassUser:false})
                                }}
                                value={this.state.passUser}
                                className={this.state.errorPassUser ? "error" : ""} 
                                type="password" autocomplete="off" required=""></input>
                                {
                                    this.state.errorPassUser == true ?
                                        <label className="error">{this.state.errorPassText}</label>
                                    :null
                                }
                                

                            </div>

                            <div>
                                <div className="tituloCampo">
                                    NUEVA CONTRASEÑA
                                </div>
                                <input 
                                onChange={(text) => {
                                    this.setState({passUserNew:text.target.value,errorPassNew:false})
                                }}
                                value={this.state.passUserNew}
                                className={this.state.errorPassNew ? "error" : ""} 
                                type="password" autocomplete="off" required=""></input>
                                {
                                    this.state.errorPassNew == true ?
                                        <label className="error">{this.state.errorPassTextNew}</label>
                                    :null
                                }
                            </div>

                            <div>
                                <div className="tituloCampo">
                                    CONFIRMA LA NUEVA CONTRASEÑA
                                </div>
                                <input 
                                onChange={(text) => {
                                    this.setState({passUserNewDos:text.target.value,errorPassNewDos:false})
                                }}
                                value={this.state.passUserNewDos}
                                className={this.state.errorPassNewDos ? "error" : ""} 
                                type="password" autocomplete="off" required=""></input>
                                {
                                    this.state.errorPassNewDos == true ?
                                        <label className="error">{this.state.errorPassTextNewDos}</label>
                                    :null
                                }
                            </div>

                            {
                                this.state.errorActualizarPass != "" ?
                                    <label className="error" style={{display:"block",textAlign:"center"}}>{this.state.errorActualizarPass}</label>
                                :null
                            }
                          
                            
                            <Button onClick={(e) => this.actualizarPass(e)} style={{width:"97%",marginLeft:"calc(var(--bs-gutter-x) * .5)"}} className="btn-agregar btnContinuarCrearCuenta">
                                {
                                    this.state.loadingDataPass== true ?
                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                    : 
                                    "GUARDAR CAMBIOS"
                                }

                                
                            </Button>

                        </Row>
                    </div>
                    
                </Modal>


                <Modal
                    isOpen={this.state.setIsOpenDireccion}
                    style={customStyles}
                    contentLabel=""
                >
                    <div>
                        <img onClick={() => this.openCloseModalDireccion(false)} src="/images/cross.png" className="closeModal" />
                        <div className='productoAgregado' style={{textAlign:"left"}}>{this.state.direccionSelect == null ? "Agregar Dirección":"Actualizar Dirección"}</div>

                        <Row className="rowRegistro" style={{paddingRight:"7%",paddingLeft:"7%"}}>
                            
                            <div style={{marginTop:"30px"}}>
                                <div className="tituloCampo">
                                    &nbsp;&nbsp;DEPARTAMENTO
                                </div>
                                <select 
                                onChange={(text) => {
                                    console.log("aqui",text.target.value)
                                    var listMunicipios = this.state.departamentos.filter((item) => item.id == text.target.value)

                                    this.setState({departamento:text.target.value,errorDepartamento:false,municipios:listMunicipios[0].items})

                                    $("#municipio").prop("selectedIndex", 0);

                                }}
                                value={this.state.departamento}
                                className={this.state.errorDepartamento ? "error" : ""} 
                                id="departamento" name="departamento">
                                    <option value="">Seleccionar</option>

                                    {this.state.departamentos.map(item => (
                                        <option value={item.id}>{item.label}</option>
                                    ))}

                                </select>
                                {
                                    this.state.errorDepartamento == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                            </div>


                            <div style={{marginTop:"0px"}}>
                                <div className="tituloCampo">
                                    &nbsp;&nbsp;MUNICIPIO
                                </div>
                                <select 
                                    onChange={async (text) => {
                                        this.setState({municipio:text.target.value,errorMunicipio:false})

                                        const resColonias =  await Http.instance.get(`rest/main/zones/${text.target.value}`);
                                        console.log(resColonias)
                                        

                                        var options = []
                                        resColonias.map(item => (
                                           options.push({value:item.id,label:item.name_online})
                                        ));

                                        this.setState({colonias:resColonias,options:options,coloniaNombre:null,colonia:"",coloniaNombre:""})

                                    }}
                                    value={this.state.municipio}
                                    className={this.state.errorMunicipio ? "error" : ""} 
                                    id="municipio" name="municipio">
                                    <option value="">Seleccionar</option>
                                    {this.state.municipios.map(item => (
                                        <option value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                                {
                                    this.state.errorMunicipio == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                            </div>

                            <div className='noInput'>
                                <div className="tituloCampo">
                                    &nbsp;&nbsp;COLONIA
                                </div>
                                <Select  
                                    onChange={(text) => {
                                        console.log(text.label)
                                        this.setState({colonia:text.value,coloniaNombre:text,errorColonia:false})
                                    }}
                                    placeholder="Seleccionar"
                                    value={this.state.coloniaNombre}
                                    className={this.state.errorColonia ? "errorSelect" : ""} 
                                    options={this.state.options}
                                    id="colonia" name="colonia">
                                   
                                </Select >
                                {
                                    this.state.errorColonia == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }
                            </div>

                            <div>
                                <div className="tituloCampo">
                                    &nbsp;&nbsp;TIPO DE DIRECCIÓN
                                </div>
                                <select 
                                    onChange={(text) => {
                                        this.setState({tipoDireccion:text.target.value,errorTipoDireccion:false})
                                    }}
                                    value={this.state.tipoDireccion}
                                    className={this.state.errorTipoDireccion ? "error" : ""} 
                                    id="colonia" name="colonia">
                                        <option value="">Seleccionar</option>
                                        <option value="1">Casa</option>
                                        <option value="2">Oficina</option>
                                        <option value="3">Apartamento</option>
                                        <option value="4">Otros</option>
                                </select>
                            </div>
                            {
                                this.state.errorTipoDireccion == true ?
                                    <label className="error">campo obligatorio.</label>
                                :null
                            }


                            <div>
                                <div className="tituloCampo">
                                    <i className="glyphicon glyphicon-home"></i> &nbsp;&nbsp;DIRECCIÓN
                                </div>
                                <textarea 
                                onChange={(text) => {
                                    this.setState({direccion:text.target.value,errorDireccion:false})
                                }}
                                value={this.state.direccion}
                                className={this.state.errorDireccion ? "error" : ""} 
                                id="streetaddress" name="streetaddress" required="" ></textarea>
                                {
                                    this.state.errorDireccion == true ?
                                        <label className="error">campo obligatorio.</label>
                                    :null
                                }                                
                            </div>

                            {
                                this.state.errorSaveDireccion != "" ?
                                    <label className="error" style={{display:"block",textAlign:"center"}}>{this.state.errorSaveDireccion}</label>
                                :null
                            }
    
                            <Button onClick={(e) => this.guardarActualizarDireccion(e)} style={{width:"97%",marginLeft:"calc(var(--bs-gutter-x) * .5)"}} className="btn-agregar btnContinuarCrearCuenta">
                                {
                                    this.state.loadingSaveDireccion== true ?
                                        <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                    : 
                                    "GUARDAR CAMBIOS"
                                }

                                
                            </Button>

                        </Row>
                    </div>
                    
                </Modal>


                <Modal
                    closeTimeoutMS={500}
                    isOpen={this.state.setIsOpenmensaje}
                    style={customStyles}
                    contentLabel=""
                >
                    <img onClick={() => this.setState({setIsOpenmensaje:!this.state.setIsOpenmensaje})} src="/images/cross.png" className="closeModal" />
                    <div className='productoAgregado'>{this.state.titulo}</div>
                    <div className='nombreProductoAgregado'>{this.state.mensaje}</div>
                    <Button onClick={(e) => this.setState({setIsOpenmensaje:!this.state.setIsOpenmensaje}) }  className="btn-agregar btnContinuarCrearCuenta">ACEPTAR</Button>

                </Modal>

                <Modal
                    closeTimeoutMS={500}
                    isOpen={this.state.setIsOpenDelete}
                    style={customStyles}
                    contentLabel=""
                >
                    <img onClick={() => this.setState({setIsOpenDelete:!this.state.setIsOpenDelete})} src="/images/cross.png" className="closeModal" />
                    <div className='productoAgregado'>¿Desea eliminar esta direccion?</div>
                    {
                        this.state.direccionSelect != null ?
                        <div>
                            <div className='nombreProductoAgregado'>{this.state.direccionSelect.address}, {this.state.direccionSelect.locality.departmentName}</div>
                            <Row>
                                <Col xs={6}>
                                    <Button onClick={() => this.setState({setIsOpenDelete:!this.state.setIsOpenDelete})}  className="btn-agregar btnCancelar">Cancelar</Button>
                                </Col>
                                <Col xs={6}>
                                    <Button onClick={(e) => this.deleteDireccion(e)} className="btn-agregar btnContinuarCrearCuenta">
                                        {
                                            this.state.loadingDeleteDireccion== true ?
                                                <div className="contSpinner" style={{padding:"0px"}}><Spinner /></div>
                                            : 
                                            "ACEPTAR"
                                        }
                                    </Button>
                                </Col>
                                
                            </Row>

                        </div>
                        : null
                    }
                    
                    
                    

                </Modal>


                <Modal
                    closeTimeoutMS={500}
                    isOpen={this.state.setIsOpenCarrito}
                    style={customStyles}
                    contentLabel=""
                >
                    <img onClick={(e) => this.setState({setIsOpenCarrito:false}) } src="/images/cross.png" className="closeModal" />
                    <div className='productoAgregado'>Productos Agregados</div>
                    <div className='nombreProductoAgregado'>{this.state.nameProductoAgregado}</div>
                    <Button onClick={(e) => this.confirmarOrden(e) }  className="btn-agregar btnContinuarCrearCuenta">CONFIRMAR ORDEN</Button>

                </Modal>

        </Row>
      );
    }
}

export default MiCuenta;