import React,{ useState,useEffect }  from 'react';
import { Row,Col,Button} from "react-bootstrap";
import Modal from 'react-modal';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { Redirect } from "react-router";
import DetalleProducto from './DetalleProducto';
import Http from '../../libs/http'
import { useMediaQuery } from 'react-responsive'
import DetalleProductoMovil from './DetalleProductoMovil';
import DetallePromocion from './DetallePromocion';
import DetallePromocionMovil from './DetallePromocionMovil';
import Constants from '../../libs/constants'
import { HeaderBotones } from '../../header/header_botones'

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }

class DetallePromocionMain extends React.Component {

    
    state = {
        dataProducto:{},
        loadData:true,
        errorDataProducto:false
    };


    closeModal() {
        this.setState({setIsOpen:!this.state.setIsOpen})
    }

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    } 

    async componentDidMount() {
        this.setState({isMobileSend:this.props.isMobileSend})

        
        try {

            //sesion iniciada
            var idUser = 0
            const customer = localStorage.getItem("customer");
            const initialCustomer = JSON.parse(customer);
            if(initialCustomer != null){
                idUser = initialCustomer.id
            }

            var dataUser = {}
            dataUser.customer = idUser
            dataUser.location = Constants.instance.idDireccion()

            const res =  await Http.instance.post(`rest/product/promo-tarrinas`,JSON.stringify(dataUser));
            if(typeof res.errorHttp === 'undefined'){
                if(res.active == false){
                    window.location = "/";
                    return
                }else{
                    this.setState({dataProducto:res,loadData:false,errorDataProducto:false})
                }
                
            }else{
                window.location = "/";
                return
            }
            
        } catch (error) {
            this.setState({errorDataCategory:true})
        }
    }

    render() {
        if(this.state.errorDataProducto == true){
            return (<Redirect to="/" />);
        }else{
            return (
          
                <Row className={this.state.isMobileSend ? "movil noPadding":""}>
                    <Col xs={12} className={this.state.isMobileSend ? "nopadding" : ""}>
                        {
                            this.state.isMobileSend ?
                            null:
                            <HeaderBotones status={this.props.status} activeTrack={this.props.activeTrack} />
                        }
                        
                        
                        <div className="clear"></div>
                        {
                            this.state.loadData == true ?
                                <div className="contSpinner"><Spinner /></div>
                            :
                            <div className={this.state.isMobileSend ? "nopadding" : ""}>
                                <Default>
                                    <DetallePromocion theme={this.props.theme}  data={this.state.dataProducto} actualizarCarrito={this.props.actualizarCarrito} />
                                </Default>
                                <Mobile>
                                    <DetallePromocionMovil theme={this.props.theme} data={this.state.dataProducto}  actualizarCarrito={this.props.actualizarCarrito}  />
                                </Mobile>
                            </div>

                        }
        
                    </Col>
        
                    
                </Row>
              );
        }
    }
}

export default DetallePromocionMain;