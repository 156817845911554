import React,{ useState,useEffect }  from 'react';
import Http from '../libs/http'
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import {NavItem} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Constants from '../libs/constants'

class MenuCategorias extends React.Component {

    state = {
        loadingCategorias: true,
        dataCategoriasMenu:[],
    };

  
    constructor(props) {
        super(props);
    } 

    async componentDidMount() {
        var idUser = 0

        //sesion iniciada
        const customer = localStorage.getItem("customer");
        const initialCustomer = JSON.parse(customer);
        if(initialCustomer != null){
            idUser = initialCustomer.id
        }

        var dataUser = {}
        dataUser.customer = idUser
        dataUser.location = Constants.instance.idDireccion()

        const res =  await Http.instance.post("rest/main/menu-categories",JSON.stringify(dataUser));
        if(typeof res.errorHttp === 'undefined'){
            this.setState({dataCategoriasMenu:res,loadingCategorias:false})
        }else{
            this.setState({loadingCategorias:false})
        }
        
    }

    render() {

      return (
        <div>
            {
                this.state.loadingCategorias == true ?
                    <div className="contSpinner"><Spinner /></div>
                : null
            }
           <ul>
            
            {this.state.dataCategoriasMenu.map(item => (
                <li>
                    <NavItem>
                        <NavLink 
                        isActive={(match, location) => {
                            
                            if(location.pathname.startsWith('/categoria/1/producto/') && item.id == 1){
                                return true
                            }else if(location.pathname == "/categoria/tarrina" && item.id == 1){
                                return true
                            }else if(location.pathname == "/categoria/promocion" && item.id == 0){
                                return true
                            }else if(location.pathname == "/categoria/llaovasito" && item.id == 2){
                                return true
                            }else if(location.pathname == "/categoria/sanum" && item.id == 3){
                                return true
                            }else if(location.pathname == "/categoria/sensaciones" && item.id == 4){
                                return true
                            }else if(location.pathname.startsWith('/categoria/4/producto/') && item.id == 4){
                                return true
                            }else if(location.pathname == "/categoria/batidos" && item.id == 5){
                                return true
                            }else if(location.pathname.startsWith('/categoria/5/producto/') && item.id == 5){
                                return true
                            }else if(location.pathname == "/categoria/take-home" && item.id == 6){
                                return true
                            }else if(location.pathname.startsWith('/categoria/6/producto/') && item.id == 6){
                                return true
                            }else if(location.pathname == "/categoria/take-out" && item.id == 7){
                                return true
                            }else if(location.pathname.startsWith('/categoria/7/producto/') && item.id == 7){
                                return true
                            }else if(location.pathname.startsWith('/categoria/complementos') && item.id == 18){
                                return true
                            }else if(location.pathname.startsWith('/categoria/frappes') && item.id == 35){
                                return true
                            }else if(location.pathname.startsWith('/categoria/giftcard') && item.id == 40){
                                return true
                            }                  
                        
                            // only consider an event active if its event id is an odd number
                            //const eventID = parseInt(match.params.id);
                            //return !isNaN(eventID) && eventID % 2 === 1;
                          }}
                          
                          className={isActive => "navlink" + (isActive ? " activeMenuLateral" : "")} strict to={"/categoria/"+item.slug} title={item.name}><img src={item.icon_image} alt="llaollao" />  {item.name}</NavLink>
                    </NavItem>
                </li>
                ))}
            </ul>
        </div>
      );
    }
}

export default MenuCategorias;