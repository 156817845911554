import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import { withRouter } from 'react-router-dom';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import {NavItem} from "react-bootstrap";
import { NavLink } from "react-router-dom";

class Promociones extends React.Component {

    state = {
        loadingProductos: true,
        loadingCarrucel: true,
        dataProductos:[],
        dataCarrucel:[],
    };

    handleClickAdd() {
        var data = this.state.dataCat1;
        data.push({id:100})
        console.log(data)
        this.setState({dataCat1:data})
    }

    constructor(props) {
        super(props);
        this.handleClickAdd = this.handleClickAdd.bind(this);

       

    } 

    async componentDidMount() {

        const resCarrucel =  await Http.instance.get("rest/main/home-slider");
        this.setState({dataCarrucel:resCarrucel,loadingCarrucel:false})
        
    }

    async componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
    }
    


    render() {
      return (
        <Row className="contHomeMovil noPadding" style={{paddingTop:"0px"}}>
            <Col xs={12} className="contPromociones" style={{padding:"0px 20px 80px 20px"}}>
                <div className="textPromociones">Promociones</div>
                {
                    this.state.loadingCarrucel == true ?
                        null
                    :
                    <Carousel
                        autoPlay={true}
                        showArrows={false}
                        infiniteLoop={true}
                        showThumbs={false}
                        showStatus={false}
                        interval={6000}
                        >

                        {this.state.dataCarrucel.map(item => (
                            <div>
                                {
                                    item.url == "null" ?
                                    <img src={item.image2} />
                                    :
                                    <a href={item.url} target={item.url_blank == true ? "_blank" :""} style={{display:'block'}}>
                                        <img src={item.image2} />
                                    </a>
                                }
                                
                            </div>
                        ))}
                        
                        
                    </Carousel>
                }
            </Col>

            <div className="mobile-stores-menu visible-mobile above noPadding">
                <ul className="menu-icons">
                    <li>
                        <NavLink className="navlink" to={"/"} activeClassName="off" title="Menú">
                            <i className="iconbot iconf-menu"></i>MENÚ
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="navlink" to={"/promociones"} activeClassName="active off" title="Promociones">
                            <i className="iconbot iconf-promotions"></i>PROMOCIONES
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="navlink" to={"/tiendas"} activeClassName="off" title="Restaurantes">
                        <i className="iconbot iconf-rest"></i>TIENDAS
                        </NavLink>
                    </li>
                </ul>
            </div>

        </Row>
      );
    }
}

export default Promociones;