import React,{ useState,useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Row,Col,Button} from "react-bootstrap";
import Http from '../../libs/http'
import Modal from 'react-modal';
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import DetalleCategoria from './DetalleCategoria'
import { Redirect } from "react-router";
import DetalleProducto from './DetalleProducto';
import DetalleProductoMovil from './DetalleProductoMovil';
import DetalleCategoriaMovil from './DetalleCategoriaMovil';
import Constants from '../../libs/constants'



class CategoriaMovil extends React.Component {

    state = {
        loadingCategorias: true,
        setIsOpen: false,
        dataCategoria:{},
        errorDataCategory:false
    };


    closeModal() {
        this.setState({setIsOpen:!this.state.setIsOpen})
    }

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
       
    } 

    async componentDidMount() {

        try {
            //sesion iniciada
            var idUser = 0
            const customer = localStorage.getItem("customer");
            const initialCustomer = JSON.parse(customer);
            if(initialCustomer != null){
                idUser = initialCustomer.id
            }

            var dataUser = {}
            dataUser.customer = idUser
            dataUser.location = Constants.instance.idDireccion()

            const res =  await Http.instance.post(`rest/product/category/${this.props.match.params.id}`,JSON.stringify(dataUser));
            if(res.active ==  false){
                window.location = "/";
                return
            }


            this.setState({dataCategoria:res,loadingCategorias:false,errorDataCategory:false})
            console.log("resss",res)
        } catch (error) {
            this.setState({errorDataCategory:true})
            
        }

       
    }
    
    async componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({dataCategoria:{},loadingCategorias:true})

            //sesion iniciada
            var idUser = 0
            const customer = localStorage.getItem("customer");
            const initialCustomer = JSON.parse(customer);
            if(initialCustomer != null){
                idUser = initialCustomer.id
            }

            var dataUser = {}
            dataUser.customer = idUser
            dataUser.location = Constants.instance.idDireccion()

            const res =  await Http.instance.post(`rest/product/category/${this.props.match.params.id}`,JSON.stringify(dataUser));
            if(res.active ==  false){
                window.location = "/";
                return
            }

            
            this.setState({dataCategoria:res,loadingCategorias:false})
        }
      }

    render() {
        if(this.state.errorDataCategory == true){
            return (<Redirect to="/" />);
        }else{
            return (
          
                <Row  className="noPadding">
                    <Col xs={12} className="noPadding">
                        
                        <div className="clear"></div>
                        {
                            this.state.loadingCategorias == true ?
                                <div className="contSpinner"><Spinner /></div>
                            :
                                this.state.dataCategoria.type == 1 ?
                                    <DetalleCategoriaMovil data={this.state.dataCategoria} promotion_info={this.props.promotion_info} />
                                :
                                    <DetalleProductoMovil data={this.state.dataCategoria} actualizarCarrito={this.props.actualizarCarrito} promotion_info={this.props.promotion_info} />
                        }
        
                    </Col>
        
                    
                </Row>
              );
        }
    }
}

export default CategoriaMovil;